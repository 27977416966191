import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  StyleRules,
  TextField,
  Theme,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Clear as ClearIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-app';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import {
  openSerialEditDialog,
  setSerialCustomToolbarSearchKeyword,
  toggleSerialCustomToolbarSearchBox,
} from '@redux/actions/appsActions';
import appLanguages from '@utils/app-languages';
import { DefaultMasterQuery } from '@utils/default-query';
import { formatString } from '@utils/format-string';
import { isUserBreweryOrAdmin } from '@utils/get-user-category';
import React from 'react';
import { connect } from 'react-redux';
import userEnv from 'userEnv';

import { compose } from 'redux';

import {
  getSerialBrands,
  getSerials,
  getSerialSlips,
  setSerialsQuery,
} from '../../../redux/actions/serversActions';
import styles from './SerialCustomToolbar.scss';

class SerialsCustomToolbarClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    showAddSerialButton: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTimeout: undefined,
    };
  }

  handleChangeSearch(e) {
    this.props.setSerialCustomToolbarSearchKeyword(String(e.target.value));
    this.handleClickSearch();
  }

  handleKeyPressSearch(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleClickSearch();
    }
  }

  handleClickSearch() {
    const { searchTimeout } = this.state;
    console.log('ClickSearch');
    if (searchTimeout) clearTimeout(searchTimeout);
    const timeout = setTimeout(() => {
      const { servers, setSerialsQuery, getSerials, getSerialSlips } = this.props;
      const {
        apps: {
          serialCustomToolbarState: { searchKeyword },
        },
      } = this.props;
      const query = {
        ...servers.serialsQuery,
        searchText: searchKeyword,
      };
      getSerials(query);
      getSerialSlips(query);
      setSerialsQuery(query);
      this.setState({ searchTimeout: undefined }, () => {
        const { textSearched } = this.props;
        if (textSearched) {
          textSearched(searchKeyword);
        }
      });
    }, 600);
    this.setState({ searchTimeout: timeout });
  }

  handleClickEdit() {
    const { servers, openSerialEditDialog, getSerialBrands } = this.props;
    const { user } = servers;
    const { location } = user;
    getSerialBrands({ ...DefaultMasterQuery, where: { locationId: location.id } });
    openSerialEditDialog();
  }

  handleClickReload() {
    console.log('ClickReload');
    const { servers, getSerials, refreshData } = this.props;
    getSerials(servers.serialsQuery);
    if (refreshData) {
      refreshData();
    }
  }

  handleOpenSearchBox() {
    this.props.toggleSerialCustomToolbarSearchBox(true);
  }

  handleCloseSearchBox() {
    this.props.setSerialCustomToolbarSearchKeyword('');
    this.handleClickSearch();
    this.props.toggleSerialCustomToolbarSearchBox(false);
  }

  render() {
    const {
      classes,
      servers,
      apps,
      showAddSerialButton,
      apps: {
        serialCustomToolbarState: { searchKeyword, openSearchBox },
      },
    } = this.props;
    const { searchTimeout } = this.state;
    const lang = apps.currentLanguage;

    const isRequesting =
      servers.isRequesting || servers.isGetRequesting || Boolean(searchTimeout) || !servers?.user;

    const searchBoxView = (
      <>
        <TextField
          fullWidth
          autoFocus
          className={classes.margin}
          value={searchKeyword}
          onChange={(e) => this.handleChangeSearch(e)}
          onKeyPress={(e) => this.handleKeyPressSearch(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment onClick={() => this.handleClickSearch()} position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {isRequesting ? (
                  <IconButton size='small'>
                    <CircularProgress size={16} color='primary' />
                  </IconButton>
                ) : (
                  ''
                )}
                <InputAdornment onClick={() => this.handleCloseSearchBox()} position='start'>
                  <ClearIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      </>
    );

    return (
      <>
        <Grid container wrap='nowrap' justify='flex-end' alignContent='center' alignItems='center'>
          <Zoom timeout={300} in={openSearchBox}>
            {openSearchBox ? (
              <Grid item xs>
                {searchBoxView}
              </Grid>
            ) : (
              <Box />
            )}
          </Zoom>
          {openSearchBox ? (
            <Grid>
              <Box ml={3} />
            </Grid>
          ) : (
            ''
          )}
          <Zoom timeout={300} in={!openSearchBox}>
            {!openSearchBox ? (
              <Grid item>
                <Tooltip title={appLanguages.search[lang]}>
                  <IconButton onClick={() => this.handleOpenSearchBox()}>
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              <Box />
            )}
          </Zoom>
          <Grid item>
            <Tooltip title={appLanguages.reload[lang]}>
              <IconButton onClick={() => this.handleClickReload()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {showAddSerialButton && (
            <Grid item>
              {userEnv.enableSerialEdit && isUserBreweryOrAdmin(servers?.user) ? (
                <Tooltip title={appLanguages.addNewData[lang]}>
                  <Button
                    disableElevation
                    variant='contained'
                    color='primary'
                    className={styles.addButton}
                    onClick={() => this.handleClickEdit()}
                  >
                    {formatString(appLanguages.addNew[lang], {
                      data: appLanguages.serialCode[lang],
                    })}
                  </Button>
                </Tooltip>
              ) : (
                ''
              )}
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  classes: any;
  showAddSerialButton?: boolean;
  textSearched?: any;
  refreshData?: any;
}

export interface IDispatchProps {
  openSerialEditDialog: () => void;
  setSerialsQuery: (query: IQuery) => void;
  getSerials: (query: IQuery) => void;
  getSerialSlips: (query: IQuery) => void;
  getSerialBrands: (query: IQuery) => void;
  setSerialCustomToolbarSearchKeyword: (keyword: string) => void;
  toggleSerialCustomToolbarSearchBox: (value: boolean) => void;
}

interface State {
  searchTimeout: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  openSerialEditDialog,
  setSerialsQuery,
  getSerials,
  getSerialBrands,
  setSerialCustomToolbarSearchKeyword,
  toggleSerialCustomToolbarSearchBox,
  getSerialSlips,
};

const myStyles = (theme: Theme): StyleRules => ({
  margin: { margin: theme.spacing(1) },
  searchRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
  },
  searchInput: { flex: 1 },
  searchIconButton: { padding: 10 },
});

export const SerialsCustomToolbar = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SerialsCustomToolbarClass);
