import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import { store } from '../redux/reducers';
import theme from '../materialui/theme';
import Layout from './Layout';

export function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <ConfirmProvider>
          <Layout />
        </ConfirmProvider>
      </Provider>
    </MuiThemeProvider>
  );
}
