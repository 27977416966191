import { Box, Typography, IconButton, createMuiTheme, Grid, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import React from 'react';
import language from './app-languages';
import { store } from '../redux/reducers';
import { nowrap } from './common';
import { MUIDataTableOptions } from 'mui-datatables';

export const isEmpty = (v) => v === undefined || v === null || v === '';

export const disableDisplayOpts = { display: false, filter: false };
export const disableSortOpts = { display: true, filter: false };
export const enableSortOnlyOpts = { sort: true, filter: false };
export const enableSortAndFilterOpts = {
  sort: true,
  filter: true,
  filterType: 'custom',
  filterList: [],
};
export const enableEmptyOpts = { empty: true, sort: false, filter: false };

export const appMuiTheme = createMuiTheme({
  overrides: {
    MuiTableRow: {},
  },
});

export const muiDataTableCommonOptions: () => MUIDataTableOptions = () => {
  const lang = store.getState().apps.currentLanguage;
  const options: MUIDataTableOptions = {
    rowsPerPageOptions: [50, 100, 200],
    serverSide: true,
    viewColumns: false,
    responsive: 'vertical', // 'scrollFullHeight', // stacked, scroll, scrollMaxHeight, scrollFullHeight
    selectableRows: 'none', // "multiple", "single", "none"
    selectableRowsOnClick: false,
    search: true,
    searchOpen: false,
    print: false,
    download: true,
    downloadOptions: { filename: 'test.csv', separator: ',' },
    filter: false,
    filterType: 'dropdown', // 'checkbox', 'dropdown', 'multiselect', 'textField'
    textLabels: {
      body: {
        noMatch: language.textLabelsBodyNoMatch[lang],
        toolTip: language.textLabelsBodyToolTip[lang],
      },
      pagination: {
        next: language.textLabelsPaginationNext[lang],
        previous: language.textLabelsPaginationPrevious[lang],
        rowsPerPage: language.textLabelsPaginationRowsPerPage[lang],
        displayRows: language.textLabelsPaginationDisplayRows[lang],
      },
      toolbar: {
        search: language.textLabelsToolbarSearch[lang],
        downloadCsv: language.textLabelsToolbarDownloadCsv[lang],
        filterTable: language.textLabelsToolbarFilterTable[lang],
        viewColumns: language.textLabelsToolbarViewColumns[lang],
      },
      filter: {
        all: language.textLabelsFilterAll[lang],
        title: language.textLabelsFilterTitle[lang],
        reset: language.textLabelsFilterReset[lang],
      },
    },
  };
  return options;
};

export const rowDataToObj = (columns, rowData) => {
  const obj = {};
  if (!columns || !Object.keys(columns).length || !rowData || !Object.keys(rowData).length)
    return obj;
  for (let i = 0; i < columns.length; i += 1) {
    obj[columns[i].name] = rowData[i];
  }
  return obj;
};

export const getSelectCustomFilterListRender = (label, optionMap, v) =>
  `${label}: ${Object.keys(optionMap).length && v in optionMap ? optionMap[v] : ''}`;

export const getSelectCustomBodyRender = (optionMap, v) =>
  Object.keys(optionMap).length && v in optionMap ? nowrap(optionMap[v]) : '';

export const getSelectCustomBodyRenderSummaryFunction = (optionMap, v) =>
  Object.keys(optionMap).length && v in optionMap ? optionMap[v] : '';

export const getDeleteActionsRender = (
  obj,
  delTitle,
  delDesc,
  isRequesting,
  onClickDelete,
  onConfirm,
) => {
  const lang = store.getState().apps.currentLanguage;
  const confirmOptions = {
    title: (
      <Grid container>
        <Grid item>
          <DeleteIcon fontSize='inherit' />
        </Grid>
        <Grid item>
          <Box ml={1} />
        </Grid>
        <Grid item>
          <Typography variant='subtitle1'>{delTitle}</Typography>
        </Grid>
      </Grid>
    ),
    description: delDesc,
    confirmationText: language.yes[lang],
    cancellationText: language.no[lang],
  };
  return (
    <>
      <Tooltip title='Delete'>
        <IconButton
          size='small'
          color='default'
          onClick={() =>
            onConfirm(confirmOptions)
              .then(() => onClickDelete(obj))
              .catch(() => null)
          }
          disabled={isRequesting}
        >
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const getWarningDeleteActionsRender = (onClick) => (
  <>
    <Tooltip title='Edit'>
      <IconButton size='small' color='default' onClick={onClick}>
        <DeleteIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  </>
);

export const getEditActionsRender = (obj, isRequesting, onClickEdit) => (
  <>
    <Tooltip title='Edit'>
      <IconButton
        size='small'
        color='primary'
        onClick={() => onClickEdit(obj)}
        disabled={isRequesting}
      >
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  </>
);

export const getWarningEditActionsRender = (onClick) => (
  <>
    <Tooltip title='Edit'>
      <IconButton size='small' color='primary' onClick={onClick}>
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  </>
);

export const onMuiTableFilterChange = (
  columnName,
  filterList,
  type,
  columns,
  serverSideFilterList,
  where,
  updateEdtObj,
  setServerSideFilterList,
) => {
  const newFilterList = [];
  for (let i = 0; i < columns.length; i += 1) {
    const column = columns[i];
    if (type === 'reset') {
      column.options.filterList = [];
      newFilterList.push([]);
    } else if (column.name === columnName) {
      const changedValue = filterList[i][0];
      if (isEmpty(changedValue)) {
        delete where[columnName];
        column.options.filterList = [];
        updateEdtObj({ where });
      } else {
        column.options.filterList = [changedValue];
        updateEdtObj({ where: { ...where, [columnName]: parseInt(changedValue) } });
      }
      newFilterList.push(filterList[i]);
    } else if (serverSideFilterList.length) {
      newFilterList.push(serverSideFilterList[i]);
    } else {
      newFilterList.push([]);
    }
  }
  if (type === 'reset') updateEdtObj({ where: {} });
  setServerSideFilterList(newFilterList);
};
