import { IStateServers } from '@/models/state-servers';
import { ISerial } from '@models/serial';
import { DefaultMasterQuery, DefaultQuery, LIMIT_PAGINATION_MAX } from '@utils/default-query';
import { isOk } from '@utils/is-ok';
import {
  INITIALIZE_SERVERS,
  SERVER_GET_REQUEST,
  SERVER_REQUEST,
  SERVER_REQUEST_DONE,
  // - locations
  GET_LOCATIONS,
  SET_LOCATIONS_QUERY,
  // - brands
  GET_BRANDS,
  SET_BRANDS_QUERY,
  // - users
  GET_AUTH_USER,
  REGISTER_USER,
  REGISTER_LOGIN_HISTORY,
  // - serials
  REGISTER_SERIAL,
  REGISTER_SERIALS,
  REGISTER_SCAN_GPS_CORDS, //temporaly
  STORE_TMP_GPS_CORDS, //temporaly
  ADD_SERIAL_CODES,
  DELETE_SERIAL,
  GET_SERIALS,
  GET_SCANNED_SERIALS,
  SET_SERIALS_QUERY,
  GET_SERIAL_BRANDS,
  GET_SERIAL_SLIPS,
  GET_STOCKS,
  GET_MASTER_LOCATIONS,
  SET_MASTER_LOCATIONS_QUERY,
  SET_STOCKS_QUERY,
  CLEAR_SERIALS,
  CLEAR_LOCATIONS,
} from '../actions';

const initializeState: IStateServers = {
  isRequesting: false,
  isGetRequesting: false,
  isPaginationGetRequesting: false,
  isLoginHistoryRegistered: false,
  errorCode: null,
  errorMessage: null,
  // Locations
  locations: [],
  locationsQuery: {
    ...DefaultMasterQuery,
  },
  masterLocationsQuery: {
    ...DefaultMasterQuery,
  },
  locationsTotalCounts: 0,
  // Brands
  brands: [],
  brandsQuery: {
    ...DefaultMasterQuery,
  },
  brandsTotalCounts: 0,
  // Users
  user: null,
  // Serials
  serials: [],
  serialsQuery: {
    ...DefaultQuery,
    limit: LIMIT_PAGINATION_MAX,
  },
  serialsTotalCounts: 0,
  serialBrands: [],
  serialBrandsTotalCounts: 0,
  scannedSerials: [],
  scannedSerialsTotalCounts: 0,
  serialSlips: [],
  serialSlipsTotalCounts: 0,
  stocks: null,
  stocksQuery: {
    ...DefaultQuery,
  },
  gps: [],
};

export default (state: IStateServers = initializeState, action): IStateServers => {
  const { result, errorCode, errorMessage, query } = action;
  const data = result && result.data ? result.data : {};
  const response = {
    ...state,
    isRequesting: false,
    isGetRequesting: false,
    isPaginationGetRequesting: false,
    errorCode,
    errorMessage,
  };
  switch (action.type) {
    case INITIALIZE_SERVERS:
      return {
        ...initializeState,
      };
    case SERVER_REQUEST:
      return {
        ...response,
        isRequesting: true,
      };
    case SERVER_GET_REQUEST:
      return {
        ...response,
        isGetRequesting: true,
      };
    /* Update */
    case SERVER_REQUEST_DONE:
    case REGISTER_SERIAL:
    case REGISTER_SERIALS:
    case REGISTER_SCAN_GPS_CORDS:
    case ADD_SERIAL_CODES:
    case DELETE_SERIAL:
    case REGISTER_USER:
      break;
    /* Get */
    case GET_LOCATIONS:
      return {
        ...response,
        locations: data.objects,
        locationsTotalCounts: data.totalCounts,
      };
    case CLEAR_LOCATIONS:
      return {
        ...response,
        isGetRequesting: true,
        locations: [],
        locationsTotalCounts: 0,
      };
    case GET_MASTER_LOCATIONS:
      return {
        ...response,
        locations: data?.objects,
        locationsTotalCounts: data?.totalCounts,
      };
    case GET_BRANDS:
      return {
        ...response,
        brands: data.objects,
        brandsTotalCounts: data.totalCounts,
      };
    case GET_AUTH_USER:
      return {
        ...response,
        user: data,
      };
    case GET_STOCKS:
      return {
        ...response,
        stocks: data.objects,
      };
    case CLEAR_SERIALS:
      return {
        ...response,
        serials: [],
        serialsTotalCounts: 0,
      };
    case GET_SERIALS:
      return {
        ...response,
        serials: data.objects,
        serialsTotalCounts: data.totalCounts,
      };
    case GET_SERIAL_BRANDS:
      return {
        ...response,
        serialBrands: data.objects,
        serialBrandsTotalCounts: data.totalCounts,
      };
    case GET_SCANNED_SERIALS: {
      return {
        ...response,
        scannedSerials: data.objects || [],
        scannedSerialsTotalCounts: data.totalCounts,
      };
    }
    case GET_SERIAL_SLIPS: {
      const slipDict: { [key: string]: ISerial[] } = {};
      if (!isOk(data.objects)) return { ...response, serialSlips: [], serialSlipsTotalCounts: 0 };

      data.objects.forEach((v) => {
        //　伝票番号あり、ディストリビュータが未出荷のもの
        if (v.slip) {
          slipDict.hasOwnProperty(v.slip) ? slipDict[v.slip].push(v) : (slipDict[v.slip] = [v]);
        }
      });
      const slips: Slip[] = [];

      for (const [key, serials] of Object.entries(slipDict)) {
        let codes = serials.map((v) => v.code);
        let slip: Slip = {};
        slip.slip = key;
        slip.serials = serials;
        slip.count = serials.length;
        slip.brewShipUserName = serials[0]?.brewShipUser?.name;
        slip.brewShipAt = serials[0]?.brewShipAt;
        slip.distRecvAt = serials[0]?.distRecvAt;
        slip.codes = codes.toString();
        slips.push(slip);
      }

      // Sort
      slips.sort((a, b) => {
        try {
          if (new Date(a.brewShipAt) > new Date(b.brewShipAt)) {
            return -1;
          } else if (new Date(a.brewShipAt) < new Date(b.brewShipAt)) {
            return 1;
          }
          return 0;
        } catch {
          return 0;
        }
      });

      return {
        ...response,
        serialSlips: slips,
        serialSlipsTotalCounts: slips.length,
      };
    }

    // TODO [SAKE-BC] Add sorting here
    /* Set Query */
    case SET_LOCATIONS_QUERY:
      return {
        ...response,
        locationsQuery: query,
      };
    case SET_MASTER_LOCATIONS_QUERY:
      return {
        ...response,
        masterLocationsQuery: query,
      };
    case SET_BRANDS_QUERY:
      return {
        ...response,
        brandsQuery: query,
      };
    case SET_SERIALS_QUERY:
      return {
        ...response,
        serialsQuery: query,
      };
    case SET_STOCKS_QUERY:
      return {
        ...response,
        stocksQuery: query,
      };
    case REGISTER_LOGIN_HISTORY:
      return {
        ...response,
        isLoginHistoryRegistered: true,
      };
    case STORE_TMP_GPS_CORDS:
      return {
        ...response,
        gps: data,
      };
    default:
      break;
  }
  return response;
};

interface Slip {
  slip?: string;
  serials?: ISerial[];
  count?: number;
  brewShipUserName?: string;
  brewShipAt?: string;
  distRecvAt?: string;
  codes?: string;
}
