import { Client, GeolocateRequest } from '@googlemaps/google-maps-services-js';
import { Button, LinearProgress, StyleRules, Theme, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IGeocode } from '@models/geocode';
import { IQuery } from '@models/query';
import { ISerial } from '@models/serial';
import { ESerialActionType } from '@models/serial-action-type';
import { ISerials } from '@models/serials';
import { IStateApps } from '@models/state-app';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import {
  getSerialSlips,
  registerSerial,
  registerSerials,
  setSerialsQuery,
  storeTmpGpsCords,
  registerScanGpsCords,
} from '@redux/actions/serversActions';
import { setTab } from '@redux/actions/appsActions';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';
import WhiteLinearProgress from '../../../elements/WhiteLinearProgress';
import appLanguages from '@utils/app-languages';
import {
  getFormattedDateString,
  setDistanceFromScanLocToUserLocByGoogleMapAPI,
  setScanLocationInfoByGoogleMapAPI,
  withConfirm,
  // calDistance,
} from '@utils/common';
import { appMuiTheme, muiDataTableCommonOptions } from '@utils/mui-datatables-ops';
import { SerialCustomToolbarSelect } from '../serials/SerialCustomToolbarSelect';
import { SerialsCustomToolbar } from '../serials/SerialsCustomToolbar';
import { isUserBreweryOrAdmin } from '@utils/get-user-category';
import { getBaiduScannedLocationInfo } from '@utils/baidu-utils/get-baidu-scanned-location-info';

class SakeRegistrationForDistributorClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    viewType: 'received',
  };

  constructor(props) {
    super(props);
    const { apps, viewType } = this.props;
    const lang = apps.currentLanguage;
    const columns: MUIDataTableColumn[] = [];
    columns.push({
      name: 'slip',
      label: appLanguages.slipNum[lang],
      options: { sort: false },
    });
    columns.push({
      name: 'brewShipUserName',
      label: appLanguages.brewery[lang],
      options: { sort: false },
    });
    columns.push({
      name: 'count',
      label: appLanguages.serialCount[lang],
      options: { sort: false },
    });
    columns.push({
      name: 'brewShipAt',
      label: appLanguages.brewShipAt[lang],
      options: {
        sort: false,
        customBodyRender: (v) => this.getDateText(v, lang),
      },
    });
    columns.push({
      name: 'distRecvAt',
      label: appLanguages.serialDistRegistrationAt[lang],
      options: {
        sort: false,
        customBodyRender: (v) => this.getDateText(v, lang),
        display: viewType === 'received',
      },
    });
    columns.push({
      name: 'codes',
      label: appLanguages.serialCollection[lang],
      options: { sort: false, display: false, filter: false },
    });

    this.state = {
      initialized: false,
      columns,
      selectedRows: [],
      scanLat: 0,
      scanLng: 0,
      scanCountry: '',
      scanArea: '',
      scanCity: '',
      scanSubCity: '',
      scanPostalCode: '',
      distanceText: '',
      distance: 0,
      warning: false,
      isFetchingGps: true,
    };
  }

  public async componentDidMount() {
    this.getData();
    // if (BAIDU_ONLY_COUNTRIES.includes(this.props.servers?.user?.location?.country)) {
    //   await this.initScanLocationByBaiduMapAPI();
    // } else {
    this.initScanLocationByGoogleMapAPI();
    // const { servers, storeTmpGpsCords } = this.props;
    // const { user, gps } = servers;
    // const { isFetchingGps } = this.state;

    //start fetching gps continuously
    // const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));
    // (async () => {
    //   if (isFetchingGps) {
    //     let items = [...gps];
    //     for (let i = 0; i < 36; i++) {
    //       if (!isFetchingGps) break;
    //       await sleep(5000);
    //       if (user.location.country === 'CN') {
    //         try {
    //           const scanLocationInfo = await getBaiduScannedLocationInfo(user);
    //           const distance = calDistance(
    //             user.location,
    //             scanLocationInfo.scanLat,
    //             scanLocationInfo.scanLng,
    //           );
    //           const item = {
    //             actionId: new Date().toISOString(),
    //             userId: user.id,
    //             locationId: user.location.id,
    //             locLatitude: user.location.latitude,
    //             locLongitude: user.location.longitude,
    //             fetchedLatitude: scanLocationInfo.scanLat,
    //             fetchedLongitude: scanLocationInfo.scanLng,
    //             distance,
    //           };
    //           items.push(item);
    //         } catch (e) {
    //           var options = {
    //             enableHighAccuracy: true,
    //             timeout: 5000,
    //             maximumAge: 0,
    //           };
    //           function error(err) {
    //             console.warn(`ERROR(${err.code}): ${err.message}`);
    //           }
    //           window.navigator.geolocation.getCurrentPosition(
    //             (p) => {
    //               const { latitude, longitude } = p.coords;
    //               if (i === 0) {
    //                 setScanLocationInfoByGoogleMapAPI(latitude, longitude, (s) => this.setState(s));
    //                 setDistanceFromScanLocToUserLocByGoogleMapAPI(
    //                   user.location,
    //                   latitude,
    //                   longitude,
    //                   (s) => this.setState(s),
    //                 );
    //               }
    //               const distance = calDistance(user.location, latitude, longitude);
    //               const item = {
    //                 actionId: new Date().toISOString(),
    //                 userId: user.id,
    //                 locationId: user.location.id,
    //                 locLatitude: user.location.latitude,
    //                 locLongitude: user.location.longitude,
    //                 fetchedLatitude: latitude,
    //                 fetchedLongitude: longitude,
    //                 distance,
    //               };
    //               items.push(item);
    //             },
    //             error,
    //             options,
    //           );
    //         }
    //       } else {
    //         var options = {
    //           enableHighAccuracy: true,
    //           timeout: 5000,
    //           maximumAge: 0,
    //         };
    //         function error(err) {
    //           console.warn(`ERROR(${err.code}): ${err.message}`);
    //         }
    //         window.navigator.geolocation.getCurrentPosition(
    //           (p) => {
    //             const { latitude, longitude } = p.coords;
    //             if (i === 0) {
    //               setScanLocationInfoByGoogleMapAPI(latitude, longitude, (s) => this.setState(s));
    //               setDistanceFromScanLocToUserLocByGoogleMapAPI(
    //                 user.location,
    //                 latitude,
    //                 longitude,
    //                 (s) => this.setState(s),
    //               );
    //             }
    //             const distance = calDistance(user.location, latitude, longitude);
    //             const item = {
    //               actionId: new Date().toISOString(),
    //               userId: user.id,
    //               locationId: user.location.id,
    //               locLatitude: user.location.latitude,
    //               locLongitude: user.location.longitude,
    //               fetchedLatitude: latitude,
    //               fetchedLongitude: longitude,
    //               distance,
    //             };
    //             items.push(item);
    //           },
    //           error,
    //           options,
    //         );
    //       }
    //       storeTmpGpsCords(items);
    //     }
    //   }
    // })();

    // }
  }

  protected getData() {
    const { servers, getSerialSlips, setSerialsQuery, viewType } = this.props;
    const { user } = servers;
    const { location } = user;
    const query = {
      ...servers.serialsQuery,
      searchText: null,
      where: {
        brewShipDistLocId: location.id,
        distRecvAt: viewType === 'receiving' ? null : { $ne: null },
      },
      order: [['brewShipAt', 'DESC']],
      offset: 0,
    };
    setSerialsQuery(query);
    getSerialSlips(query);
    setTimeout(() => this.setState({ initialized: true }), 1000);
  }

  getDateText(dateString, lang) {
    let shipAtText = appLanguages.unshipped[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      shipAtText = getFormattedDateString(date);
    }
    return shipAtText;
  }

  protected async initScanLocationByBaiduMapAPI() {
    const {
      servers: { user },
    } = this.props;
    const scanLocationInfo = await getBaiduScannedLocationInfo(user);
    this.setState({
      distanceText: scanLocationInfo.distanceText,
      distance: scanLocationInfo.distance,
      scanLat: scanLocationInfo.scanLat,
      scanLng: scanLocationInfo.scanLng,
    });
  }

  /*
   *   GPC Management
   */
  protected initScanLocationByGoogleMapAPI() {
    const { servers } = this.props;
    const { user } = servers;
    const _initScanLocationByHtml5GeoAPI = () => {
      // HTML5 Geolocation API によるGPSによる位置情報の取得
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }
      window.navigator.geolocation.getCurrentPosition(
        (p) => {
          const { latitude, longitude } = p.coords;
          setScanLocationInfoByGoogleMapAPI(latitude, longitude, (s) => this.setState(s));
          setDistanceFromScanLocToUserLocByGoogleMapAPI(user.location, latitude, longitude, (s) =>
            this.setState(s),
          );
        },
        error,
        options,
      );
    };
    // Google Map API (geolocate API) での位置情報取得
    const requestParams: GeolocateRequest = {
      params: {
        key: userEnv.googleMapApiKey,
      },
      data: null,
    };
    new Client({})
      .geolocate(requestParams)
      .then((response) => {
        const d = response.data;
        if (!d || !d['location'] || !d['location']?.lat || !d['location']?.lng) {
          _initScanLocationByHtml5GeoAPI();
        } else {
          const { lat, lng } = d['location'];
          setScanLocationInfoByGoogleMapAPI(lat, lng, (s) => this.setState(s));
          setDistanceFromScanLocToUserLocByGoogleMapAPI(user.location, lat, lng, (s) =>
            this.setState(s),
          );
        }
      })
      .catch(() => _initScanLocationByHtml5GeoAPI());
  }

  updateQuery(newQuery) {
    const { servers, setSerialsQuery, getSerialSlips } = this.props;
    const query = { ...servers.serialsQuery, ...newQuery };
    setSerialsQuery(query);
    getSerialSlips(query);
  }

  handleOnChangePage(currentPage) {
    const { servers } = this.props;
    const { limit } = servers.serialsQuery;
    this.updateQuery({ offset: currentPage * limit });
  }

  // TODO: FIX
  handleOnColumnSortChange(changedColumn, direction) {
    const { columns } = this.state;
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({
      order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']],
    });
  }

  handleOnRowsSelected(currentRowsSelected, allRowsSelected) {
    this.setState({ selectedRows: allRowsSelected });
  }

  async handleReceiveSlips() {
    const { selectedRows } = this.state;
    const { servers, registerSerials, setTab, registerScanGpsCords } = this.props;
    const { user, gps } = servers;
    const { location } = user;
    const { scanLat, scanLng, warning } = this.state;
    // let latestDist = distance;
    let latestLat = scanLat;
    let latestLnt = scanLng;
    gps.sort(function (a, b) {
      if (a.distance > b.distance) {
        return 1;
      } else {
        return -1;
      }
    });
    if (gps.length && gps[0]['fetchedLatitude'] && gps[0]['fetchedLongitude']) {
      latestLat = gps[0]['fetchedLatitude'];
      latestLnt = gps[0]['fetchedLongitude'];
    }
    const distLocId = location ? location.id : null;
    const distRecvAt = new Date().toISOString();
    const distRecvScanLatLng = latestLat && latestLnt ? `${latestLat},${latestLnt}` : null;

    const geocode: IGeocode = {
      latitude: latestLat ? latestLat : null,
      longitude: latestLnt ? latestLnt : null,
    };

    // 選択シリアル番号の登録処理
    const registeringSerials = [];
    selectedRows.forEach(async (v) => {
      const dataIndex = v['dataIndex'];
      const serials = servers.serialSlips[dataIndex]['serials'];

      for (const serial of serials) {
        const bulk = {
          ...serial,
          recvDistLocId: distLocId,
          distRecvAt,
          distRecvScanLatLng,
          distRecvWarning: warning,
          requireSurveyForDistRecv: warning,
          distRecvUserId: user.id,
        };
        registeringSerials.push(bulk);
      }
    });

    await registerSerials({
      action: ESerialActionType.RECEIVE,
      geocode: geocode,
      serials: registeringSerials,
    });
    this.setState({ isFetchingGps: false });
    registerScanGpsCords(gps);

    // Go to Inventory Tab
    setTab(1);
  }

  render() {
    const {
      servers,
      apps: { currentLanguage: lang },
      classes,
      viewType,
    } = this.props;
    const { initialized, columns } = this.state;
    const { offset, limit } = servers.serialsQuery;
    const currentPage = offset / limit;
    const numberOfRows = limit;
    const isRequesting =
      servers.isRequesting || servers.isGetRequesting || !initialized || !servers?.user;

    const CustomCheckbox = (props) => {
      let newProps = Object.assign({}, props);
      newProps.color = props['data-description'] === 'row-select' ? 'secondary' : 'primary';

      const dataIndex = props['data-index'] ? props['data-index'] : 0;
      if (servers?.serialSlips[dataIndex]?.distRecvAt) {
        return <></>;
      } else {
        return <Checkbox {...newProps} />;
      }
    };

    const customCommonOptions = muiDataTableCommonOptions();
    // Only for Brewery and Admin Users
    if (isUserBreweryOrAdmin(servers?.user)) {
      customCommonOptions.filter = true;
      // customCommonOptions.filterType = 'checkbox';
      // customCommonOptions.selectableRows = 'multiple';
    }

    if (servers.isGetRequesting) return <LinearProgress />;

    return (
      <>
        {isRequesting ? <LinearProgress /> : <WhiteLinearProgress />}
        <MuiThemeProvider theme={appMuiTheme}>
          <MUIDataTable
            title=''
            data={servers.serialSlips}
            columns={columns}
            options={{
              ...customCommonOptions,
              download: false,
              filterType: 'checkbox',
              selectableRows: 'multiple',
              expandableRows: false,
              expandableRowsOnClick: false,
              onRowClick: (rowData, rowMeta) => console.log('onRowsSelect.', rowData, rowMeta),
              search: false,
              page: currentPage,
              rowsPerPage: numberOfRows,
              pagination: !(viewType === 'receiving'),
              count: servers.serialSlipsTotalCounts,
              selectableRowsHeader: false, // 先頭行の全チェックボックス表示切替
              selectableRowsOnClick: true,
              isRowSelectable: (dataIndex, selectedRows) => {
                return servers.serialSlips[dataIndex].distRecvAt ? false : true;
              },
              customToolbar: () => <SerialsCustomToolbar />,
              onChangePage: (currentPage) => this.handleOnChangePage(currentPage),
              onColumnSortChange: (changedColumn, direction) =>
                this.handleOnColumnSortChange(changedColumn, direction),
              onRowSelectionChange: (currentRowsSelected, allRowsSelected) =>
                this.handleOnRowsSelected(currentRowsSelected, allRowsSelected),
              customToolbarSelect: () => <SerialCustomToolbarSelect />,
            }}
            components={{
              // @ts-ignore-start
              Checkbox: CustomCheckbox,
              // @ts-ignore-end
            }}
          />
        </MuiThemeProvider>
        {viewType === 'receiving' && (
          <Button
            className={classes.processReceivingButton}
            variant='contained'
            color='primary'
            onClick={() => this.handleReceiveSlips()}
          >
            {appLanguages.processReceiving[lang]}
          </Button>
        )}
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  classes: any;
  viewType?: 'receiving' | 'received';
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
  getSerialSlips: (query: IQuery) => void;
  registerSerial: (serialObj: ISerial) => void;
  registerSerials: (serialsObj: ISerials, successMessage?: string) => void;
  setTab: (tabIndex: number) => void;
  storeTmpGpsCords: (params: any) => void; //temporaly
  registerScanGpsCords: (params: any) => void;
}

interface State {
  initialized: boolean;
  columns: MUIDataTableColumn[];
  selectedRows: any[];
  scanLat: number;
  scanLng: number;
  scanCountry: string;
  scanArea: string;
  scanCity: string;
  scanSubCity: string;
  scanPostalCode: string;
  distanceText: string;
  distance: number;
  warning: boolean;
  isFetchingGps: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  setSerialsQuery,
  getSerialSlips,
  registerSerial,
  registerSerials,
  setTab,
  storeTmpGpsCords,
  registerScanGpsCords,
};

const myStyles = (theme: Theme): StyleRules => ({
  processReceivingButton: { width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0 },
});

export const SakeRegistrationForDistributor = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withConfirm(SakeRegistrationForDistributorClass));
