import { Coords } from 'google-map-react';
import { BaiduUtils } from './utils';

//BD-09 to WGS-84
export function convertBaiduToGoogleCoordinates(lat, lng): Coords {
  if (BaiduUtils.isOutOfChina(lat, lng)) {
    return { lat, lng };
  }
  const decrypted = BaiduUtils.bd_decrypt(lat, lng);
  const coords = BaiduUtils.gcj_decrypt_exact(decrypted.lat, decrypted.lng);
  return { lat: coords.lat, lng: coords.lng };
}

//WGS-84 to BD-09
export function convertGoogleToBaiduCoordinates(lat, lng): Coords {
  if (BaiduUtils.isOutOfChina(lat, lng)) {
    return { lat, lng };
  }
  const encrypted = BaiduUtils.gcj_encrypt(lat, lng);
  const coords = BaiduUtils.bd_encrypt(encrypted.lat, encrypted.lng);
  return { lat: coords.lat, lng: coords.lng };
}
