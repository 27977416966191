export enum EQROperations {
  // 未指定
  UNSPECIFIED = 0,
  // 出荷
  SHIPPING = 1,
  // 入荷
  RECEIVING = 2,
  // 販売
  SELLING = 3,
}
