import { IRegistrationState } from '@models/registration-state';
import { ISerialRangePagination } from '@models/serial-range-pagination';
import {
  INITIALIZE_APPS,
  OPEN_SNACKBAR_MESSAGE,
  CLOSE_SNACKBAR_MESSAGE,
  SET_TAB,
  SET_REGISTRATION_DATE,
  START_SETTING,
  DONE_SETTING,
  SET_CURRENT_LANGUAGE,
  OPEN_PROFILE_DIALOG,
  CLOSE_PROFILE_DIALOG,
  OPEN_QR_SCAN_DIALOG,
  CLOSE_QR_SCAN_DIALOG,
  OPEN_LOGOUT_DIALOG,
  CLOSE_LOGOUT_DIALOG,
  ADD_SCANNED_SERIAL_CODE,
  ADD_SERIAL_CODE_RANGE,
  DEL_SCANNED_SERIAL_CODE,
  CLEAR_SCANNED_SERIAL_CODES,
  SET_SERIAL_RANGE_FROM,
  SET_SERIAL_RANGE_TO,
  CLEAR_SERIAL_RANGE,
  SET_EDIT_SERIAL,
  OPEN_SERIAL_EDIT_DIALOG,
  CLOSE_SERIAL_EDIT_DIALOG,
  // - users
  SET_EDIT_USER,
  OPEN_USER_EDIT_DIALOG,
  CLOSE_USER_EDIT_DIALOG,
  UPDATE_SERIAL_RANGE_PAGINATON,
  SET_BREWERY_REG_STATE,
  SET_DISTRIBUTOR_SHIP_STATE,
  SET_RESTAURANT_REG_STATE,
  SET_SERIAL_CUSTOM_TOOLBAR_SEARCH_KEYWORD,
  TOOGLE_SERIAL_CUSTOM_TOOLBAR_SEARCHBOX,
} from '.';

export const initializeApps = () => ({ type: INITIALIZE_APPS });

export const openSnackbarMessage = (format, message) => ({
  type: OPEN_SNACKBAR_MESSAGE,
  format,
  message,
});

export const closeSnackbarMessage = () => ({ type: CLOSE_SNACKBAR_MESSAGE });

export const setTab = (value) => ({ type: SET_TAB, value });

export const setRegistrationDate = (date) => ({ type: SET_REGISTRATION_DATE, date });

export const startSetting = () => ({ type: START_SETTING });
export const doneSetting = () => ({ type: DONE_SETTING });
const __setCurrentLanguage = (lang) => ({ type: SET_CURRENT_LANGUAGE, lang });
export const setCurrentLanguage = (lang) => (dispatch) => {
  setTimeout(() => dispatch(startSetting()), 1);
  setTimeout(() => dispatch(__setCurrentLanguage(lang)), 10);
  setTimeout(() => dispatch(doneSetting()), 600);
};

export const openProfileDialog = () => ({ type: OPEN_PROFILE_DIALOG });
export const closeProfileDialog = () => ({ type: CLOSE_PROFILE_DIALOG });

export const openQRScanDialog = () => ({ type: OPEN_QR_SCAN_DIALOG });
export const closeQRScanDialog = () => ({ type: CLOSE_QR_SCAN_DIALOG });

export const openLogoutDialog = () => ({ type: OPEN_LOGOUT_DIALOG });
export const closeLogoutDialog = () => ({ type: CLOSE_LOGOUT_DIALOG });

export const setBreweryRegState = (state: IRegistrationState) => ({
  type: SET_BREWERY_REG_STATE,
  state,
});
export const setDistributorShipState = (state: IRegistrationState) => ({
  type: SET_DISTRIBUTOR_SHIP_STATE,
  state,
});
export const setRestaurantRegState = (state: IRegistrationState) => ({
  type: SET_RESTAURANT_REG_STATE,
  state,
});

export const addScannedSerialCode = (serialCode) => ({ type: ADD_SCANNED_SERIAL_CODE, serialCode });
export const setSerialCodeRange = (serialCodes: string[]) => ({
  type: ADD_SERIAL_CODE_RANGE,
  serialCodes,
});
export const delScannedSerialCode = (serialCode) => ({ type: DEL_SCANNED_SERIAL_CODE, serialCode });
export const clearScannedSerialCode = () => ({ type: CLEAR_SCANNED_SERIAL_CODES });
export const setPaginationSerialRange = (serialRangePagination: ISerialRangePagination) => ({
  type: UPDATE_SERIAL_RANGE_PAGINATON,
  serialRangePagination,
});

export const setSerialRangeFrom = (range: string) => ({ type: SET_SERIAL_RANGE_FROM, range });
export const setSerialRangeTo = (range: string) => ({ type: SET_SERIAL_RANGE_TO, range });
export const clearSerialRange = () => ({ type: CLEAR_SERIAL_RANGE });

export const setEditSerial = (obj) => ({ type: SET_EDIT_SERIAL, obj });
export const openSerialEditDialog = (obj = {}) => ({ type: OPEN_SERIAL_EDIT_DIALOG, obj });
export const closeSerialEditDialog = () => ({ type: CLOSE_SERIAL_EDIT_DIALOG });

export const setEditUser = (obj) => ({ type: SET_EDIT_USER, obj });
export const openUserEditDialog = (obj = {}) => ({ type: OPEN_USER_EDIT_DIALOG, obj });
export const closeUserEditDialog = () => ({ type: CLOSE_USER_EDIT_DIALOG });

export const setSerialCustomToolbarSearchKeyword = (keyword: string) => ({
  type: SET_SERIAL_CUSTOM_TOOLBAR_SEARCH_KEYWORD,
  keyword,
});

export const toggleSerialCustomToolbarSearchBox = (value: boolean) => ({
  type: TOOGLE_SERIAL_CUSTOM_TOOLBAR_SEARCHBOX,
  value,
});
