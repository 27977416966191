import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

class Progress extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Box mt={3} />
        <Grid container justify='center'>
          <Grid item xs={12}>
            <Box mt={5} />
          </Grid>
          <Grid item xs={12}>
            <Box mt={5} />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <CircularProgress className={classes.progress} />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

Progress.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  apps: state.apps,
});

const mapDispatchToProps = {};

const myStyles = (theme) => ({
  progress: { margin: theme.spacing(2) },
});

export default compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(Progress);
