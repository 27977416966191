import { IStateAuth } from '@models/state-auth';
import {
  INIT_AUTH_REQUEST,
  INIT_AUTH,
  LOGIN_REQUEST,
  LOGIN_WITH_EMAIL,
  LOGIN_WITH_TOKEN,
  LOGOUT_REQUEST,
  LOGOUT,
} from '../actions';

const noLogin = {
  user: null,
  idToken: null,
  cToken: null,
  isTokenLogin: false,
  isEmailLogin: false,
};

const noError = {
  errorCode: null,
  errorMessage: null,
};

const initializeState: IStateAuth = {
  initialized: false,
  isRequesting: false,
  loginTime: null,
  ...noLogin,
  ...noError,
};

export default (state: IStateAuth = initializeState, action): IStateAuth => {
  const initialized = true;
  const isRequesting = false;
  const response = {
    ...state,
  };
  switch (action.type) {
    case INIT_AUTH_REQUEST:
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
      return {
        ...state,
        ...noLogin,
        ...noError,
        isRequesting: true,
      };
    case INIT_AUTH: {
      const { user, idToken } = action;
      return {
        ...state,
        ...noError,
        initialized,
        isRequesting,
        user,
        idToken,
      };
    }
    case LOGIN_WITH_EMAIL: {
      if (action.error) {
        const { code, message } = action.error;
        return {
          ...state,
          ...noLogin,
          initialized,
          isRequesting,
          errorCode: code,
          errorMessage: message,
        };
      }
      if (action.result) {
        const { user, idToken } = action;
        return {
          ...state,
          ...noError,
          initialized,
          isRequesting,
          isTokenLogin: false,
          isEmailLogin: true,
          loginTime: new Date().toISOString(),
          user,
          idToken,
        };
      }
      break;
    }
    case LOGIN_WITH_TOKEN: {
      if (action.error) {
        const { code, message } = action.error;
        return {
          ...state,
          ...noLogin,
          initialized,
          isRequesting,
          errorCode: code,
          errorMessage: message,
        };
      }
      if (action.result) {
        const { user, idToken } = action;
        return {
          ...state,
          ...noError,
          initialized,
          isRequesting,
          isTokenLogin: true,
          isEmailLogin: false,
          loginTime: new Date().toISOString(),
          user,
          idToken,
        };
      }
      break;
    }
    case LOGOUT: {
      if (action.error) {
        const { code, message } = action.error;
        return {
          ...state,
          ...noLogin,
          initialized,
          isRequesting,
          errorCode: code,
          errorMessage: message,
        };
      }
      return {
        ...state,
        ...noLogin,
        ...noError,
        initialized,
        isRequesting,
      };
    }
    default:
      break;
  }
  return response;
};
