import PropTypes from 'prop-types';

const profilePropTypes = PropTypes.shape({
  uid: PropTypes.string,
  providerId: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  photoURL: PropTypes.string,
});

export default profilePropTypes;
