import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import appLanguages from '../../utils/app-languages';
import {
  LANGUAGE_ENGLISH,
  LANGUAGE_JAPANESE,
  LANGUAGE_CHINESE,
  LANGUAGE_CHINESE_TRADITIONAL,
  LANGUAGE_KOREAN,
  LANGUAGE_THAI,
  LANGUAGE_VIETAMESE,
} from '../../utils/common';

class NotFound extends React.PureComponent {
  render() {
    const { apps } = this.props;
    const lang = apps.currentLanguage;

    return (
      <>
        <Box mt={3} />
        <Grid container justify='center'>
          <Grid item xs={10} className='text-bootstrap4-secondary'>
            {lang === LANGUAGE_JAPANESE ? (
              <>{appLanguages.notFoundMessage[LANGUAGE_JAPANESE]}</>
            ) : (
              ''
            )}
            {lang === LANGUAGE_ENGLISH ? <>{appLanguages.notFoundMessage[LANGUAGE_ENGLISH]}</> : ''}
            {lang === LANGUAGE_CHINESE ? <>{appLanguages.notFoundMessage[LANGUAGE_CHINESE]}</> : ''}
            {lang === LANGUAGE_CHINESE_TRADITIONAL ? (
              <>{appLanguages.notFoundMessage[LANGUAGE_CHINESE_TRADITIONAL]}</>
            ) : (
              ''
            )}
            {lang === LANGUAGE_KOREAN ? <>{appLanguages.notFoundMessage[LANGUAGE_KOREAN]}</> : ''}
            {lang === LANGUAGE_THAI ? <>{appLanguages.notFoundMessage[LANGUAGE_THAI]}</> : ''}
            {lang === LANGUAGE_VIETAMESE ? (
              <>{appLanguages.notFoundMessage[LANGUAGE_VIETAMESE]}</>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

NotFound.propTypes = {
  apps: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  apps: state.apps,
});

const mapDispatchToProps = {};

const myStyles = () => ({});

export default compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(NotFound);
