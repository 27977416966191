import PropTypes from 'prop-types';
import profilePropTypes from './profile';

const userPropTypes = PropTypes.shape({
  uid: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  photoUrl: PropTypes.string,
  displayName: PropTypes.string,
  emailVerified: PropTypes.bool,
  providerData: PropTypes.arrayOf(profilePropTypes),
});

export default userPropTypes;
