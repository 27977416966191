import {
  AppBar,
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  StyleRules,
  Tab,
  Tabs,
  TextField as TextFieldd,
  Theme,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  Add as AddIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  //DonutLargeOutlined,
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ECategoryType } from '@models/category-type';
import { EQROperations } from '@models/qr-operations';
import { IQuery } from '@models/query';
import { ISerialRangePagination } from '@models/serial-range-pagination';
import { IStateApps } from '@models/state-app';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import { TSupportedLanguages } from '@models/supported-languages';
import {
  addScannedSerialCode,
  clearScannedSerialCode,
  clearSerialRange,
  delScannedSerialCode,
  openSerialEditDialog,
  openSnackbarMessage,
  setEditSerial,
  setPaginationSerialRange,
  setRegistrationDate,
  setSerialCodeRange,
  setSerialRangeFrom,
  setSerialRangeTo,
  setTab,
} from '@redux/actions/appsActions';
import { getScannedSerials } from '@redux/actions/serversActions';
import appLanguages from '@utils/app-languages';
import { checkSerialValidity, TSerialValidity } from '@utils/check-serial-validity';
import {
  extractSerialFromUrl,
  getSerialCodeErrorMessage,
  getTextFieldForSmall,
  LANGUAGE_DEFAULT,
  SERIAL_DIGIT,
  SERIAL_LIMIT_COUNT,
  SERIAL_PAGE_LIMIT_COUNT,
} from '@utils/common';
import { DefaultSerialPagination } from '@utils/default-serial-pagination';
import { functions } from '@utils/firebase';
//import { formatString } from '@utils/format-string';
import { getQueryForScannedSerials } from '@utils/get-scanned-serials-for-registration';
import { getUserCategory } from '@utils/get-user-category';
import { isOk } from '@utils/is-ok';
import { padZeroes } from '@utils/pad-zeroes';
import React from 'react';
import Reader from './lib'; // Wrapper for QrReader
import { connect } from 'react-redux';
import userEnv from 'userEnv';

import { compose } from 'redux';

import TabPanel, { a11yProps } from '../../../elements/TabPanel';

//import Icon from '@mui/material/Icon';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Import date time picker and necessary dependencies
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
//import TextField from '@mui/material/TextField';

const storage = window.localStorage;

const getFromToSerialErrorMessages = (
  fromSerial,
  toSerial,
  key,
  objectLength,
  serialInputObjects,
  lang: TSupportedLanguages = LANGUAGE_DEFAULT,
) => {
  let fromErrorMessage = getSerialCodeErrorMessage(fromSerial);
  let toErrorMessage = getSerialCodeErrorMessage(toSerial);

  if (!fromErrorMessage && !toErrorMessage) {
    const diff = parseInt(toSerial) - parseInt(fromSerial);
    if (diff < 0) toErrorMessage = appLanguages.startPointLowerInvalid[lang];
    if (key > 0) {
      for (let i = 0; i < objectLength; i += 1) {
        if (i !== parseInt(key)) {
          //console.log(`I : ${i} key: ${key}`);
          //Case both serials are inside a range which is lready entered
          if (
            parseInt(fromSerial) >= parseInt(serialInputObjects[i]['from']) &&
            parseInt(fromSerial) <= parseInt(serialInputObjects[i]['to']) &&
            parseInt(toSerial) >= parseInt(serialInputObjects[i]['from']) &&
            parseInt(toSerial) <= parseInt(serialInputObjects[i]['to'])
          ) {
            fromErrorMessage = appLanguages.serialAlreadyEntered[lang];
            toErrorMessage = appLanguages.serialAlreadyEntered[lang];
          }
          //From lies in between range of some other entry
          else if (
            parseInt(fromSerial) >= parseInt(serialInputObjects[i]['from']) &&
            parseInt(fromSerial) <= parseInt(serialInputObjects[i]['to'])
          ) {
            fromErrorMessage = appLanguages.serialAlreadyEntered[lang];
          }
          //To lies in between range of some other entry
          else if (
            parseInt(toSerial) >= parseInt(serialInputObjects[i]['from']) &&
            parseInt(toSerial) <= parseInt(serialInputObjects[i]['to'])
          ) {
            toErrorMessage = appLanguages.serialAlreadyEntered[lang];
          }
          //Both lies outside the range and engulf an existing entry
          else if (
            parseInt(fromSerial) < parseInt(serialInputObjects[i]['from']) &&
            parseInt(toSerial) > parseInt(serialInputObjects[i]['from'])
          ) {
            fromErrorMessage = appLanguages.serialAlreadyEntered[lang];
            toErrorMessage = appLanguages.serialAlreadyEntered[lang];
          }
        }
      }
    }
  }

  return { fromErrorMessage, toErrorMessage };
};

const getExceptErrorMessages = (
  fromSerial,
  toSerial,
  exceptSerial,
  key,
  objectLength,
  serialInputObjects,
  lang: TSupportedLanguages = LANGUAGE_DEFAULT,
) => {
  //console.log('Except serial list :' + exceptSerial);
  let exceptErrorMessage = '';
  if (exceptSerial.length !== 0) {
    for (let i = 0; i < exceptSerial.length; i += 1) {
      //console.log('Except serial in loop :' + exceptSerial[i]);
      exceptErrorMessage = getSerialCodeErrorMessage(exceptSerial[i]);

      if (!exceptErrorMessage) {
        if (
          parseInt(exceptSerial[i]) < parseInt(fromSerial) ||
          parseInt(exceptSerial[i]) > parseInt(toSerial)
        ) {
          exceptErrorMessage = appLanguages.serialNotInRange[lang];
          break;
        }
        //Repeated elements
        for (let j = 0; j < exceptSerial.length; j += 1) {
          if (j !== i) {
            if (exceptSerial[i] === exceptSerial[j] || exceptSerial[i] === `${exceptSerial[j]},`)
              exceptErrorMessage = appLanguages.duplicateSerial[lang];
          }
        }
      }
    }
  }
  return exceptErrorMessage;
};

var amount = 0;
let fromErrorMessage = {};
let toErrorMessage = {};
let exceptErrorMessage = {};
var finalSerial = [];
var confirmPushed = false;
var disableConfirm = false;

class SerialQRScanClass extends React.PureComponent<Props, State> {
  protected cameraSwitchKey;
  protected tabValueKey;

  constructor(props) {
    super(props);
    this.cameraSwitchKey = 'SerialQRScan.cameraSwitch';
    this.tabValueKey = 'SerialQRScan.tabValue';
    this.state = {
      tabValue: parseInt(storage.getItem(this.tabValueKey)) || 0,
      cameraSwitch: storage.getItem(this.cameraSwitchKey) || 'on',
      handleChangeScannedSerialsStrTimeout: undefined,
      updateScannedSerialsTimeout: undefined,
      handleScanTimeout: undefined,
      scannedSerialCodesStr: '',
      scanIntervalTime: 0,
      cameraId: undefined,
      devices: [],
      serialInputObjects: {
        0: { from: '', to: '', except: '', fromErr: '', toErr: '' },
      },
      registrationDate: new Date(),
    };
  }

  public async componentDidMount() {
    this.getConfig();
    // Load the serial data IF there are pagination serials
    if (isOk(this.props.apps?.serialRangePagination?.pageSerialCodes)) {
      this.updateSerialList();
    }

    // Get the list of video devices associated with the device
    if (navigator) {
      // request camera permission
      await navigator.mediaDevices
        .getUserMedia({ audio: false, video: { facingMode: 'environment' } })
        .then(function (stream) {
          stream.getTracks().forEach((x) => x.stop());
        })
        .catch((error) => {
          console.log(error);
        });
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoSelect = [];
          devices.forEach((device) => {
            if (device.kind === 'videoinput') {
              videoSelect.push(device);
            }
          });
          const sortedVideo = [];
          let frontCamera;
          videoSelect.forEach((device, i) => {
            if (i !== 0) {
              sortedVideo.push(device);
            } else if (i == 0) {
              frontCamera = device;
            }
          });
          frontCamera && sortedVideo.push(frontCamera);
          return sortedVideo;
        })
        .then((devices) => {
          this.setState({
            cameraId: devices[0].deviceId,
            devices,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  public render() {
    const {
      servers: { user, isGetRequesting, isRequesting },
    } = this.props;
    const {
      handleScanTimeout,
      handleChangeScannedSerialsStrTimeout,
      updateScannedSerialsTimeout,
      tabValue,
    } = this.state;
    const isNowLoading = Boolean(
      handleChangeScannedSerialsStrTimeout ||
        updateScannedSerialsTimeout ||
        handleScanTimeout ||
        isGetRequesting ||
        isRequesting,
    );

    const userCategory = getUserCategory(user);
    const isBrew = userCategory === ECategoryType.BREWERY;

    return (
      <>
        <Paper elevation={3}>
          {this.getTabSwitcher(isNowLoading)}
          {!isBrew && [
            <TabPanel value={tabValue} index={0}>
              {tabValue === 0 ? this.getScanWithInterQRCameraView(isNowLoading) : <Box />}
            </TabPanel>,
            <TabPanel value={tabValue} index={1}>
              {tabValue === 1 ? this.getScanWithExternalQRCameraView() : <Box />}
            </TabPanel>,
          ]}
          {isBrew && (
            <TabPanel value={tabValue} index={isBrew ? 0 : 2}>
              {tabValue === (isBrew ? 0 : 2) ? this.getBulkRegistrationView() : <Box />}
            </TabPanel>
          )}
        </Paper>
        <Box mt={1}>{this.getScannedSerialCodesView(isNowLoading)}</Box>
      </>
    );
  }

  protected getTabSwitcher(isNowLoading: boolean): JSX.Element {
    const { tabValue } = this.state;
    const {
      apps: { currentLanguage: lang },
      servers: { user },
    } = this.props;
    const userCategory = getUserCategory(user);
    const isBrew = userCategory === ECategoryType.BREWERY;

    return (
      <AppBar position='static' color='primary'>
        <Tabs
          value={tabValue}
          variant='scrollable'
          scrollButtons='auto'
          onChange={(_, v) => this.handleChangeTabValue(v)}
        >
          {!isBrew &&
            [
              <Tab
                disabled={isNowLoading}
                label={<Typography variant='caption'>{appLanguages.camera[lang]}</Typography>}
                {...a11yProps(0)}
              />,
              userEnv?.enableScanByUrl && (
                <Tab
                  disabled={isNowLoading}
                  label={<Typography variant='caption'>{appLanguages.externalQR[lang]}</Typography>}
                  {...a11yProps(1)}
                />
              ),
            ].filter((tab) => tab)}

          {isBrew && (
            <Tab
              disabled={isNowLoading}
              label={
                <Typography variant='caption'>{appLanguages.bundleRegistration[lang]}</Typography>
              }
              {...a11yProps(2)}
            />
          )}
        </Tabs>
      </AppBar>
    );
  }

  protected getScanWithInterQRCameraView(isNowLoading: boolean): JSX.Element {
    const {
      apps: { currentLanguage: lang },
      classes,
    } = this.props;
    const { cameraSwitch, handleScanTimeout } = this.state;
    const { cameraId, devices } = this.state;
    return (
      <>
        {!handleScanTimeout ? (
          <Typography variant='caption' color='textSecondary'>
            {appLanguages.pleaseBringTheQRCodeCloserToTheCamera[lang]}
          </Typography>
        ) : (
          <Typography variant='caption' color='textSecondary'>
            {appLanguages.reading[lang]}
          </Typography>
        )}
        <Paper className={classes.paper1}>
          <Grid container justify='flex-start' alignContent='flex-start' alignItems='flex-start'>
            <Grid item xs={12}>
              <ToggleButtonGroup
                size='small'
                exclusive
                value={cameraSwitch}
                onChange={(e, v) => {
                  this.setState({ cameraSwitch: v });
                  storage.setItem(this.cameraSwitchKey, v);
                }}
              >
                <ToggleButton value='on'>
                  <VideocamIcon fontSize='small' />
                </ToggleButton>
                <ToggleButton value='off'>
                  <VideocamOffIcon fontSize='small' />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
          </Grid>
          <Grid container justify='center' alignContent='center' alignItems='center'>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              {
                // UI Componet to choose camera for scanning.
                devices.length && (
                  <select
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState({ cameraId: undefined }, () => {
                        this.setState({ cameraId: value });
                      });
                    }}
                  >
                    {devices.map((deviceInfo, index) => (
                      <React.Fragment key={deviceInfo.deviceId}>
                        <option value={deviceInfo.deviceId}>{`Camera ${index}`}</option>
                      </React.Fragment>
                    ))}
                  </select>
                )
              }
              <Paper elevation={3}>
                {isNowLoading || cameraSwitch !== 'on' ? (
                  <Card>
                    <CardMedia className={classes.cardMedia} image='img/black.jpeg' title='...' />
                  </Card>
                ) : (
                  <div>
                    {cameraId && (
                      <Reader
                        chooseDeviceId={this.selectCamera}
                        delay={100}
                        resolution={1000}
                        style={{ width: '100%', height: 200 }}
                        onScan={(data) => this.handleScan(data)}
                        onError={(err) => window.console.warn('Failed scan QR.', err)}
                      />
                    )}
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }

  protected getScanWithExternalQRCameraView(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    const { scannedSerialCodesStr } = this.state;
    return (
      <>
        <Typography variant='caption' color='textSecondary'>
          {appLanguages.pleasePasteTheQRDataMessage[lang]}
        </Typography>
        <TextFieldd
          autoFocus
          variant='outlined'
          fullWidth
          multiline
          rows={1}
          rowsMax={3}
          value={scannedSerialCodesStr}
          onChange={(e) => this.handleChangeScannedSerialsStr(e)}
        />
      </>
    );
  }

  handleAdd = () => {
    //Function to create a new row for adding serials upon button click by creating new object everytime
    const objectLength = Object.keys(this.state.serialInputObjects).length;

    //console.log(objectLength);
    this.setState((prevState) => ({
      ...prevState,
      serialInputObjects: {
        ...prevState.serialInputObjects,
        [objectLength]: {
          from: '',
          to: '',
          except: '',
          exceptError: '',
          dummy: true,
        },
      },
    }));
    //console.log(this.state.serialInputObjects);
  };

  handleRemove = (key) => {
    const { [key]: renamedKey, ...remainingRows } = this.state.serialInputObjects;
    this.setState(
      (prevState) => ({
        ...prevState,
        serialInputObjects: { ...remainingRows },
      }),
      () => this.calculation(0),
    );
  };

  refreshcalculate = (key) => {
    const objectLength = Object.keys(this.state.serialInputObjects).length;
    this.setState((prevState) => ({
      ...prevState,
      serialInputObjects: {
        ...prevState.serialInputObjects,
      },
    }));
    for (let i = 0; i < objectLength; i += 1) {
      this.setState((prevState) => ({
        ...prevState,
        serialInputObjects: {
          ...prevState.serialInputObjects,
          [key]: {
            ...prevState.serialInputObjects[key],
            dummy: !this.state.serialInputObjects[key]['dummy'],
          },
        },
      }));
      this.calculation(i);
    }
  };

  calculation = (key) => {
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    //console.log('From value in this update - ' + this.state.serialInputObjects[key]['from']);
    //console.log('To value in this update - ' + this.state.serialInputObjects[key]['to']);
    const limit = 10000;
    const allSerialCodes = [];
    amount = 0;

    const objectLength = Object.keys(this.state.serialInputObjects).length;
    const excludeSerials = this.state.serialInputObjects[key]['except']
      ? this.state.serialInputObjects[key]['except'].split(',')
      : '';

    if (
      !isOk(this.state.serialInputObjects[key]['from']) ||
      !isOk(this.state.serialInputObjects[key]['to'])
    )
      return;
    //console.log('Exclude :' + excludeSerials);
    //console.log('Length Exclude :' + excludeSerials.length);
    //console.log('To :' + parseInt(this.state.serialInputObjects[key]['to']));
    //console.log('From :' + parseInt(this.state.serialInputObjects[key]['from']));
    //console.log('Except :' + parseInt(this.state.serialInputObjects[key]['except']));
    let errormessages = getFromToSerialErrorMessages(
      this.state.serialInputObjects[key]['from'],
      this.state.serialInputObjects[key]['to'],
      key,
      objectLength,
      this.state.serialInputObjects,
      lang,
    );
    fromErrorMessage[key] = errormessages.fromErrorMessage;
    toErrorMessage[key] = errormessages.toErrorMessage;
    //console.log(`Key ${key} - From error message : ` + fromErrorMessage[key]);
    //console.log(`Key ${key} - To error message : ` + toErrorMessage[key]);
    this.setState((prevState) => ({
      ...prevState,
      serialInputObjects: {
        ...prevState.serialInputObjects,
        [key]: {
          ...prevState.serialInputObjects[key],
          fromErr: errormessages.fromErrorMessage,
          toErr: errormessages.toErrorMessage,
        },
      },
    }));

    let exceptErr = getExceptErrorMessages(
      this.state.serialInputObjects[key]['from'],
      this.state.serialInputObjects[key]['to'],
      excludeSerials,
      key,
      objectLength,
      this.state.serialInputObjects,
      lang,
    );
    exceptErrorMessage[key] = exceptErr;
    //console.log(`Key ${key} - From error message - ` + exceptErrorMessage[key]);
    //console.log('To error message - ' + toErrorMessage[key]);
    //console.log('Except error message - ' + exceptErr);
    /*const { exceptErrorMessage } = getExceptErrorMessages(
      this.state.serialInputObjects[key]['from'],
      this.state.serialInputObjects[key]['to'],
      this.state.serialInputObjects[key]['except'],
      lang,
    );*/
    //var numberofSerials = 0;
    if (!fromErrorMessage[key] && !toErrorMessage[key] && !exceptErrorMessage[key]) {
      //console.log('Amount1 ' + amount);
      disableConfirm = false;
      for (let i = 0; i < objectLength; i += 1) {
        if (exceptErrorMessage[i] !== '') disableConfirm = true;
      }

      /*for (let i = 0; i < objectLength; i += 1) {
        numberofSerials +=
          parseInt(this.state.serialInputObjects[i]['to']) -
          parseInt(this.state.serialInputObjects[i]['from']) +
          1 -
          (this.state.serialInputObjects[i]['except'] ? parseInt(excludeSerials.length) : 0);
      }*/

      //Push all the serial codes now and exclude the ones you dont want
      if (amount <= limit) {
        Object.keys(this.state.serialInputObjects).map((key) => {
          let excludeList = this.state.serialInputObjects[key]['except']
            ? this.state.serialInputObjects[key]['except'].split(',')
            : '';
          if (excludeList.length !== 0) {
            for (let x = 0; x < excludeList.length; x += 1) {
              excludeList[x] = parseInt(excludeList[x]);
            }
          }
          let start_serial = parseInt(this.state.serialInputObjects[key]['from']);
          //console.log('Start Serial ' + start_serial);
          let end_serial = parseInt(this.state.serialInputObjects[key]['to']);
          //console.log('End Serial ' + end_serial);
          for (let j = start_serial; j <= end_serial; j += 1) {
            //console.log('J ' + j);
            //console.log(excludeList);
            !excludeList.includes(j)
              ? allSerialCodes.push(`${j}`) //console.log('Pushed ' + j))
              : null;
          }
        });

        if (confirmPushed) {
          for (let i = 0; i < allSerialCodes.length; i += 1) {
            finalSerial.push(`${allSerialCodes[i]}`);
          }
        }

        amount = allSerialCodes.length;
        //console.log('Amount ' + amount);
      } else {
        amount = allSerialCodes.length;
        //console.log('More than 10000, kindly check again');
      }
    } else {
      disableConfirm = true;
      return;
    }
  };

  onChangeRegistrationDate = (date) => {
    const { setRegistrationDate } = this.props;
    this.setState({
      registrationDate: date,
    });
    setRegistrationDate(date);
    return this.state.registrationDate;
  };

  protected getBulkRegistrationView(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
      classes,
    } = this.props;

    return (
      <Paper elevation={3} className={classes.paper1}>
        {Object.keys(this.state.serialInputObjects).map((key) => (
          <div key={key}>
            {/*Box for From range serial */}
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
                <Typography variant='caption' color='textSecondary' align='justify' noWrap={true}>
                  {appLanguages.from[lang]}
                </Typography>
              </Grid>
              <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
                {getTextFieldForSmall(
                  <Typography variant='caption'>{appLanguages.serialRangeStart[lang]}</Typography>,
                  appLanguages.serialInputSampleStart[lang],
                  this.state.serialInputObjects[key]['from'] || '',
                  (e) => this.handleChangeFromSerials(key, e.target.value),
                  Boolean(this.state.serialInputObjects[key]['fromErr']) || false,
                  this.state.serialInputObjects[key]['fromErr'] || '',
                )}
              </Grid>
              {/*Box for End range serial */}
              <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
                <Typography variant='caption' color='textSecondary' align='justify' noWrap={true}>
                  {appLanguages.to[lang]}
                </Typography>
              </Grid>
              <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
                {getTextFieldForSmall(
                  <Typography variant='caption'>{appLanguages.serialRangeEnd[lang]}</Typography>,
                  appLanguages.serialInputSampleEnd[lang],
                  this.state.serialInputObjects[key]['to'] || '',
                  (e) => this.handleChangeToSerials(key, e.target.value),
                  Boolean(this.state.serialInputObjects[key]['toErr']) || false,
                  this.state.serialInputObjects[key]['toErr'] || '',
                )}
              </Grid>
              {/*Box for Exclude serial */}

              {/*Added code for Excluding serials*/}
              <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
                <Typography variant='caption' color='textSecondary' align='justify' noWrap={true}>
                  {appLanguages.except[lang]}
                </Typography>
              </Grid>
              <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
                {getTextFieldForSmall(
                  <Typography variant='caption'>{appLanguages.serialExclude[lang]}</Typography>,
                  appLanguages.serialInputSampleExclude[lang],
                  this.state.serialInputObjects[key]['except'] || '',
                  (e) => this.handleChangeExceptSerials(key, e.target.value),
                  Boolean(exceptErrorMessage[key]) || false,
                  exceptErrorMessage[key] || '',
                )}
              </Grid>

              {/*Code ends here for Excluding serials*/}
              {/* Minus sign to remove a row */}
              <Grid item className={classes.gridItem} style={{ paddingLeft: '10px' }}>
                <div style={{ display: key === '0' ? 'none' : 'block' }}>
                  <Button onClick={(e) => this.handleRemove(key)}>
                    <RemoveCircleIcon fontSize='small'>remove_circle</RemoveCircleIcon>
                  </Button>
                </div>
              </Grid>

              <Grid item style={{ paddingLeft: '80px' }}>
                <div style={{ display: key !== '0' ? 'none' : 'block' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label={appLanguages.serialRegistrationDate[lang]}
                      value={this.state.registrationDate}
                      onChange={(newValue) => {
                        this.onChangeRegistrationDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
            </div>
          </div>
        ))}

        {
          //Static grid for add extra button, amount , and confirm button
        }
        <Grid container alignItems='flex-end'>
          <Button onClick={this.handleAdd}>
            <AddCircleIcon>add_circle</AddCircleIcon>
          </Button>
          <Grid item className={classes.gridItem} style={{ paddingLeft: '250px' }}>
            <Typography variant='caption' color='textSecondary'>
              {amount > 10000 ? (
                <p style={{ color: 'red' }}>
                  {appLanguages.amount[lang]} {amount} <br></br>{' '}
                  {appLanguages.excessShipmentsError[lang]}
                </p>
              ) : (
                `${appLanguages.amount[lang]}${' '} ${amount}`
              )}
            </Typography>
          </Grid>
          {/*<Grid item className={classes.gridItem} style={{ paddingLeft: '150px' }}>
            <Button
              size='small'
              variant='outlined'
              onClick={() =>
                this.refreshcalculate(Object.keys(this.state.serialInputObjects).length - 1)
              }
            >
              {appLanguages.calculate[lang]}
            </Button>
            </Grid>?*/}
          <Grid item className={classes.gridItem} style={{ paddingLeft: '170px' }}>
            <Button
              size='small'
              variant='outlined'
              onClick={() => this.reflectFromToSerial()}
              disabled={amount > 10000 || disableConfirm === true ? true : false}
            >
              {appLanguages.confirm[lang]}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  protected getScannedSerialCodesView(isNowLoading: boolean): JSX.Element {
    const {
      apps: { serialRangePagination, currentLanguage: lang },
      classes,
    } = this.props;
    if (!isOk(serialRangePagination?.pageSerialCodes)) return;
    return (
      <Paper elevation={3}>
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Grid container>
                  <Grid item>
                    <CheckIcon fontSize='inherit' />
                  </Grid>
                  <Grid item>
                    <Box ml={1} />
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2'>
                      {appLanguages.listOfScannedQRData[lang]}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </ListItem>
          {serialRangePagination?.pageSerialCodes.map((serialCode, index) =>
            this.getListItemSerial(serialCode, index, isNowLoading),
          )}
        </List>
        <div className={classes.pagination}>
          <Pagination
            count={Math.ceil(serialRangePagination?.totalCount / SERIAL_PAGE_LIMIT_COUNT)}
            page={serialRangePagination?.currentPage}
            color='primary'
            onChange={(event: React.ChangeEvent<unknown>, page: number) =>
              this.handlePaginationChange(event, page)
            }
          />
        </div>
      </Paper>
    );
  }

  protected handlePaginationChange(event: React.ChangeEvent<unknown>, page: number) {
    const {
      setPaginationSerialRange,
      apps: { serialRangePagination, scannedSerialCodes },
    } = this.props;
    const offset = (page - 1) * SERIAL_PAGE_LIMIT_COUNT;
    const pageSerialCodes = scannedSerialCodes.slice(offset, offset + SERIAL_PAGE_LIMIT_COUNT);
    this.updateSerialList(pageSerialCodes);
    setPaginationSerialRange({
      ...serialRangePagination,
      offset,
      pageSerialCodes,
      currentPage: page,
    });
  }

  protected getConfig() {
    const {
      servers,
      openSnackbarMessage,
      apps: { currentLanguage },
    } = this.props;
    const { user } = servers;
    const { category } = user.location;
    const request = functions.httpsCallable('getScanIntervalTime');
    request()
      .then((result) => {
        const { data } = result;
        const { brew, rest } = data;
        if (category === ECategoryType.BREWERY)
          this.setState({ scanIntervalTime: parseFloat(brew) || 1 });
        if (category === ECategoryType.RESTAURANT)
          this.setState({ scanIntervalTime: parseFloat(rest) || 1 });
      })
      .catch((e) =>
        openSnackbarMessage(
          'error',
          `${appLanguages.registrationError[currentLanguage]} (${e.message})`,
        ),
      );
  }

  protected initState() {
    this.setState({
      scannedSerialCodesStr: '',
    });
  }

  protected handleChangeTabValue(v) {
    const { tabValue } = this.state;
    if (tabValue === v) return;
    this.setState({ tabValue: v });
    storage.setItem(this.tabValueKey, `${v}`);
    setTimeout(() => this.initState(), 1);
    setTimeout(() => this.clearScannedData(), 1);
  }

  protected selectCamera = () => {
    return this.state.cameraId;
  };

  protected handleScan(data) {
    if (!data) return;
    const { servers } = this.props;
    const { scanIntervalTime, handleScanTimeout, updateScannedSerialsTimeout } = this.state;
    const isNowLoading = Boolean(
      handleScanTimeout ||
        updateScannedSerialsTimeout ||
        servers.isGetRequesting ||
        servers.isRequesting,
    );
    if (isNowLoading) return;

    const intervalTimeMSec = scanIntervalTime * 1000;
    const _finish = () =>
      setTimeout(() => this.setState({ handleScanTimeout: undefined }), intervalTimeMSec);

    const timeout = setTimeout(() => {
      try {
        const { apps, openSnackbarMessage } = this.props;
        const lang = apps.currentLanguage;
        const url = data.trim();
        const serialCode = extractSerialFromUrl(url);
        if (serialCode) {
          const { scannedSerialCodes } = apps;
          if (scannedSerialCodes.filter((s) => s === serialCode).length) {
            _finish();
            return;
          }
          this.addSerialCode(serialCode);
          _finish();
        } else {
          openSnackbarMessage('error', appLanguages.invalidQRCode[lang]);
          _finish();
        }
      } catch (error) {
        alert('Invalid QR');
      }
    }, 100);
    this.setState({ handleScanTimeout: timeout });
  }

  protected handleChangeScannedSerialsStr(e) {
    const text = e.target.value;
    this.setState({ scannedSerialCodesStr: text });
    // serialナンバーの抽出
    const { handleChangeScannedSerialsStrTimeout } = this.state;
    if (handleChangeScannedSerialsStrTimeout) clearTimeout(handleChangeScannedSerialsStrTimeout);
    const timeout = setTimeout(() => {
      const { addScannedSerialCode } = this.props;
      this.clearScannedData();
      for (const str of text.split('http')) {
        const url = `http${str}`;
        const serial = extractSerialFromUrl(url);
        if (serial) addScannedSerialCode(serial);
      }
      this.updateSerialList();
      this.setState({ handleChangeScannedSerialsStrTimeout: undefined });
    }, 10);
    this.setState({ handleChangeScannedSerialsStrTimeout: timeout });
  }

  protected handleChangeFromSerials = (key: any, data: string) => {
    //Function to process and update the object when FROM boxes are filled with serial number
    this.setState(
      (prevState) => ({
        ...prevState,
        serialInputObjects: {
          ...prevState.serialInputObjects,
          [key]: { ...prevState.serialInputObjects[key], from: data },
        },
      }),
      () => {
        this.calculation(key);
      },
    );
    //console.log(this.state.serialInputObjects);
    //this.props.setSerialRangeFrom(this.state.serialInputObjects[key]['from']);
    //this.checkFromToSerials(key);
    //this.calculation(key);
  };

  protected handleChangeToSerials = (key: any, data: string) => {
    //Function to process and update the object when TO boxes are filled with serial number
    this.setState(
      (prevState) => ({
        ...prevState,
        serialInputObjects: {
          ...prevState.serialInputObjects,
          [key]: { ...prevState.serialInputObjects[key], to: data },
        },
      }),
      () => {
        this.calculation(key);
      },
    );
    //console.log(this.state.serialInputObjects);
    //this.props.setSerialRangeTo(this.state.serialInputObjects[key]['to']);
    //this.checkFromToSerials(key);
    //this.calculation(key);
  };

  protected handleChangeExceptSerials = (key: any, data: string) => {
    //Function to process and update the object when EXCLUDE boxes are filled with serial number
    this.setState(
      (prevState) => ({
        ...prevState,
        serialInputObjects: {
          ...prevState.serialInputObjects,
          [key]: { ...prevState.serialInputObjects[key], except: data },
        },
      }),
      () => {
        this.calculation(key);
      },
    );
    //console.log(this.state.serialInputObjects);
    //this.props.setSerialRangeTo(this.state.serialInputObjects[key]["to"]);
    //this.checkFromToSerials(key);
    //this.calculation(key);
  };

  protected reflectFromToSerial() {
    const { setSerialCodeRange, setPaginationSerialRange } = this.props;
    //const numFromSerial = Number(fromSerial);
    //const numToSerial = Number(toSerial);
    confirmPushed = true;
    finalSerial = [];
    this.calculation(Object.keys(this.state.serialInputObjects).length - 1);

    // Get All Range
    const serialCodes: string[] = [];
    for (let i = 0; i < finalSerial.length; i += 1) {
      serialCodes.push(padZeroes(finalSerial[i], SERIAL_DIGIT));
    }

    // Update scanned serial codes
    setSerialCodeRange(serialCodes);

    // Update Pagination
    const pageSerialCodes = serialCodes.slice(0, SERIAL_PAGE_LIMIT_COUNT);
    this.updateSerialList(pageSerialCodes);

    setPaginationSerialRange({
      pageSerialCodes,
      currentPage: 1,
      offset: 0,
      totalCount: serialCodes.length,
    });
  }

  protected checkFromToSerials(key) {
    const {
      apps: { serialRangeFrom: fromSerial, serialRangeTo: toSerial },
    } = this.props;
    const {
      apps,
      delScannedSerialCode,
      apps: { currentLanguage: lang },
    } = this.props;
    const ObjectLength = Object.keys(this.state.serialInputObjects).length;

    if (!isOk(fromSerial) || !isOk(toSerial)) return;
    const allSerialCodes = [];
    const { fromErrorMessage, toErrorMessage } = getFromToSerialErrorMessages(
      fromSerial,
      toSerial,
      key,
      ObjectLength,
      this.state.serialInputObjects,
      lang,
    );
    if (!fromErrorMessage && !toErrorMessage) {
      const limit = fromSerial + SERIAL_LIMIT_COUNT;
      for (let code = fromSerial; code <= toSerial; code += 1) {
        if (limit < code) break;
        allSerialCodes.push(`${code}`);
      }
    }

    for (const scannedCode of apps.scannedSerialCodes) {
      if (allSerialCodes.filter((s) => s === scannedCode).length) continue;
      delScannedSerialCode(`${scannedCode}`);
    }
  }

  protected updateSerialList(serialCodes?: string[]) {
    const { updateScannedSerialsTimeout } = this.state;
    const {
      apps: { serialRangePagination },
      getScannedSerials,
    } = this.props;

    const displaySerialCodes = serialCodes || serialRangePagination?.pageSerialCodes;
    //Duval30Sep2021-Fix unknown server error and Unstable Bottle URL Recognition
    if (!(displaySerialCodes.length > 0)) {
      return;
    }
    if (updateScannedSerialsTimeout) clearTimeout(updateScannedSerialsTimeout);
    const timeout = setTimeout(() => {
      getScannedSerials(getQueryForScannedSerials(displaySerialCodes));
      this.setState({ updateScannedSerialsTimeout: undefined });
    }, 500);

    this.setState({ updateScannedSerialsTimeout: timeout });
  }

  protected addSerialCode(serialCode) {
    if (!serialCode) return;
    const { addScannedSerialCode } = this.props;
    addScannedSerialCode(`${serialCode}`);
    this.updateSerialList();
  }

  protected delSerialCode(serialCode) {
    if (!serialCode) return;
    const { delScannedSerialCode } = this.props;
    delScannedSerialCode(`${serialCode}`);
    setTimeout(() => this.updateSerialList(), 600);
  }

  protected clearScannedData() {
    const { setSerialCodeRange, setPaginationSerialRange } = this.props;
    setSerialCodeRange([]);
    setPaginationSerialRange(DefaultSerialPagination);
    this.updateSerialList();
  }

  protected getListItemSerial(serialCode, index, isNowLoading) {
    const {
      apps: { currentLanguage: lang },
      servers,
      classes,
      servers: { user },
      operation,
    } = this.props;
    {
      if (!serialCode) return '';
      const targetSerials = servers?.scannedSerials.filter((s) => s.code === serialCode);
      const targetSerial = targetSerials.length && targetSerials[0];
      const brandName = targetSerial ? targetSerial.brand.name : '';
      const addSerialButton = (
        <>
          <Button
            size='small'
            variant='outlined'
            color='inherit'
            startIcon={<AddIcon fontSize='small' color='inherit' />}
            onClick={() => {
              const { setEditSerial, openSerialEditDialog, setTab } = this.props;
              const obj = { code: serialCode };
              setTimeout(() => setTab(0), 1);
              setTimeout(() => setEditSerial(obj), 10);
              setTimeout(() => openSerialEditDialog(obj), 600);
            }}
          >
            {appLanguages.register[lang]}
          </Button>
        </>
      );
      const deleteButton = (
        <>
          <IconButton onClick={() => this.delSerialCode(serialCode)} edge='end' aria-label='delete'>
            <DeleteIcon />
          </IconButton>
        </>
      );

      const mainMessage = (
        <>
          <Typography variant='subtitle2' color='textPrimary'>
            {`${appLanguages.serialCode[lang]}: ${serialCode}`}
          </Typography>
          <Typography variant='caption' color='textSecondary'>
            {brandName
              ? `(${appLanguages.brand[lang]}: ${brandName || '-'})`
              : appLanguages.brandNotRegistered[lang]}
          </Typography>
        </>
      );
      // 参考(1). category => 0:none / 1:brewery / 2:distributor / 3:restaurant
      // 参考(2). operation => 0:'未指定' / 1:'出荷' / 2:'入荷' / 3:'販売'
      let subMessage = (
        <Alert severity='info' icon={false} className={classes.alert}>
          <Typography variant='caption' color='inherit'>
            {appLanguages.canBeRegistered[lang]}
          </Typography>
        </Alert>
      );
      const { category } = user.location;
      const serialValidity: TSerialValidity = checkSerialValidity(user, targetSerial, operation);
      switch (category) {
        case ECategoryType.BREWERY: {
          switch (serialValidity) {
            case 'serial-not-exist':
              // 酒蔵ユーザ: 存在しないシリアル番号
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.brandNotRegisteredInSerial[lang]}
                  </Typography>
                  {addSerialButton}
                </Alert>
              );
              break;
            case 'invalid-dispatch':
              // エラー: 酒蔵ユーザ・出荷の場合: 酒蔵が、別酒蔵の銘柄を、出荷登録しようとした場合。 （酒蔵シリアル登録 → 酒蔵出荷の時）
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.invalidBreweryData[lang]}
                  </Typography>
                </Alert>
              );
              break;
            case 'already-dispatched':
              // エラー: 既に出荷登録済のボトルを、酒蔵が再登録しようとした場合。（酒蔵出荷 → 酒蔵出荷の時）
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.alreadyRegisteredForShipping[lang]}
                  </Typography>
                </Alert>
              );
              break;
          }
          break;
        }
        case ECategoryType.DISTRIBUTOR: // ディストリビュータ
          switch (serialValidity) {
            case 'serial-not-exist':
              // ディストリビュータユーザ: 存在しないシリアル番号
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.serialNumberNotRegistered[lang]}
                  </Typography>
                </Alert>
              );
              break;
            case 'havent-received-yet':
              // エラー: ディストリビュータが入荷登録をせずに、出荷データを登録しようとした場合。 （酒蔵出荷 → ディストリビュータ入荷の場合）
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.arrivalNotRegistered[lang]}
                  </Typography>
                </Alert>
              );
              break;
            case 'already-dispatched':
              // エラー: 既に出荷登録済のボトルを、ディストリビュータが再登録しようとした場合。（ディストリビュータ出荷 → ディストリビュータ出荷の場合）
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.alreadyRegisteredForShipping[lang]}
                  </Typography>
                </Alert>
              );
              break;
            case 'receive-dispatch-location-discrepancy':
              // 警告: ディストリビュータの入荷と一致するかチェック （ディストリビュータ入荷 → ディストリビュータ販売の場合）
              subMessage = (
                <Alert severity='warning' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='inherit'>
                    {appLanguages.diffDistributorArrivedContactJCSC[lang]}
                  </Typography>
                </Alert>
              );
              break;
          }
          break;
        case ECategoryType.RESTAURANT: {
          switch (serialValidity) {
            case 'serial-not-exist':
              // レストランユーザ: 存在しないシリアル番号
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.serialNumberNotRegistered[lang]}
                  </Typography>
                </Alert>
              );
              break;
            case 'already-received':
              // [レストランユーザ・入荷の場合] エラー: 既に入荷登録済のボトルを、レストランが再登録しようとした場合。 （レストラン入荷 → レストラン入荷の時）
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.alreadyRegisteredForArrival[lang]}
                  </Typography>
                </Alert>
              );
              break;
            case 'havent-received-yet':
              // [レストランユーザ・入荷の場合] エラー: 酒蔵が出荷登録をせずに、レストランが入荷登録しようとした場合。（酒蔵シリアル登録 → レストラン入荷の時）
              // [レストランユーザ・販売の場合] エラー: レストランが入荷登録をせずに、販売データを登録しようとした場合。 （酒蔵出荷 → レストラン販売の時）
              subMessage =
                operation === EQROperations.RECEIVING ? (
                  <Alert severity='error' icon={false} className={classes.alert}>
                    <Typography variant='caption' color='error'>
                      {appLanguages.breweryShippingIncomplete[lang]}
                    </Typography>
                  </Alert>
                ) : (
                  <Alert severity='error' icon={false} className={classes.alert}>
                    <Typography variant='caption' color='error'>
                      {appLanguages.arrivalNotRegistered[lang]}
                    </Typography>
                  </Alert>
                );
              break;
            case 'receive-dispatch-location-discrepancy':
              // [レストランユーザ・入荷の場合] 警告: 酒蔵の出荷と一致するかチェック （酒蔵出荷 → レストラン入荷の時）
              subMessage = (
                <Alert severity='warning' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='inherit'>
                    {appLanguages.bottleContactJCSC[lang]}
                  </Typography>
                </Alert>
              );
              break;
            case 'invalid-dispatch':
              // エラー: 既に販売登録済のボトルを、レストランが再登録しようとした場合。（レストラン販売 → レストラン販売の時）
              subMessage = (
                <Alert severity='error' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='error'>
                    {appLanguages.alreadyRegisteredForSale[lang]}
                  </Typography>
                </Alert>
              );
              break;
            case 'receive-dispatch-location-discrepancy':
              // 警告: レストランの入荷と一致するかチェック （レストラン入荷 → レストラン販売の時）
              subMessage = (
                <Alert severity='warning' icon={false} className={classes.alert}>
                  <Typography variant='caption' color='inherit'>
                    {appLanguages.diffRestaurantArrivedContactJCSC[lang]}
                  </Typography>
                </Alert>
              );
              break;
          }
          break;
        }
        default:
          // 何もしない
          break;
      }

      return (
        <React.Fragment key={serialCode}>
          {index ? <Divider variant='middle' component='li' /> : ''}
          <ListItem button>
            <ListItemText
              primary={
                <>
                  {mainMessage}
                  {isNowLoading ? <Box /> : subMessage}
                </>
              }
            />
            <ListItemSecondaryAction>{deleteButton}</ListItemSecondaryAction>
          </ListItem>
        </React.Fragment>
      );
    }
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  operation: EQROperations;
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  getScannedSerials: (query: IQuery) => void;
  addScannedSerialCode: (serialCode: string) => void;
  delScannedSerialCode: (serialCode: string) => void;
  openSnackbarMessage: (type: string, message: string) => void;
  setTab: (tabNo: number) => void;
  setRegistrationDate: (date: Date) => void;
  setEditSerial: (serialObj: { code: string }) => void;
  openSerialEditDialog: (serialObj: { code: string }) => void;
  clearScannedSerialCode: () => void;
  setSerialRangeFrom: (range: string) => void;
  setSerialRangeTo: (range: string) => void;
  clearSerialRange: () => void;
  setPaginationSerialRange: (pagination: ISerialRangePagination) => void;
  setSerialCodeRange: (serialCodes: string[]) => void;
}

const mapStateToProps: (state: IStoreState) => Partial<IStateProps> = (state: IStoreState) => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  getScannedSerials,
  addScannedSerialCode,
  delScannedSerialCode,
  openSnackbarMessage,
  setTab,
  setRegistrationDate,
  setEditSerial,
  openSerialEditDialog,
  clearScannedSerialCode,
  setSerialRangeFrom,
  setSerialRangeTo,
  clearSerialRange,
  setPaginationSerialRange,
  setSerialCodeRange,
};

interface State {
  tabValue: number;
  cameraSwitch: string;
  handleChangeScannedSerialsStrTimeout: any;
  updateScannedSerialsTimeout: any;
  handleScanTimeout: any;
  scannedSerialCodesStr: string;
  scanIntervalTime: number;
  cameraId: any;
  devices: any;
  serialInputObjects: any;
  registrationDate: any;
}

const myStyles = (theme: Theme): StyleRules => ({
  root: { width: '100%' },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  actionsContainer: { marginBottom: theme.spacing(2) },
  resetContainer: { padding: theme.spacing(3) },
  paper: { padding: theme.spacing(0.1) },
  paper1: { padding: theme.spacing(1) },
  paper2: { padding: theme.spacing(2) },
  paper3: { padding: theme.spacing(3) },
  cardMedia: {
    height: 0,
    paddingTop: '100%',
  },
  gridItem: {
    paddingTop: theme.spacing(0.1),
    padding: theme.spacing(0.1),
  },
  alert: {
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0.1),
  },
  pagination: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const SerialQRScan = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SerialQRScanClass);
