import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  Hidden,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  ExitToApp as ExitToAppIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Redo as RedoIcon,
  Translate as TranslateIcon,
} from '@material-ui/icons';
import { ECategoryType } from '@models/category-type';
import { getUserCategory } from '@utils/get-user-category';
import React from 'react';
import { connect } from 'react-redux';
import userEnv from 'userEnv';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import {
  initializeApps,
  openProfileDialog,
  setCurrentLanguage,
} from '../../redux/actions/appsActions';
import { logout } from '../../redux/actions/authActions';
import { initializeServers, registerUserLang } from '../../redux/actions/serversActions';
import appLanguages from '../../utils/app-languages';
import {
  LANGUAGE_CHINESE,
  LANGUAGE_CHINESE_TRADITIONAL,
  LANGUAGE_ENGLISH,
  LANGUAGE_JAPANESE,
  LANGUAGE_KOREAN,
  LANGUAGE_THAI,
  LANGUAGE_VIETAMESE,
} from '../../utils/common';
import ProfileDialog from './ProfileDialog';

const storage = window.localStorage;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mouseOverButton: false,
      mouseOverMenu: false,
      langOpen: false,
    };
  }

  getTitle(title) {
    const { classes } = this.props;
    return (
      <Typography variant='subtitle1' noWrap className={classes.title}>
        {title}
      </Typography>
    );
  }

  handleAccountClick(e) {
    this.setState({ mouseOverButton: true, anchorEl: e.currentTarget });
  }

  handleAccountMouseOver(e) {
    setTimeout(() => this.handleAccountClick(e), 300);
  }

  handleAccountClose() {
    this.setState({ mouseOverButton: false, mouseOverMenu: false });
  }

  handleLeaveAccountButton() {
    setTimeout(() => this.setState({ mouseOverButton: false }), 100);
  }

  handleEnterAccountMenu() {
    this.setState({ mouseOverMenu: true });
  }

  handleLeaveAccountMenu() {
    setTimeout(() => this.setState({ mouseOverMenu: false }), 100);
  }

  handleClickLangMenu() {
    const { langOpen } = this.state;
    this.setState({ langOpen: !langOpen });
  }

  handleLogout() {
    const { logout, initializeApps, initializeServers } = this.props;
    setTimeout(() => initializeApps(), 10);
    setTimeout(() => initializeServers(), 10);
    setTimeout(() => storage.clear(), 50);
    setTimeout(() => logout(), 100);
  }

  async setLanguage(lang) {
    const { servers, registerUserLang } = this.props;
    let user = servers.user;
    user.language = lang;
    console.log('language setting');
    await registerUserLang(user);
  }

  render() {
    const { apps, servers, classes, openProfileDialog, setCurrentLanguage } = this.props;
    const { anchorEl, mouseOverButton, mouseOverMenu, langOpen } = this.state;
    const isRequesting = servers.isRequesting || servers.isGetRequesting;
    const { user } = servers;
    const { name, email, imageUrl, location } = user;
    const { isOpenProfileDialog } = apps;

    const progress = <LinearProgress color='secondary' />;

    const lang = apps.currentLanguage;
    const open = mouseOverButton || mouseOverMenu;

    const userCategory = getUserCategory(user);
    let appManualUrl;
    switch (userCategory) {
      case ECategoryType.BREWERY:
      case ECategoryType.ADMIN:
        appManualUrl = userEnv.appManualBreweryUrl;
        break;
      case ECategoryType.DISTRIBUTOR:
        appManualUrl = userEnv.appManualDistributorUrl;
        break;
      case ECategoryType.RESTAURANT:
        appManualUrl = userEnv.appManualRestaurantUrl;
        break;
    }

    return (
      <>
        <AppBar position='fixed' className={classes.appBar}>
          {isRequesting ? progress : ''}
          <Toolbar>
            <Grid container justify='center' alignItems='center' alignContent='center'>
              <Link href='./' color='inherit'>
                <Grid item xs>
                  <Grid container justify='flex-start'>
                    <Grid item>
                      <Avatar
                        onClick={() => window.location.reload()}
                        className={classes.logoAvatar}
                        src='/img/sakenomy.png'
                      />
                    </Grid>
                    <Grid item>
                      <Box ml={1} />
                    </Grid>
                    <Grid item>
                      <Button onClick={() => window.location.reload()} color='inherit'>
                        {this.getTitle(
                          <>
                            <b>SAKEBlockchain</b>
                            {' App'}
                          </>,
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Link>
              <Grid item xs>
                <Grid container justify='flex-end' alignItems='flex-end' alignContent='flex-end'>
                  <Tooltip
                    title={
                      <>
                        <Typography variant='body2'>{`${appLanguages.name[lang]}: ${name}`}</Typography>
                        <Typography variant='body2'>{`${appLanguages.email[lang]}: ${email}`}</Typography>
                      </>
                    }
                    aria-label='account'
                  >
                    <Avatar
                      aria-controls={open ? 'account-list' : null}
                      aria-haspopup='true'
                      onClick={(e) => this.handleAccountClick(e)}
                      onMouseLeave={() => this.handleLeaveAccountButton()}
                      className={classes.userAvatar}
                      alt={name}
                      src={imageUrl}
                    />
                  </Tooltip>
                  <Menu
                    id='account-list'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => this.handleAccountClose()}
                    MenuListProps={{
                      onMouseEnter: () => this.handleEnterAccountMenu(),
                      onMouseLeave: () => this.handleLeaveAccountMenu(),
                    }}
                  >
                    <MenuItem disabled onClick={() => null}>
                      <Card>
                        <CardHeader
                          avatar={
                            <Avatar className={classes.userAvatar} alt={name} src={imageUrl} />
                          }
                          title={name}
                          subheader={
                            <>
                              {`(${email})`}
                              <Grid mt={1} />
                              {location ? `@${location.name}` : ''}
                            </>
                          }
                        />
                      </Card>
                    </MenuItem>
                    <List>
                      {/* 言語設定 */}
                      <ListItem button onClick={() => this.handleClickLangMenu()}>
                        <ListItemIcon>
                          <TranslateIcon />
                        </ListItemIcon>
                        <ListItemText primary={appLanguages.languageSetting[lang]} />
                        {langOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItem>
                      <Collapse addEndListener={() => null} in={langOpen}>
                        <List>
                          <ListItem
                            button
                            onClick={() => {
                              setCurrentLanguage(LANGUAGE_ENGLISH);
                              this.setLanguage(LANGUAGE_ENGLISH);
                            }}
                            className={classes.nested}
                          >
                            <ListItemIcon>
                              {lang === LANGUAGE_ENGLISH ? (
                                <RadioButtonCheckedIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={appLanguages.langEnglish[LANGUAGE_ENGLISH]} />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              setCurrentLanguage(LANGUAGE_JAPANESE);
                              this.setLanguage(LANGUAGE_JAPANESE);
                            }}
                            className={classes.nested}
                          >
                            <ListItemIcon>
                              {lang === LANGUAGE_JAPANESE ? (
                                <RadioButtonCheckedIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={appLanguages.langJapanese[LANGUAGE_JAPANESE]} />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              setCurrentLanguage(LANGUAGE_CHINESE);
                              this.setLanguage(LANGUAGE_CHINESE);
                            }}
                            className={classes.nested}
                          >
                            <ListItemIcon>
                              {lang === LANGUAGE_CHINESE ? (
                                <RadioButtonCheckedIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={appLanguages.langChinese[LANGUAGE_CHINESE]} />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              setCurrentLanguage(LANGUAGE_CHINESE_TRADITIONAL);
                              this.setLanguage(LANGUAGE_CHINESE_TRADITIONAL);
                            }}
                            className={classes.nested}
                          >
                            <ListItemIcon>
                              {lang === LANGUAGE_CHINESE_TRADITIONAL ? (
                                <RadioButtonCheckedIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={appLanguages.langCantonese[LANGUAGE_CHINESE_TRADITIONAL]}
                            />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              setCurrentLanguage(LANGUAGE_KOREAN);
                              this.setLanguage(LANGUAGE_KOREAN);
                            }}
                            className={classes.nested}
                          >
                            <ListItemIcon>
                              {lang === LANGUAGE_KOREAN ? (
                                <RadioButtonCheckedIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={appLanguages.langKorean[LANGUAGE_KOREAN]} />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              setCurrentLanguage(LANGUAGE_THAI);
                              this.setLanguage(LANGUAGE_THAI);
                            }}
                            className={classes.nested}
                          >
                            <ListItemIcon>
                              {lang === LANGUAGE_THAI ? (
                                <RadioButtonCheckedIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={appLanguages.langKorean[LANGUAGE_THAI]} />
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              setCurrentLanguage(LANGUAGE_VIETAMESE);
                              this.setLanguage(LANGUAGE_VIETAMESE);
                            }}
                            className={classes.nested}
                          >
                            <ListItemIcon>
                              {lang === LANGUAGE_VIETAMESE ? (
                                <RadioButtonCheckedIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={appLanguages.langKorean[LANGUAGE_VIETAMESE]} />
                          </ListItem>
                        </List>
                      </Collapse>
                      {/* 流通管理ダッシュボードへのリンク */}
                      <ListItem
                        button
                        onClick={() => {
                          setTimeout(() => window.open(appManualUrl, '__black'), 1);
                        }}
                      >
                        <ListItemIcon>
                          <RedoIcon />
                        </ListItemIcon>
                        <ListItemText primary={appLanguages.openAppManual[lang]} />
                      </ListItem>
                      {/* ログアウト */}
                      <ListItem button onClick={() => this.handleLogout()}>
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={appLanguages.logout[lang]} />
                      </ListItem>
                    </List>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {isOpenProfileDialog ? <ProfileDialog /> : ''}
      </>
    );
  }
}

Header.propTypes = {
  apps: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  servers: PropTypes.objectOf(PropTypes.any).isRequired,
  logout: PropTypes.func.isRequired,
  initializeApps: PropTypes.func.isRequired,
  initializeServers: PropTypes.func.isRequired,
  openProfileDialog: PropTypes.func.isRequired,
  setCurrentLanguage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps = {
  logout,
  initializeApps,
  initializeServers,
  openProfileDialog,
  setCurrentLanguage,
  registerUserLang,
};

const myStyles = (theme) => ({
  title: { textTransform: 'none' },
  appBar: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoAvatar: {
    cursor: 'pointer',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  badge: {
    top: '50%',
    right: -3,
  },
  userAvatar: {
    cursor: 'pointer',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

export default compose(withStyles(myStyles), connect(mapStateToProps, mapDispatchToProps))(Header);
