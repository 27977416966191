import PropTypes from 'prop-types';
import userPropTypes from './user';

const authPropTypes = PropTypes.shape({
  initialized: PropTypes.bool,
  idToken: PropTypes.string,
  isRequesting: PropTypes.bool,
  errorCode: PropTypes.string,
  errorMessage: PropTypes.string,
  user: userPropTypes,
});

export default authPropTypes;
