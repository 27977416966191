import { IUser } from '@models/user';
import { convertBaiduToGoogleCoordinates } from './conversion';
import { getCurrentBaiduLocation } from './get-current-baidu-location';
import { getDistanceBaidu } from './get-distance-baidu';

export async function getBaiduScannedLocationInfo(user: IUser): Promise<ScannedLocationInfo> {
  const baiduLocation = await getCurrentBaiduLocation();
  const baiduDistance = getDistanceBaidu(
    {
      lat: baiduLocation.latitude,
      lng: baiduLocation.longitude,
      type: 'baidu',
    },
    {
      lat: user?.location?.latitude,
      lng: user?.location?.longitude,
      type: 'google',
    },
  );

  const distance = baiduDistance.toFixed();
  const googleMapScanCoords = convertBaiduToGoogleCoordinates(
    baiduLocation.latitude,
    baiduLocation.longitude,
  );

  return {
    distance,
    scanLat: googleMapScanCoords.lat,
    scanLng: googleMapScanCoords.lng,
    distanceText: `${distance / 1000} km`,
  };
}

interface ScannedLocationInfo {
  distance: number;
  scanLat: number;
  scanLng: number;
  distanceText: string;
}
