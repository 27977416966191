import { ECategoryType } from '@models/category-type';
import { ISerial } from '@models/serial';
import { IUser } from '@models/user';
import { getUserCategory } from './get-user-category';
import { EQROperations } from '@models/qr-operations';

export type TSerialValidity =
  | 'serial-not-exist'
  | 'invalid-dispatch'
  | 'havent-received-yet'
  | 'already-dispatched'
  | 'already-received'
  | 'receive-dispatch-location-discrepancy'
  | 'ok';

export function checkSerialValidity(
  user: IUser,
  serial: ISerial,
  operation?: EQROperations,
): TSerialValidity {
  const category = getUserCategory(user);
  switch (category) {
    case ECategoryType.BREWERY: {
      if (!serial) {
        // 酒蔵ユーザ: 存在しないシリアル番号
        return 'serial-not-exist';
      } else if (user.locationId !== serial.brand.locationId) {
        // エラー: 酒蔵ユーザ・出荷の場合: 酒蔵が、別酒蔵の銘柄を、出荷登録しようとした場合。 （酒蔵シリアル登録 → 酒蔵出荷の時）
        return 'invalid-dispatch';
      } else if (serial.brewShipAt) {
        // エラー: 既に出荷登録済のボトルを、酒蔵が再登録しようとした場合。（酒蔵出荷 → 酒蔵出荷の時）
        return 'already-dispatched';
      }
      break;
    }
    case ECategoryType.DISTRIBUTOR: // ディストリビュータ
      if (!serial) {
        // ディストリビュータユーザ: 存在しないシリアル番号
        return 'serial-not-exist';
      } else {
        if (!serial.distRecvAt) {
          // エラー: ディストリビュータが入荷登録をせずに、出荷データを登録しようとした場合。 （酒蔵出荷 → ディストリビュータ入荷の場合）
          return 'havent-received-yet';
        } else if (serial.distShipAt) {
          // エラー: 既に出荷登録済のボトルを、ディストリビュータが再登録しようとした場合。（ディストリビュータ出荷 → ディストリビュータ出荷の場合）
          return 'already-dispatched';
        } else if (user.locationId !== serial.recvDistLocId) {
          // 警告: ディストリビュータの入荷と一致するかチェック （ディストリビュータ入荷 → ディストリビュータ販売の場合）
          return 'receive-dispatch-location-discrepancy';
        }
      }
      break;
    case ECategoryType.RESTAURANT: {
      // レストランユーザ
      if (!serial) {
        // レストランユーザ: 存在しないシリアル番号
        return 'serial-not-exist';
      } else {
        // オペレーション毎に処理をメッセージを分ける
        switch (operation) {
          case EQROperations.RECEIVING: {
            // レストランユーザ・入荷の場合
            if (serial.restRecvAt) {
              // エラー: 既に入荷登録済のボトルを、レストランが再登録しようとした場合。 （レストラン入荷 → レストラン入荷の時）
              return 'already-received';
            } else if (!serial.brewShipAt) {
              // エラー: 酒蔵が出荷登録をせずに、レストランが入荷登録しようとした場合。（酒蔵シリアル登録 → レストラン入荷の時）
              return 'havent-received-yet';
            } else if (
              user.locationId !== serial.brewShipRestLocId &&
              user.locationId !== serial.distShipRestLocId
            ) {
              // 警告: 酒蔵の出荷と一致するかチェック （酒蔵出荷 → レストラン入荷の時）
              return 'receive-dispatch-location-discrepancy';
            }
            break;
          }
          case EQROperations.SELLING: {
            // レストランユーザ・販売の場合
            if (!serial.restRecvAt) {
              // エラー: レストランが入荷登録をせずに、販売データを登録しようとした場合。 （酒蔵出荷 → レストラン販売の時）
              return 'havent-received-yet';
            } else if (serial.restSoldAt) {
              // エラー: 既に販売登録済のボトルを、レストランが再登録しようとした場合。（レストラン販売 → レストラン販売の時）
              return 'invalid-dispatch';
            } else if (user.locationId !== serial.recvRestLocId) {
              // 警告: レストランの入荷と一致するかチェック （レストラン入荷 → レストラン販売の時）
              return 'receive-dispatch-location-discrepancy';
            }
            break;
          }
          default:
            // 何もしない
            break;
        }
      }
      break;
    }
    default:
      // 何もしない
      break;
  }
  return 'ok';
}
