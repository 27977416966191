import React from 'react';
import { StyleRules, Theme, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import { getStocks, setStocksQuery } from '@redux/actions/serversActions';
import { IQuery } from '@models/query';
import { DefaultQuery } from '@utils/default-query';
import { isOk } from '@utils/is-ok';
import appLanguages from '@utils/app-languages';
import { IStateApps } from '@models/state-app';
import MUIDataTable from 'mui-datatables';
import { appMuiTheme, muiDataTableCommonOptions } from '@utils/mui-datatables-ops';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { formatString } from '@utils/format-string';
import { getUserCategory } from '@utils/get-user-category';
import { ECategoryType } from '@models/category-type';

class StocksInfoClass extends React.PureComponent<Props> {
  public componentDidMount() {
    const { getStocks, setStocksQuery, servers } = this.props;
    const locationId = servers?.user?.location?.id;
    const userCategory = getUserCategory(servers?.user);
    const query = {
      ...DefaultQuery,
      ...(userCategory === ECategoryType.BREWERY && { brewLocId: locationId }),
      ...(userCategory === ECategoryType.DISTRIBUTOR && { distLocId: locationId }),
      ...(userCategory === ECategoryType.RESTAURANT && { restLocId: locationId }),
    };
    setStocksQuery(query);
    getStocks(query);
  }

  public render(): JSX.Element {
    const {
      servers,
      classes,
      apps: { currentLanguage: lang },
    } = this.props;
    if (!isOk(servers?.stocks)) return <React.Fragment />;
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={appMuiTheme}>
          <MUIDataTable
            title=''
            columns={[
              {
                name: 'brandName',
                label: appLanguages.brand[lang],
              },
              {
                name: 'stocks',
                label: appLanguages.stock[lang],
              },
            ]}
            data={servers?.stocks?.map((stock) => [
              stock.name,
              formatString(appLanguages.unitBottle[lang], { unit: String(stock.count) }),
            ])}
            options={{
              ...muiDataTableCommonOptions(),
              download: false,
              filter: false,
              search: false,
              expandableRows: false,
              expandableRowsOnClick: false,
              pagination: false,
              selectableRowsHeader: false,
            }}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

const myStyles = (theme: Theme): StyleRules => ({
  table: {
    minWidth: 650,
  },
  container: {
    paddingBottom: '20px',
  },
});

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  getStocks: (query: IQuery) => void;
  setStocksQuery: (query: IQuery) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  getStocks,
  setStocksQuery,
};

export const StocksInfo = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(StocksInfoClass);
