import { Box, Grid, Paper, StyleRules, Theme } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IQuery } from '@models/query';
import { ISerial } from '@models/serial';
//import { ISerials } from '@models/serials';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import { functions } from '@utils/firebase';
//Grid libraries and packages
import { GridColDef } from '@mui/x-data-grid';
import {
  delSerial,
  getSerialBrands,
  getSerials,
  getSerialSlips,
  registerSerialsCancelShipping,
  setSerialsQuery,
} from '@redux/actions/serversActions';
import appLanguages from '@utils/app-languages';
import {
  getDateRangePickerSummaryFunction,
  getFormattedDateString,
  nowrapHeader,
} from '@utils/common';
import { LIMIT_PAGINATION_MAX } from '@utils/default-query';
import {
  appMuiTheme,
  getSelectCustomBodyRender,
  muiDataTableCommonOptions,
  getSelectCustomBodyRenderSummaryFunction,
} from '@utils/mui-datatables-ops';
import React from 'react';
import { connect } from 'react-redux';

import { IStateApps } from 'models/state-app';
import { compose } from 'redux';

import {
  openSerialEditDialog,
  openSnackbarMessage,
  setEditSerial,
  setSerialCustomToolbarSearchKeyword,
  toggleSerialCustomToolbarSearchBox,
} from '../../../redux/actions/appsActions';

//Summary grouping mui data table libraries
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';

let filter_toggle = false;
let final_rowData_default_one_year = [];
let final_rowData = [];
let count_and_brand = {};
let count = {};

class SummaryFunctionClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = { showAddSerialButton: true };
  private initialFilters = {
    filterBrewShipAt: [],
    filterSalesOrder: '',
    filterDestinationId: '',
    filterDistDestinationId: '',
    filterBrandId: '',
  };
  public query = {};
  public pages = {
    offset: 0,
    limit: LIMIT_PAGINATION_MAX,
  };

  constructor(props) {
    super(props);
    const { apps, servers, setSerialsQuery } = this.props;
    const lang = apps.currentLanguage;
    const { user } = servers;
    const { location } = user;

    const orderBy = [
      ['brewShipAt', 'DESC'],
      ['code', 'ASC'],
    ];

    const columns = [];

    const query = {
      ...servers.serialsQuery,
      order: orderBy,
      searchText: null,
      where: {
        brewShipAt: { $ne: null },
        brewLocId: location.id,
      },
      offset: 0,
    };
    this.query = query;
    setSerialsQuery(this.query);

    const columns_summary_function = [];

    columns_summary_function.push({
      name: 'brewShipAt',
      label: nowrapHeader(appLanguages.shipAt[lang]),
      options: {
        customBodyRender: (v) => this.getShipAtText(v, lang),
      },
    });

    columns_summary_function.push({
      name: 'brewShipDistLocId',
      label: nowrapHeader(appLanguages.shippingDestination[lang]),
      options: {
        customBodyRender: (v, tableMeta) => {
          //console.log(v, tableMeta);
          if (v !== null) {
            return getSelectCustomBodyRender(this.getBrewShipRestLocOptionMap(v), v);
          } else {
            return getSelectCustomBodyRender(
              this.getBrewShipRestLocOptionMap(tableMeta.rowData[3]),
              tableMeta.rowData[3],
            );
          }
        },
      },
    });

    columns_summary_function.push({
      name: 'slip',
      label: nowrapHeader(appLanguages.slipNum[lang]),
      options: {
        customBodyRender: (v) => this.getSlipText(v),
      },
    });

    columns_summary_function.push({
      name: 'brewShipRestLocId',
      label: 'Serial number',
      options: {
        display: false,
      },
    });

    columns_summary_function.push({
      name: 'slip',
      label: 'Dummy column to store slip or brewSHipAt-destination if slip is empty',
      options: {
        display: false,
        customBodyRender: (v, tableMeta) => {
          if (v !== '') {
            return v;
          } else {
            let destination = tableMeta.rowData[3] || tableMeta.rowData[1];
            return `${tableMeta.rowData[0]}-${destination}`;
          }
        },
      },
    });

    this.state = {
      dialogopenflag: false,
      cancelShipFlag: false,
      enableFilter: false,
      filterTimeout: undefined,
      initialized: false,
      ...this.initialFilters,
      columns,
      serialsTotalCounts: 0,
      searchTimeout: undefined,
      count: '',
      refreshData: false,
      rowinfo: null,
      selectedrows: [],
      checked: false,
      columns_summary_function,
      currentPageNo: 0,
    };
  }

  async componentDidMount() {
    //const {serialsList} = this.props;
    //console.log('Serials list ' + serialsList);
    //this.fetchSerials();
  }

  async fetchSerials() {
    const self = this;
    const request = functions.httpsCallable('getSerials');
    let cancelShipFlag = false;
    const response = await request({ query: this.query, cancelShippingFlag: cancelShipFlag });
    const { data } = response;
    data.objects = data.objects.filter(function (item) {
      console.log(item);
      return item.status !== 6;
    });
    self.setState({
      initialized: true,
      serialsTotalCounts: data.totalCounts,
    });
  }

  async fetchSerials_filter(query) {
    const self = this;
    const request = functions.httpsCallable('getSerials');
    let cancelShipFlag = true;
    const response = await request({ query: query, cancelShippingFlag: cancelShipFlag });
    const { data } = response;
    data.objects = data.objects.filter(function (item) {
      console.log(item);
      return item.status !== 6;
    });
    self.setState({
      initialized: true,
      serialsTotalCounts: data.totalCounts,
    });
  }

  //////////////////Functions for the columns
  getSlipText(text) {
    return text ? text : 'N/A';
  }

  getBrewShipRestLocOptionMap(v) {
    const { servers } = this.props;
    const optionMap = {};
    servers?.locations?.forEach((o) => {
      optionMap[o.id] = o.name;
    });
    if (!(v in optionMap)) {
      optionMap[v] = 'N/A';
    }
    return optionMap;
  }

  getShipAtText(dateString, lang) {
    let shipAtText = appLanguages.unshipped[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      shipAtText = getFormattedDateString(date);
    } else {
      shipAtText = 'N/A';
    }
    return shipAtText;
  }

  getBrandOptionMap() {
    const { servers } = this.props;
    const optionMap = {};
    servers.serialBrands.map((o) => {
      optionMap[o.id] = o.name;
      return o.id;
    });
    //console.log(optionMap);
    return optionMap;
  }

  getBrandOptionMapSort(v) {
    const { servers } = this.props;
    const optionMap = {};
    servers.serialBrands.map((o) => {
      optionMap[o.id] = o.name;
    });
    //console.log(optionMap);
    return optionMap[v];
  }

  //////////////////////////////////////////////

  handleChangeBrewShipAt(v) {
    filter_toggle = true;
    //console.log('Value of filter :' + v[0] + ',' + v[1]);
    //console.log('Type of filter :' + typeof v);
    this.handleChangeTime('filterBrewShipAt', 'brewShipAt', v);
  }

  handleChangeTime(filterKey, dbKey, v) {
    const from = v && v.length && new Date(v[0].setHours(0, 0, 0));
    //console.log('From :' + from);
    const to = v && v.length && new Date(v[1].setHours(23, 59, 59));
    v[0] !== undefined && v[1] !== undefined
      ? //(console.log('entered here'),
        (final_rowData = final_rowData.filter(
          (d) => new Date(d.brewShipAt) > from && new Date(d.brewShipAt) < to,
        ))
      : //console.log(JSON.stringify(final_rowData, null, 4)))
        (filter_toggle = false);
    const newState: Partial<State> = {};

    newState[filterKey] = v;
    this.setState(newState as State);
  }

  handleOnChangePage(currentPage) {
    //const { servers } = this.props;
    //const { limit } = servers.serialsQuery;

    this.setState(
      (prevState) => ({ ...prevState, currentPageNo: currentPage }),
      () => {},
    );
  }

  render() {
    const {
      apps,

      classes,
      apps: {},
      serialsList,
    } = this.props;
    const { columns_summary_function, filterBrewShipAt } = this.state;
    //const { offset, limit } = servers.serialsQuery;

    let limit = 5;
    const lang = apps.currentLanguage;
    let rowData = serialsList || [];
    //Todays date for the todays summary table
    let count_and_brand_today = {};
    let total_count_today = 0;
    let today_date = new Date();
    let today_date_number = today_date.getDate();
    let today_month_number = today_date.getMonth();
    let today_year_number = today_date.getFullYear();

    //////Calculations and code block for todays summary table
    for (var key in rowData) {
      let date = new Date(rowData[key]['brewShipAt']).getDate();
      let month = new Date(rowData[key]['brewShipAt']).getMonth();
      let year = new Date(rowData[key]['brewShipAt']).getFullYear();
      if (
        date === today_date_number &&
        month === today_month_number &&
        year === today_year_number
      ) {
        if (
          getSelectCustomBodyRenderSummaryFunction(
            this.getBrandOptionMap(),
            rowData[key]['brandId'],
          ) in count_and_brand_today
        ) {
          count_and_brand_today[
            getSelectCustomBodyRenderSummaryFunction(
              this.getBrandOptionMap(),
              rowData[key]['brandId'],
            )
          ] += 1;
          total_count_today += 1;
        } else {
          count_and_brand_today[
            getSelectCustomBodyRenderSummaryFunction(
              this.getBrandOptionMap(),
              rowData[key]['brandId'],
            )
          ] = 1;
          total_count_today += 1;
        }
      }
    }
    let data_today_summary = [];
    Object.keys(count_and_brand_today).map((key, index) => {
      data_today_summary.push({
        brand: key,
        stock: count_and_brand_today[key] + appLanguages.bottles[lang],
      });
    });
    //console.log(data_today_summary);
    let column_headers_today = [
      {
        name: 'brand',
        label: appLanguages.brand[lang],
        options: { setCellProps: () => ({ style: { minWidth: '180px' } }) },
      },
      {
        name: 'stock',
        label: `${appLanguages.stockCountTotal[lang]} : ${total_count_today} ${appLanguages.bottles[lang]}`,
        options: { setCellProps: () => ({ style: { minWidth: '180px' } }) },
      },
    ];
    //console.log(JSON.stringify(count_and_brand_today, null, 4));

    //////////////**************************/////////////////

    //Set all brewShipAt dates rounded to nearest minute
    for (var key in rowData) {
      let date = rowData[key]['brewShipAt'];
      //console.log(JSON.stringify(rowData[key], null, 4));
      let newDate = new Date(date).setSeconds(0);
      let newISODateRounded = new Date(newDate);
      rowData[key]['brewShipAt'] = newISODateRounded.toISOString();
    }

    //Code block to modify the serialData nd group common slip Data
    let tempSlipGroup = [];
    let first_index_slip_occurence = [];
    for (var key in rowData) {
      for (var secondKey in rowData[key]) {
        //Create a new list with only slip Groups
        if (secondKey === 'slip') {
          if (rowData[key][secondKey] === '') {
            let destination =
              rowData[key]['brewShipRestLocId'] || rowData[key]['brewShipDistLocId'];
            tempSlipGroup.includes(`${rowData[key]['brewShipAt']}-${destination}`)
              ? ''
              : (tempSlipGroup.push(`${rowData[key]['brewShipAt']}-${destination}`),
                first_index_slip_occurence.push(key));
            //console.log(JSON.stringify(rowData[key], null, 4));
          } else {
            tempSlipGroup.includes(rowData[key][secondKey])
              ? ''
              : (tempSlipGroup.push(rowData[key][secondKey]), first_index_slip_occurence.push(key));
          }
        }
      }
    }
    //console.log('tempDateGroup :' + tempDateGroup);
    //console.log('tempslipGroup :' + tempSlipGroup);
    //console.log('first index ' + first_index_slip_occurence);
    if (!filter_toggle) {
      final_rowData = [];

      //Case when slip has multiple or single serial numbers and we need to find first and last
      let serial_no_range_all_slips = {};
      let brand_info_all_slips = {}; //Store the brand ID of each bottle to later map and find the stock count of each brand in each slip
      // Object which stores brand id and count of that brand id within each slip
      for (var slip_index in tempSlipGroup) {
        serial_no_range_all_slips[tempSlipGroup[slip_index]] = [];
        brand_info_all_slips[tempSlipGroup[slip_index]] = [];
        count_and_brand[tempSlipGroup[slip_index]] = {};
        count[tempSlipGroup[slip_index]] = 0;
      }
      for (var slip_index in tempSlipGroup) {
        for (var key in rowData) {
          if (rowData[key]['slip'] === tempSlipGroup[slip_index]) {
            serial_no_range_all_slips[tempSlipGroup[slip_index]].push(rowData[key]['code']);
            brand_info_all_slips[tempSlipGroup[slip_index]].push(
              getSelectCustomBodyRenderSummaryFunction(
                this.getBrandOptionMap(),
                rowData[key]['brandId'],
              ),
            );
            serial_no_range_all_slips[tempSlipGroup[slip_index]].sort();
          } else if (rowData[key]['slip'] === '') {
            let destination =
              rowData[key]['brewShipRestLocId'] || rowData[key]['brewShipDistLocId'];
            if (tempSlipGroup[slip_index] === `${rowData[key]['brewShipAt']}-${destination}`) {
              serial_no_range_all_slips[tempSlipGroup[slip_index]].push(rowData[key]['code']);
              brand_info_all_slips[tempSlipGroup[slip_index]].push(
                getSelectCustomBodyRenderSummaryFunction(
                  this.getBrandOptionMap(),
                  rowData[key]['brandId'],
                ),
              );
              serial_no_range_all_slips[tempSlipGroup[slip_index]].sort();
            }
          }
        }
      }
      //console.log(brand_info_all_slips);

      //Count the number of bottles for each brandid within a slip
      for (var i in brand_info_all_slips) {
        //console.log(i);
        for (var index in brand_info_all_slips[i]) {
          //console.log(typeof(a[i][index]))
          if (brand_info_all_slips[i][index] in count_and_brand[i]) {
            count_and_brand[i][brand_info_all_slips[i][index]] += 1;
            count[i] += 1;
          } else {
            count_and_brand[i][brand_info_all_slips[i][index]] = 1;
            count[i] += 1;
          }
        }
        // console.log(i)
      }

      //console.log(
      //  'serial_no_range_all_slips :' + JSON.stringify(serial_no_range_all_slips, null, 4),
      //);
      //console.log('Brand Id object : ' + JSON.stringify(brand_info_all_slips, null, 4));
      //console.log('Count and brand :' + JSON.stringify(count_and_brand, null, 4));

      //Append all the slips with range of serial number for each subslip to final row data
      for (var slip_index in tempSlipGroup) {
        final_rowData.push({
          code: `${
            serial_no_range_all_slips[tempSlipGroup[slip_index]][0]
          } - ${serial_no_range_all_slips[tempSlipGroup[slip_index]].at(-1)}`,
          brewShipAt: rowData[first_index_slip_occurence[slip_index]]['brewShipAt'],
          brewShipDistLocId: rowData[first_index_slip_occurence[slip_index]]['brewShipDistLocId'],
          slip: rowData[first_index_slip_occurence[slip_index]]['slip'],
          brewShipRestLocId: rowData[first_index_slip_occurence[slip_index]]['brewShipRestLocId'],
        });
      }
      final_rowData.sort((b, a) => {
        return a.brewShipAt < b.brewShipAt ? -1 : a.brewShipAt > b.brewShipAt ? 1 : 0;
      });
      //console.log('Final row data : ' + JSON.stringify(final_rowData, null, 4));
      //End of case

      //Default case of most recent one year display until filter is applied

      var date = new Date();
      date.setFullYear(date.getFullYear() - 1);
      date.setHours(0, 0, 0, 0);

      final_rowData_default_one_year = final_rowData.filter((o) => new Date(o.brewShipAt) >= date);
    } else {
      //Do nothing because final_rowData has already been filtered in the handleChangeTime function
    }

    /* 参考: https://github.com/gregnb/mui-datatables */
    //const isRequesting = servers.isRequesting || servers.isGetRequesting || !initialized;

    return (
      <>
        <br></br>
        <MuiThemeProvider theme={appMuiTheme}>
          <Paper className={classes.paper}>
            <Box>
              <h4>{appLanguages.todaySummarybrew[lang]}</h4>
            </Box>
            <div
              style={{
                paddingTop: 25,
                paddingBottom: 40,
              }}
            >
              <MUIDataTable
                title=''
                data={data_today_summary}
                options={{
                  ...muiDataTableCommonOptions(),
                  responsive: 'standard',
                  download: false,
                  filter: false,
                  search: false,
                  expandableRows: false,
                  expandableRowsOnClick: false,
                  pagination: false,
                  sort: false,
                  selectableRowsHeader: false,
                }}
                columns={column_headers_today}
              />
            </div>

            <Box>
              <h4>{appLanguages.shippingHistory[lang]}</h4>
            </Box>

            <div>
              <Grid item style={{ paddingBottom: 10 }}>
                {
                  //Change handleChangeBrewShipAt to the function you are going to create to handle dates
                  getDateRangePickerSummaryFunction(
                    appLanguages.shipAt[lang],
                    filterBrewShipAt,
                    (v) => this.handleChangeBrewShipAt(v),
                    lang,
                  )
                }
              </Grid>
            </div>

            <MUIDataTable
              title=''
              data={
                !filter_toggle
                  ? final_rowData_default_one_year.slice(
                      this.state.currentPageNo * limit,
                      this.state.currentPageNo * limit + limit <=
                        final_rowData_default_one_year.length
                        ? this.state.currentPageNo * limit + limit
                        : final_rowData_default_one_year.length,
                    )
                  : final_rowData.slice(
                      this.state.currentPageNo * limit,
                      this.state.currentPageNo * limit + limit <= final_rowData.length
                        ? this.state.currentPageNo * limit + limit
                        : final_rowData.length,
                    )
              } //Custom pagination to render rows in sets of 50
              //Destintion
              columns={columns_summary_function}
              options={{
                ...muiDataTableCommonOptions(),
                responsive: 'standard',
                download: false,
                expandableRows: true,
                expandableRowsOnClick: true,
                expandableRowsHeader: false,
                sort: false,
                renderExpandableRow: (rowData, rowMeta) => {
                  let tempSlip = rowData[4];
                  //console.log('rowMeta ' + JSON.stringify(rowMeta[0]['rowIndex'], null, 4));
                  //console.log(rowData, rowMeta);
                  let data = [];
                  Object.keys(count_and_brand[tempSlip]).map((key, index) => {
                    data.push({
                      brand: key,
                      stock: count_and_brand[tempSlip][key] + appLanguages.bottles[lang],
                    });
                  });
                  let column_headers = [
                    {
                      name: 'brand',
                      label: appLanguages.brand[lang],
                      options: { setCellProps: () => ({ style: { minWidth: '180px' } }) },
                    },
                    {
                      name: 'stock',
                      label: `${appLanguages.stockCountTotal[lang]} : ${count[tempSlip]} ${appLanguages.bottles[lang]}`,
                      options: { setCellProps: () => ({ style: { minWidth: '180px' } }) },
                    },
                  ];
                  let rowData_CancelShipping_SummaryFunction = [];
                  for (var key in serialsList) {
                    let destination =
                      serialsList[key]['brewShipRestLocId'] ||
                      serialsList[key]['brewShipDistLocId'];
                    if (serialsList[key]['slip'] !== '' && serialsList[key]['slip'] === tempSlip) {
                      rowData_CancelShipping_SummaryFunction.push(serialsList[key]);
                    } else if (
                      serialsList[key]['slip'] === '' &&
                      `${serialsList[key]['brewShipAt']}-${destination}` === tempSlip
                    ) {
                      rowData_CancelShipping_SummaryFunction.push(serialsList[key]);
                    }
                  }
                  //console.log(
                  //  'New subdata for CancelShipping :' +
                  //    JSON.stringify(rowData_CancelShipping_SummaryFunction, null, 4),
                  //);

                  const columns_datagrid = [];

                  /*columns.push({
      field: 'id',
      options: { ...disableDisplayOpts },
    });
    */

                  columns_datagrid.push({
                    field: 'code',
                    headerName: nowrapHeader(appLanguages.serialCode[lang]),
                    flex: 1,
                    minWidth: 150,
                  });

                  columns_datagrid.push({
                    field: 'brandId',
                    headerName: nowrapHeader(appLanguages.brand[lang]),
                    renderCell: (params) =>
                      getSelectCustomBodyRender(this.getBrandOptionMap(), params.row.brandId),
                    flex: 1,
                    minWidth: 100,
                    hide: false,

                    sortComparator: (v1, v2) => {
                      var rest1: any = this.getBrandOptionMapSort(v1);
                      var rest2: any = this.getBrandOptionMapSort(v2);
                      return rest1.localeCompare(rest2);
                    },
                    sortingOrder: ['asc', 'desc'],
                  });

                  columns_datagrid.push({
                    field: 'slip',
                    headerName: nowrapHeader(appLanguages.slipNum[lang]),

                    renderCell: (params) => this.getSlipText(params.row.slip),

                    flex: 1,
                    minWidth: 150,
                    hide: true,
                    sortComparator: (v1, v2) => {
                      return v1.localeCompare(v2);
                    },
                    sortingOrder: ['asc', 'desc'],
                  });

                  columns_datagrid.push({
                    field: 'brewShipAt',
                    headerName: nowrapHeader(appLanguages.shipAt[lang]),
                    renderCell: (params) => this.getShipAtText(params.row.brewShipAt, lang),
                    flex: 1,
                    minWidth: 150,
                    hide: true,
                  });

                  columns_datagrid.push({
                    field: 'brewShipDistLocId',
                    headerName: nowrapHeader(appLanguages.selectDistToShip[lang]),
                    renderCell: (params) =>
                      getSelectCustomBodyRender(
                        this.getBrewShipRestLocOptionMap(params.row.brewShipDistLocId),
                        params.row.brewShipDistLocId,
                      ),
                    flex: 1,
                    minWidth: 150,
                    sortComparator: (v1, v2, param1, param2) => {
                      var rest1: any = this.getBrewShipRestLocOptionMap(v1);
                      var rest2: any = this.getBrewShipRestLocOptionMap(v2);
                      let p1: any = rest1[v1];
                      let p2: any = rest2[v2];

                      return p1.localeCompare(p2);
                    },
                    sortingOrder: ['asc', 'desc'],
                    hide: true,
                  });

                  columns_datagrid.push({
                    field: 'distShipRestLocId',
                    headerName: nowrapHeader(appLanguages.restaurant2[lang]),
                    renderCell: (params) =>
                      getSelectCustomBodyRender(
                        this.getBrewShipRestLocOptionMap(params.row.distShipRestLocId),
                        params.row.distShipRestLocId,
                      ),
                    flex: 1,
                    minWidth: 150,
                    sortComparator: (v1, v2, param1, param2) => {
                      var rest1: any = this.getBrewShipRestLocOptionMap(v1);
                      var rest2: any = this.getBrewShipRestLocOptionMap(v2);
                      let p1: any = rest1[v1];
                      let p2: any = rest2[v2];

                      return p1.localeCompare(p2);
                    },
                    sortingOrder: ['asc', 'desc'],
                    hide: true,
                  });

                  return (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <div style={{ paddingLeft: 30, paddingTop: 5, paddingBottom: 5 }}>
                            <h5>{appLanguages.summary[lang]}</h5>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div
                            style={{
                              paddingLeft: 30,
                              paddingRight: 30,
                            }}
                          >
                            <MUIDataTable
                              title=''
                              columns={column_headers}
                              data={data}
                              options={{
                                ...muiDataTableCommonOptions(),
                                responsive: 'standard',
                                download: false,
                                filter: false,
                                search: false,
                                expandableRows: false,
                                expandableRowsOnClick: false,
                                pagination: false,
                                sort: false,
                                selectableRowsHeader: false,
                              }}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}>
                          <div style={{ paddingLeft: 30, paddingTop: 5, paddingBottom: 5 }}>
                            <h5>{appLanguages.details[lang]}</h5>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}>
                          <div style={{ height: 400, paddingLeft: 30, paddingRight: 30 }}>
                            <DataGrid
                              rows={rowData_CancelShipping_SummaryFunction}
                              columns={columns_datagrid}
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                },
                //onRowClick: (rowData, rowMeta) => console.log('onRowsSelect.', rowData, rowMeta),
                onChangePage: (currentPage) => {
                  this.setState(
                    (prevState) => ({ ...prevState, currentPageNo: currentPage }),
                    () => {},
                  );
                  this.handleOnChangePage(currentPage);
                  //console.log(this.state.currentPageNo);
                },
                //onChangeRowsPerPage: (numberOfRows) => this.handleOnChangeRowsPerPage(numberOfRows),
                search: false,
                page: this.state.currentPageNo,
                rowsPerPage: 5,
                rowsPerPageOptions: [5],
                //rowsPerPage: 20,
                pagination: true,
                count: !filter_toggle
                  ? final_rowData_default_one_year.length
                  : final_rowData.length,
              }}
            />
          </Paper>
        </MuiThemeProvider>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
  textSearched?: any;
  refreshData?: any;
  showAddSerialButton?: boolean;
  serialsList: any;
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
  openSnackbarMessage: (type: string, message: string) => void;
  setEditSerial: (serialObj: ISerial) => void;
  openSerialEditDialog: (serialObj: ISerial) => void;
  delSerial: (serialObj: ISerial) => void;
  confirm?: () => void;
  registerSerialsCancelShipping: (serialObj: any, successMessage?: any) => void;
  setSerialCustomToolbarSearchKeyword: (keyword: string) => void;
  getSerials: (query: IQuery) => void;
  getSerialSlips: (query: IQuery) => void;
  toggleSerialCustomToolbarSearchBox: (value: boolean) => void;
  getSerialBrands: (query: IQuery) => void;
}

interface State {
  dialogopenflag: boolean;
  cancelShipFlag: boolean;
  enableFilter: boolean;
  filterTimeout: any;
  initialized: boolean;
  columns: GridColDef[];
  columns_summary_function: MUIDataTableColumn[];
  //serialsList: any;
  serialsTotalCounts: 0;
  filterBrewShipAt: any;
  filterSalesOrder: any;
  filterBrandId: any;
  filterDestinationId: any;
  filterDistDestinationId: any;
  searchTimeout: any;
  refreshData: any;
  count: any;
  rowinfo: any;
  selectedrows: any;
  checked: boolean;
  currentPageNo: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setSerialsQuery,
  openSnackbarMessage,
  setEditSerial,
  openSerialEditDialog,
  delSerial,
  getSerialBrands,
  registerSerialsCancelShipping,
  toggleSerialCustomToolbarSearchBox,
  getSerialSlips,
  getSerials,
  setSerialCustomToolbarSearchKeyword,
};

const myStyles = (theme: Theme): StyleRules => ({
  root: { width: '100%' },
  button: { marginTop: theme.spacing(1), marginRight: theme.spacing(1) },
  actionsContainer: { marginBottom: theme.spacing(2) },
  resetContainer: { padding: theme.spacing(3) },
  paper: { padding: theme.spacing(2) },
});

export const SummaryFunction = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SummaryFunctionClass);
