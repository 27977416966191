/*
 * appsActions
 */
export const INITIALIZE_APPS = 'INITIALIZE_APPS';
export const OPEN_SNACKBAR_MESSAGE = 'OPEN_SNACKBAR_MESSAGE';
export const CLOSE_SNACKBAR_MESSAGE = 'CLOSE_SNACKBAR_MESSAGE';

export const SET_TAB = 'SET_TAB';

export const SET_REGISTRATION_DATE = 'SET_REGISTRATION_DATE';

export const START_SETTING = 'START_SETTING';
export const DONE_SETTING = 'DONE_SETTING';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';

export const OPEN_PROFILE_DIALOG = 'OPEN_PROFILE_DIALOG';
export const CLOSE_PROFILE_DIALOG = 'CLOSE_PROFILE_DIALOG';

export const OPEN_QR_SCAN_DIALOG = 'OPEN_QR_SCAN_DIALOG';
export const CLOSE_QR_SCAN_DIALOG = 'CLOSE_QR_SCAN_DIALOG';

export const OPEN_LOGOUT_DIALOG = 'OPEN_LOGOUT_DIALOG';
export const CLOSE_LOGOUT_DIALOG = 'CLOSE_LOGOUT_DIALOG';

export const OPEN_CANCEL_SHIPMENTS_DIALOG = 'OPEN_CANCEL_SHIPMENS_DIALOG';
export const CLOSE_CANCEL_SHIPMENTS_DIALOG = 'CLOSE_CANCEL_SHIPMENTS_DIALOG';

export const SET_BREWERY_REG_STATE = 'SET_BREWERY_REG_STATE';
export const SET_DISTRIBUTOR_SHIP_STATE = 'SET_DISTRIBUTOR_SHIP_STATE';
export const SET_RESTAURANT_REG_STATE = 'SET_RESTAURANT_REG_STATE';

export const ADD_SCANNED_SERIAL_CODE = 'ADD_SCANNED_SERIAL_CODE';
export const ADD_SERIAL_CODE_RANGE = 'ADD_SERIAL_CODE_RANGE';
export const DEL_SCANNED_SERIAL_CODE = 'DEL_SCANNED_SERIAL_CODE';
export const CLEAR_SCANNED_SERIAL_CODES = 'CLEAR_SCANNED_SERIAL_CODES';
export const UPDATE_SERIAL_RANGE_PAGINATON = 'UPDATE_SERIAL_RANGE_PAGINATON';
export const SET_SERIAL_RANGE_FROM = 'SET_SERIAL_RANGE_FROM';
export const SET_SERIAL_RANGE_TO = 'SET_SERIAL_RANGE_TO';
export const CLEAR_SERIAL_RANGE = 'CLEAR_SERIAL_RANGE';

export const SET_EDIT_SERIAL = 'SET_EDIT_SERIAL';
export const OPEN_SERIAL_EDIT_DIALOG = 'OPEN_SERIAL_EDIT_DIALOG';
export const CLOSE_SERIAL_EDIT_DIALOG = 'CLOSE_SERIAL_EDIT_DIALOG';

/*
 * authActions
 */
export const INIT_AUTH_REQUEST = 'INIT_AUTH_REQUEST';
export const INIT_AUTH = 'INIT_AUTH';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL';
export const LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT = 'LOGOUT';

/*
 * serversActions
 */
export const INITIALIZE_SERVERS = 'INITIALIZE_SERVERS';
export const SERVER_REQUEST = 'SERVER_REQUEST';
export const SERVER_GET_REQUEST = 'SERVER_GET_REQUEST';
export const SERVER_REQUEST_DONE = 'SERVER_REQUEST_DONE';
// - locations
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const GET_MASTER_LOCATIONS = 'GET_MASTER_LOCATIONS';
export const SET_LOCATIONS_QUERY = 'SET_LOCATIONS_QUERY';
export const SET_MASTER_LOCATIONS_QUERY = 'SET_MASTER_LOCATIONS_QUERY';
// - brands
export const GET_BRANDS = 'GET_BRANDS';
export const SET_BRANDS_QUERY = 'SET_BRANDS_QUERY';
// - users
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_LOGIN_HISTORY = 'REGISTER_LOGIN_HISTORY';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const SET_EDIT_USER = 'SET_EDIT_USER';
export const OPEN_USER_EDIT_DIALOG = 'OPEN_USER_EDIT_DIALOG';
export const CLOSE_USER_EDIT_DIALOG = 'CLOSE_USER_EDIT_DIALOG';
export const GET_USERS = 'GET_USERS';

// - serials
export const REGISTER_SERIAL = 'REGISTER_SERIAL';
export const REGISTER_SERIALS = 'REGISTER_SERIALS';
export const REGISTER_SCAN_GPS_CORDS = 'REGISTER_SCAN_GPS_CORDS'; //temporaly
export const STORE_TMP_GPS_CORDS = 'STORE_TMP_GPS_CORDS'; //temporaly
export const ADD_SERIAL_CODES = 'ADD_SERIAL_CODES';
export const GET_SERIALS = 'GET_SERIALS';
export const CLEAR_SERIALS = 'CLEAR_SERIALS';
export const GET_STOCKS = 'GET_STOCKS';
export const GET_SCANNED_SERIALS = 'GET_SCANNED_SERIALS';
export const DELETE_SERIAL = 'DELETE_SERIAL';
export const SET_SERIALS_QUERY = 'SET_SERIALS_QUERY';
export const SET_STOCKS_QUERY = 'SET_STOCKS_QUERY';
export const GET_SERIAL_BRANDS = 'GET_SERIAL_BRANDS';
export const GET_SERIAL_SLIPS = 'GET_SERIAL_SLIPS';
export const SET_SERIAL_CUSTOM_TOOLBAR_SEARCH_KEYWORD = 'SET_SERIAL_CUSTOM_TOOLBAR_SEARCH_KEYWORD';
export const TOOGLE_SERIAL_CUSTOM_TOOLBAR_SEARCHBOX = 'TOOGLE_SERIAL_CUSTOM_TOOLBAR_SEARCHBOX';
