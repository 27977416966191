import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getAuthUser } from '../redux/actions/serversActions';
import Header from './header/Header';
import { Content } from './main/Content';
import NotFound from './main/NotFound';
import Progress from './main/Progress';
import { setCurrentLanguage } from '../redux/actions/appsActions';

class Main extends React.PureComponent {
  componentDidMount() {
    const { getAuthUser } = this.props;
    getAuthUser();
  }

  setSessionLanguage() {
    const { setCurrentLanguage, apps, servers } = this.props;
    if (servers?.user) {
      if (servers.user?.language && servers.user?.language != apps.currentLanguage) {
        setCurrentLanguage(servers.user.language);
      }
    }
  }

  render() {
    const { apps, servers } = this.props;
    const { nowSetting } = apps;
    const { user } = servers;
    if (servers?.user) this.setSessionLanguage();

    const index = new Date().toLocaleDateString().replace(/\//g, '_');

    return user && !nowSetting ? (
      <>
        <CssBaseline />
        <BrowserRouter>
          <Header />
          <Switch>
            <Route exact path='/' render={() => <Redirect to={`/${index}`} />} />
            <Route exact path='/*' component={Content} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </>
    ) : (
      <Progress />
    );
  }
}

Main.propTypes = {
  apps: PropTypes.objectOf(PropTypes.any).isRequired,
  servers: PropTypes.objectOf(PropTypes.any).isRequired,
  getAuthUser: PropTypes.func.isRequired,
  setCurrentLanguage: PropTypes.func.isRequired,
};

const myStyles = () => ({});

const mapStateToProps = (state) => ({
  apps: state.apps,
  servers: state.servers,
});
const mapDispatchToProps = {
  getAuthUser,
  setCurrentLanguage,
};

export default compose(withStyles(myStyles), connect(mapStateToProps, mapDispatchToProps))(Main);
