import { IQuery } from '@models/query';

export const LIMIT_MASTER_MAX = 1000000;
export const LIMIT_PAGINATION_MAX = 200;

export const DefaultQuery: IQuery = {
  offset: 0,
  limit: LIMIT_PAGINATION_MAX,
  searchText: '',
  order: [['updatedAt', 'DESC']],
  where: {},
  totalCounts: 0,
};

export const DefaultMasterQuery: IQuery = {
  offset: 0,
  limit: LIMIT_MASTER_MAX,
  searchText: '',
  order: [['updatedAt', 'DESC']],
  where: {},
  totalCounts: 0,
};
