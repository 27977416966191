import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { closeSnackbarMessage } from '../../redux/actions/appsActions';
import MySnackbarContentWrapper from './MySnackbarContentWrapper';

class SnackbarMessage extends React.PureComponent {
  render() {
    const { apps, closeSnackbarMessage } = this.props;
    const autoHideDuration = 6000;
    /* success / warning / error / info */
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={apps.isOpenSnackbarMessage}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackbarMessage}
      >
        <MySnackbarContentWrapper
          className=''
          onClose={closeSnackbarMessage}
          variant={apps.snackbarMessageFormat}
          message={apps.snackbarMessage}
        />
      </Snackbar>
    );
  }
}

SnackbarMessage.propTypes = {
  apps: PropTypes.objectOf(PropTypes.any).isRequired,
  closeSnackbarMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  apps: state.apps,
});

const mapDispatchToProps = {
  closeSnackbarMessage,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SnackbarMessage);
