import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/firestore';
// eslint-disable-next-line import/no-unresolved
import userEnv from 'userEnv';

export const firebaseApp = firebase.initializeApp(userEnv.firebaseConfig);
export const functions = firebaseApp.functions(userEnv.region);
if (userEnv.env === 'local') functions.useFunctionsEmulator(userEnv.apiUrl);
export const storage = firebaseApp.storage();
export const firestore = firebaseApp.firestore();
export default firebase;
