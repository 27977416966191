import { Coords } from 'google-map-react';
import { convertGoogleToBaiduCoordinates } from './conversion';

export function getDistanceBaidu(origin: BaiduCoord, destination: BaiduCoord) {
  // Load raw BMap library
  const BMap = require('BMap');
  const map = new BMap.Map('address');

  let originCoord: Coords = getConvertedCoord(origin);
  let destCoord: Coords = getConvertedCoord(destination);

  const originPoint = new BMap.Point(originCoord.lng, originCoord.lat);
  const destPoint = new BMap.Point(destCoord.lng, destCoord.lat);

  return map.getDistance(originPoint, destPoint);
}

function getConvertedCoord(param: BaiduCoord): Coords {
  if (param.type === 'google') {
    return convertGoogleToBaiduCoordinates(param.lat, param.lng);
  }
  return { lat: param.lat, lng: param.lng };
}

interface BaiduCoord extends Coords {
  type: 'google' | 'baidu';
}
