import { store } from '@/redux/reducers';
import { Grid, LinearProgress } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IQuery } from '@models/query';
import { ISerial } from '@models/serial';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import appLanguages from '@utils/app-languages';
import { getFormattedDateString, nowrapHeader, withConfirm } from '@utils/common';
import { DefaultMasterQuery, LIMIT_PAGINATION_MAX } from '@utils/default-query';
import { formatString } from '@utils/format-string';
import {
  appMuiTheme,
  disableDisplayOpts,
  disableSortOpts,
  enableEmptyOpts,
  enableSortOnlyOpts,
  getDeleteActionsRender,
  getEditActionsRender,
  getSelectCustomBodyRender,
  getWarningDeleteActionsRender,
  getWarningEditActionsRender,
  muiDataTableCommonOptions,
  rowDataToObj,
} from '@utils/mui-datatables-ops';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';
import userEnv from 'userEnv';

import { IStateApps } from 'models/state-app';
import { compose } from 'redux';

import WhiteLinearProgress from '../../../elements/WhiteLinearProgress';
import {
  openSerialEditDialog,
  openSnackbarMessage,
  setEditSerial,
} from '../../../redux/actions/appsActions';
import {
  delSerial,
  getLocations,
  getSerialBrands,
  getSerials,
  setSerialsQuery,
} from '../../../redux/actions/serversActions';
import { SerialEditDialog } from './SerialEditDialog';
import { SerialsCustomToolbar } from './SerialsCustomToolbar';

class SerialsClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    viewType: 'stock',
    viewReference: 'brew',
  };

  constructor(props) {
    super(props);
    const { apps, viewType, viewReference } = this.props;
    const lang = apps.currentLanguage;
    const columns = [];

    /**
      BREW 在庫: シリアル、銘柄
      BREW 出荷: シリアル、銘柄、伝票番号、出荷日(brewShipAt)、出荷先（ディストリビュータ）、出荷先 (レストラン)
      DIST 在庫: シリアル、銘柄、酒蔵、酒蔵 出荷日(brewShipAt)、入荷受付日
      DIST 出荷: シリアル、銘柄、伝票番号、出荷日(distShipAt)、出荷先 (レストラン)
     */

    columns.push({
      name: 'id',
      options: { ...disableDisplayOpts },
    });

    // シリアル
    columns.push({
      name: 'code',
      label: nowrapHeader(appLanguages.serialCode[lang]),
      options: { ...disableSortOpts },
    });

    // 銘柄
    columns.push({
      name: 'brandId',
      label: nowrapHeader(appLanguages.brand[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => getSelectCustomBodyRender(this.getBrandOptionMap(), v),
      },
    });

    columns.push({
      name: 'distShipRestLocId', // 20thOct-sreenath-using distShipRestLocId field to populate restaurant name
      label: nowrapHeader(appLanguages.deliveringDestination[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => getSelectCustomBodyRender(this.getBrewShipRestLocOptionMap(v), v),
        display: viewType === 'shipped',
      },
    });

    if (userEnv.enableSerialEdit && viewReference !== 'distributor') {
      columns.push({
        name: '',
        options: {
          ...enableEmptyOpts,
          customBodyRender: (_, mt) => this.getActionsRender(mt),
        },
      });
    }
    this.state = {
      initialized: false,
      columns,
    };
  }

  async componentDidMount() {
    const {
      servers,
      getSerials,
      setSerialsQuery,
      getSerialBrands,
      getLocations,
      viewType,
      viewReference,
    } = this.props;
    const { user } = servers;
    const { location } = user;
    // Sreenath27thOct - Unordered serials list
    // fetch serials in ascending order with respect to viewReference and view type
    let orderBy = [
      ['updatedAt', 'DESC'],
      ['code', 'ASC'],
    ];

    const query = {
      ...servers.serialsQuery,
      order: orderBy,
      searchText: null,
      where: {
        ...(viewReference === 'brew' && {
          brewShipAt: viewType === 'stock' ? null : { $ne: null },
          brewLocId: location.id,
        }),
        ...(viewReference === 'distributor' && {
          distShipAt: viewType === 'stock' ? null : { $ne: null },
          recvDistLocId: location.id,
        }),
      },
      offset: 0,
      limit: LIMIT_PAGINATION_MAX,
    };
    setSerialsQuery(query);
    // Sreenath26thOct - Brand Info
    // for brewery only show the registered brands in the dropdown list.
    let whereClause = {};
    if (viewReference === 'brew') {
      whereClause['locationId'] = location.id;
    }
    await getSerialBrands({ ...DefaultMasterQuery, where: whereClause });
    await getSerials(query);
    await getLocations({ ...DefaultMasterQuery, where: { hidden: false } });
    await this.setState({ initialized: true });
  }

  getSlipText(text) {
    return text ? text : 'N/A';
  }

  getBrandOptionMap() {
    const { servers } = this.props;
    const optionMap = {};
    servers.serialBrands.map((o) => {
      optionMap[o.id] = o.name;
      return o.id;
    });
    return optionMap;
  }

  getShipAtText(dateString, lang) {
    let shipAtText = appLanguages.unshipped[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      shipAtText = getFormattedDateString(date);
    } else {
      shipAtText = 'N/A';
    }
    return shipAtText;
  }

  getBrewShipRestLocOptionMap(v) {
    const { servers } = this.props;
    const optionMap = {};
    servers?.locations?.forEach((o) => {
      optionMap[o.id] = o.name;
    });
    if (!(v in optionMap)) {
      optionMap[v] = 'N/A';
    }
    return optionMap;
  }

  getEditActionsRender(tableMeta) {
    const {
      servers,
      openSnackbarMessage,
      apps: { currentLanguage },
    } = this.props;
    const obj = this.rowDataToObj(tableMeta.rowData);
    const isRequesting = servers ? servers.isRequesting : true;
    const onClickEdit = (o) => this.handleOnClickEdit(o);
    const { columnIndex, rowData } = tableMeta;
    const invalid = rowData[columnIndex - 1]; // brewShipAt
    return (
      <>
        {invalid
          ? getWarningEditActionsRender(() =>
              openSnackbarMessage(
                'error',
                appLanguages.cannotUpdateDueToBreweryProcedures[currentLanguage],
              ),
            )
          : getEditActionsRender(obj, isRequesting, onClickEdit)}
      </>
    );
  }

  getDeleteActionsRender(tableMeta) {
    const {
      servers,
      confirm,
      openSnackbarMessage,
      apps: { currentLanguage },
    } = this.props;
    const obj = this.rowDataToObj(tableMeta.rowData);
    const isRequesting = servers ? servers.isRequesting : true;
    const lang = store.getState().apps.currentLanguage;
    const delTitle = appLanguages.serialDelete[lang];
    const delDesc = formatString(appLanguages.confirmSerialDelete[lang], { field: obj['code'] });
    const onClickDelete = (o) => this.handleOnClickDelete(o);
    const { columnIndex, rowData } = tableMeta;
    const invalid = rowData[columnIndex - 1]; // brewShipAt
    return (
      <>
        {invalid
          ? getWarningDeleteActionsRender(() =>
              openSnackbarMessage(
                'error',
                appLanguages.cannotUpdateDueToBreweryProcedures[currentLanguage],
              ),
            )
          : getDeleteActionsRender(obj, delTitle, delDesc, isRequesting, onClickDelete, confirm)}
      </>
    );
  }

  getActionsRender(tableMeta) {
    return (
      <>
        <Grid container wrap='nowrap'>
          <Grid item>{this.getEditActionsRender(tableMeta)}</Grid>
          <Grid item>{this.getDeleteActionsRender(tableMeta)}</Grid>
        </Grid>
      </>
    );
  }

  rowDataToObj(rowData) {
    const { columns } = this.state;
    return rowDataToObj(columns, rowData);
  }

  updateQuery(newQuery) {
    const { servers, setSerialsQuery, getSerials } = this.props;
    const query = { ...servers.serialsQuery, ...newQuery };
    setSerialsQuery(query);
    getSerials(query);
  }

  handleOnClickEdit(obj) {
    const { setEditSerial, openSerialEditDialog } = this.props;
    setEditSerial(obj);
    openSerialEditDialog(obj);
  }

  handleOnClickDelete(obj) {
    const { delSerial } = this.props;
    delSerial(obj);
  }

  handleOnChangePage(currentPage) {
    const { servers } = this.props;
    const { limit } = servers.serialsQuery;
    this.updateQuery({ offset: currentPage * limit });
  }

  handleOnChangeRowsPerPage(numberOfRows) {
    this.updateQuery({ offset: 0, limit: numberOfRows });
  }

  handleOnColumnSortChange(changedColumn, direction) {
    const { columns } = this.state;
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({
      order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']],
    });
  }

  render() {
    const { apps, servers, viewType } = this.props;
    const { initialized, columns } = this.state;
    const { offset, limit } = servers.serialsQuery;
    const currentPage = offset / limit;
    const numberOfRows = limit;

    /* 参考: https://github.com/gregnb/mui-datatables */
    const isRequesting = servers.isRequesting || servers.isGetRequesting || !initialized;
    return (
      <>
        {isRequesting ? <LinearProgress /> : <WhiteLinearProgress />}
        <MuiThemeProvider theme={appMuiTheme}>
          <MUIDataTable
            title=''
            data={servers.serials}
            columns={columns}
            options={{
              ...muiDataTableCommonOptions(),
              download: false,
              expandableRowsOnClick: false,
              onRowClick: (rowData, rowMeta) => console.log('onRowsSelect.', rowData, rowMeta),
              search: false,
              page: currentPage,
              rowsPerPage: numberOfRows,
              count: servers.serialsTotalCounts,
              customToolbar: () => (
                <SerialsCustomToolbar showAddSerialButton={viewType === 'stock'} />
              ),
              onChangePage: (currentPage) => this.handleOnChangePage(currentPage),
              onChangeRowsPerPage: (numberOfRows) => this.handleOnChangeRowsPerPage(numberOfRows),
              onColumnSortChange: (changedColumn, direction) =>
                this.handleOnColumnSortChange(changedColumn, direction),
            }}
          />
        </MuiThemeProvider>
        {apps.isOpenSerialEditDialog ? <SerialEditDialog /> : ''}
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  viewType?: 'stock' | 'shipped';
  viewReference?: 'brew' | 'distributor';
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
  openSerialEditDialog: (serialObj: ISerial) => void;
  getSerials: (query: IQuery) => void;
  setEditSerial: (serialObj: ISerial) => void;
  delSerial: (serialObj: ISerial) => void;
  getSerialBrands: (query: IQuery) => void;
  getLocations: (query: IQuery) => void;
  openSnackbarMessage: (type: string, message: string) => void;
  confirm?: () => void;
}

interface State {
  initialized: boolean;
  columns: MUIDataTableColumn[];
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setSerialsQuery,
  openSerialEditDialog,
  getSerials,
  setEditSerial,
  delSerial,
  getSerialBrands,
  getLocations,
  openSnackbarMessage,
};

export const Serials = compose(connect(mapStateToProps, mapDispatchToProps))(
  withConfirm(SerialsClass),
);
