import {
  AppBar,
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  StyleRules,
  Tab,
  Tabs,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Edit as EditIcon, LibraryAdd as LibraryAddIcon } from '@material-ui/icons';
import { ECategoryType } from '@models/category-type';
import { ISerial } from '@models/serial';
import { IStateApps } from '@models/state-app';
import { IStateServers } from '@models/state-servers';
import appLanguages from '@utils/app-languages';
import {
  extractSerialFromUrl,
  getAutocomplete,
  getSerialCodeErrorMessage,
  getTextFieldForSmall,
  SERIAL_DIGIT,
  SERIAL_LIMIT_COUNT,
} from '@utils/common';
import { DefaultQuery } from '@utils/default-query';
import { functions } from '@utils/firebase';
import { formatString } from '@utils/format-string';
import { isOk } from '@utils/is-ok';
import { padZeroes } from '@utils/pad-zeroes';
import React from 'react';
import QrReader from 'react-qr-reader';
import { connect } from 'react-redux';

import { compose } from 'redux';

import TabPanel, { a11yProps } from '../../../elements/TabPanel';
import {
  closeSerialEditDialog,
  openSnackbarMessage,
  setEditSerial,
} from '../../../redux/actions/appsActions';
import { addSerialCodes, registerSerial } from '../../../redux/actions/serversActions';

class SerialEditDialogClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      useCamera: false,
      scanTimeout: undefined,
      fromSerials: {
        1: '',
      },
      toSerials: {
        1: '',
      },
      brandIds: {
        1: 0,
      },
      inputtedSerials: [],
      resultSerials: [],
      scanIntervalTime: 1,
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  getConfig() {
    const {
      servers,
      openSnackbarMessage,
      apps: { currentLanguage },
    } = this.props;
    const { user } = servers;
    const { category } = user.location;
    const request = functions.httpsCallable('getScanIntervalTime');
    request()
      .then((result) => {
        const { data } = result;
        const { brew, rest } = data;
        if (category === ECategoryType.BREWERY)
          this.setState({ scanIntervalTime: parseFloat(brew) || 1 });
        if (category === ECategoryType.RESTAURANT)
          this.setState({ scanIntervalTime: parseFloat(rest) || 1 });
      })
      .catch((e) =>
        openSnackbarMessage(
          'error',
          `${appLanguages.registrationError[currentLanguage]} (${e.message})`,
        ),
      );
  }

  updateSetEditSerial(updateObj) {
    const { apps, setEditSerial } = this.props;
    setEditSerial({ ...apps.editSerial, ...updateObj });
  }

  handleScan(data) {
    if (!data) return;
    const { scanIntervalTime } = this.state;

    const intervalTimeMSec = scanIntervalTime * 1000;
    const _finish = () =>
      setTimeout(() => this.setState({ scanTimeout: undefined }), intervalTimeMSec);

    const timeout = setTimeout(() => {
      const url = data.trim();
      const serialCode = extractSerialFromUrl(url);
      if (serialCode) this.handleChangeCode(serialCode);
      _finish();
    }, 100);
    this.setState({ scanTimeout: timeout });
  }

  handleChangeCode(v) {
    this.updateSetEditSerial({ code: v });
  }

  handleChangeBrand(v) {
    this.updateSetEditSerial({ brandId: v });
  }

  handleChangeFromRegSerials(index, v) {
    const { fromSerials } = this.state;
    this.setState({ fromSerials: { ...fromSerials, [index]: v } });
    this.checkFromToRegSerials();
  }

  handleChangeToRegSerials(index, v) {
    const { toSerials } = this.state;
    this.setState({ toSerials: { ...toSerials, [index]: v } });
    this.checkFromToRegSerials();
  }

  handleChangeBrandList(index, e, v) {
    const { brandIds } = this.state;
    this.setState({
      brandIds: { ...brandIds, [index]: (v && v.id) || 0 },
    });
  }

  protected checkFromToRegSerials() {
    const { fromSerials, toSerials } = this.state;
    const allSerialCodes = [];
    for (const index of [1]) {
      const fromSerial = fromSerials[index];
      const toSerial = toSerials[index];
      if (parseInt(fromSerial) && parseInt(toSerial)) {
        const limit = parseInt(fromSerial) + SERIAL_LIMIT_COUNT;
        for (let code = parseInt(fromSerial); code <= parseInt(toSerial); code += 1) {
          if (limit < code) break;
          allSerialCodes.push({ code, index });
        }
      }
    }
    this.setState({ inputtedSerials: allSerialCodes });
  }

  protected async getSerialsFromInputSerialRange() {
    const { inputtedSerials } = this.state;
    if (!isOk(inputtedSerials)) return;

    const request = functions.httpsCallable('getSerials');
    const where = { $or: [] };
    for (const serial of inputtedSerials) {
      where.$or.push({ code: serial.code });
    }
    const query = { ...DefaultQuery, where };
    try {
      const response = await request(query);
      response.data.objects = response.data.objects.filter(function (item) {
        console.log(item);
        return item.status !== 6;
      });
      this.setState({ resultSerials: response.data.objects });
    } catch (error) {}
  }

  async handleClickRegister() {
    const { tabValue } = this.state;
    const { cbEdited } = this.props;
    switch (tabValue) {
      case 0: {
        const { apps, registerSerial } = this.props;
        await registerSerial(apps.editSerial);
        if (cbEdited) {
          cbEdited();
        }
        break;
      }
      case 1: {
        const { addSerialCodes } = this.props;
        const { fromSerials, toSerials, brandIds } = this.state;
        const multiIndexes = [1];
        for (const index of multiIndexes) {
          const fromSerialCode = parseInt(fromSerials[index]);
          const toSerialCode = parseInt(toSerials[index]);
          const brandId = brandIds[index];
          if (!fromSerialCode || !toSerialCode || !brandId) continue;
          if (toSerialCode > toSerials) continue;
          addSerialCodes({
            fromSerialCode: padZeroes(fromSerialCode, SERIAL_DIGIT),
            toSerialCode: padZeroes(toSerialCode, SERIAL_DIGIT),
            brandId,
          });
        }
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { classes, apps, servers } = this.props;
    const { tabValue, useCamera, scanTimeout, fromSerials, toSerials, brandIds } = this.state;
    const { closeSerialEditDialog } = this.props;
    const obj = apps.editSerial;
    const id = obj ? obj.id : undefined;
    {
      console.log(id);
    }
    const multiIndexes = [1];

    const lang = apps.currentLanguage;

    // Serial Brands
    const defaultSerialBrand = 0;
    let serialBrandValue = null;
    const serialBrandValues = {
      1: null,
    };
    const serialBrands = servers.serialBrands.map((o) => {
      const v = { id: o.id, name: o.name };
      if (obj && o.id === obj.brandId) serialBrandValue = { ...v };
      for (const index of multiIndexes) {
        if (o.id === brandIds[index]) serialBrandValues[index] = { ...v };
      }
      return v;
    });
    const isRequesting = servers.isRequesting || servers.isGetRequesting;

    const serialCode = obj && obj.code ? obj.code : '';
    console.log(serialCode);
    let errorMessage = getSerialCodeErrorMessage(serialCode);

    const singleRegView =
      tabValue === 0 ? (
        <>
          {!id ? (
            <>
              <Grid
                container
                justify='flex-start'
                alignContent='flex-start'
                alignItems='flex-start'
              >
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        color='primary'
                        checked={useCamera}
                        onChange={(e) => this.setState({ useCamera: e.target.checked })}
                      />
                    }
                    label={
                      <Typography variant='subtitle2' color='textSecondary'>
                        {appLanguages.useQRCodeScanner[lang]}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1} />
                </Grid>
              </Grid>
              {useCamera ? (
                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Paper elevation={3}>
                      {scanTimeout ? (
                        <Card>
                          <CardMedia
                            className={classes.cardMedia}
                            image='img/black.jpeg'
                            title='...'
                          />
                        </Card>
                      ) : (
                        <QrReader
                          delay={100}
                          resolution={1000}
                          onScan={(data) => this.handleScan(data)}
                          onError={(err) => window.console.warn('Failed scan QR.', err)}
                        />
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </>
          ) : (
            <Box />
          )}
          {getTextFieldForSmall(
            <Typography variant='subtitle2'>{`${appLanguages.serialCode[lang]}`}</Typography>,
            '',
            serialCode || '',
            (e) => this.handleChangeCode(e.target.value),
            Boolean(errorMessage) || false,
            errorMessage || '',
          )}
          {getAutocomplete(
            <Typography variant='subtitle2'>{`${appLanguages.brand[lang]}`}</Typography>,
            '',
            false,
            serialBrandValue || null,
            serialBrands || [],
            (e, v) => this.handleChangeBrand(v ? v.id : defaultSerialBrand),
          )}
        </>
      ) : (
        <Box />
      );

    const multiRegView =
      tabValue === 1 ? (
        multiIndexes.map((index) => {
          const fromSerial = fromSerials[index];
          const toSerial = toSerials[index];
          const fromErrorMessage = getSerialCodeErrorMessage(fromSerial);
          let toErrorMessage = getSerialCodeErrorMessage(toSerial);
          if (!fromErrorMessage && !toErrorMessage) {
            const diff = parseInt(toSerial) - parseInt(fromSerial);
            if (diff < 0) toErrorMessage = appLanguages.startPointLowerInvalid[lang];
            if (diff >= SERIAL_LIMIT_COUNT)
              toErrorMessage = formatString(appLanguages.maxLimitRegistration[lang], {
                limit: String(SERIAL_LIMIT_COUNT),
              });
          }
          errorMessage = errorMessage || fromErrorMessage || toErrorMessage;
          return (
            <React.Fragment key={index.toString()}>
              <Paper elevation={3} className={classes.paper}>
                <Grid container alignItems='flex-end'>
                  <Grid item className={classes.gridItem}>
                    {getTextFieldForSmall(
                      <Typography variant='subtitle2'>
                        {appLanguages.serialRangeStart[lang]}
                      </Typography>,
                      appLanguages.serialInputSampleStart[lang],
                      fromSerial || '',
                      (e) => this.handleChangeFromRegSerials(index, e.target.value),
                      Boolean(fromErrorMessage) || false,
                      fromErrorMessage || '',
                    )}
                  </Grid>
                  <Grid item className={classes.gridItem}>
                    <Typography variant='caption' color='textSecondary'>
                      {appLanguages.from[lang]}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.gridItem}>
                    {getTextFieldForSmall(
                      <Typography variant='subtitle2'>
                        {appLanguages.serialRangeEnd[lang]}
                      </Typography>,
                      appLanguages.serialInputSampleEnd[lang],
                      toSerial || '',
                      (e) => this.handleChangeToRegSerials(index, e.target.value),
                      Boolean(toErrorMessage) || false,
                      toErrorMessage || '',
                    )}
                  </Grid>
                  <Grid item className={classes.gridItem}>
                    <Typography variant='caption' color='textSecondary'>
                      {appLanguages.to[lang]}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.gridItem}>
                    {getAutocomplete(
                      <Typography variant='subtitle2'>{`${appLanguages.brand[lang]}`}</Typography>,
                      '',
                      false,
                      serialBrandValues[index] || null,
                      serialBrands || [],
                      (e, v) => this.handleChangeBrandList(index, e, v),
                      false,
                      '',
                      'none',
                    )}
                  </Grid>
                </Grid>
              </Paper>
              <Box mt={1} />
            </React.Fragment>
          );
        })
      ) : (
        <Box />
      );
    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        open={Boolean(servers.user && apps.isOpenSerialEditDialog)}
        onClose={() => closeSerialEditDialog()}
      >
        {isRequesting ? <LinearProgress color='primary' /> : ''}

        <DialogTitle className={id ? classes.dialogTitleNone : classes.dialogTitle}>
          {id ? (
            <Grid container>
              <Grid item>
                <EditIcon fontSize='inherit' />
              </Grid>
              <Grid item>
                <Box ml={1} />
              </Grid>
              <Grid item>
                <Typography variant='subtitle1'>{appLanguages.editSerial2[lang]}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item>
                <LibraryAddIcon fontSize='inherit' />
              </Grid>
              <Grid item>
                <Box ml={1} />
              </Grid>
              <Grid item>
                <Typography variant='subtitle1'>{appLanguages.addNewData2[lang]}</Typography>
              </Grid>
            </Grid>
          )}
        </DialogTitle>
        <DialogContent className={id ? classes.dialogContentNone : classes.dialogContent}>
          {id ? (
            <>{singleRegView}</>
          ) : (
            <Paper elevation={3}>
              <AppBar position='static' color='primary'>
                <Tabs
                  value={tabValue}
                  variant='scrollable'
                  scrollButtons='auto'
                  onChange={(e, v) => this.setState({ tabValue: v })}
                >
                  <Tab
                    disabled={isRequesting}
                    label={appLanguages.individualRegistration[lang]}
                    {...a11yProps(0)}
                  />
                  <Tab
                    disabled={isRequesting}
                    label={appLanguages.bundleRegistration[lang]}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    {singleRegView}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container>
                  <Grid item>{multiRegView}</Grid>
                </Grid>
              </TabPanel>
            </Paper>
          )}
          <Box mb={3} />
        </DialogContent>
        <DialogActions>
          <Grid container justify='flex-end'>
            <Button
              variant='outlined'
              color='default'
              onClick={() => closeSerialEditDialog()}
              disabled={isRequesting}
            >
              {appLanguages.cancel[lang]}
            </Button>
            <Box ml={1} />
            <Button
              variant='outlined'
              color='default'
              onClick={async () => await this.handleClickRegister()}
              disabled={isRequesting || Boolean(errorMessage)}
            >
              {id ? appLanguages.update[lang] : appLanguages.register[lang]}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
  cbEdited?: any;
}

export interface IDispatchProps {
  setEditSerial: (serialObj: Partial<ISerial>) => void;
  registerSerial: (serialObj: ISerial) => void;
  closeSerialEditDialog: () => void;
  addSerialCodes: (params: any) => void;
  openSnackbarMessage: (type: string, message: string) => void;
}

interface State {
  tabValue: number;
  useCamera: boolean;
  scanTimeout: any;
  fromSerials: any;
  toSerials: any;
  brandIds: any;
  inputtedSerials: any[];
  resultSerials: any[];
  scanIntervalTime: number;
}

const mapStateToProps = (state) => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps = {
  setEditSerial,
  closeSerialEditDialog,
  registerSerial,
  addSerialCodes,
  openSnackbarMessage,
};

const myStyles = (theme: Theme): StyleRules => ({
  keyboardDatePicker: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  cardMedia: {
    height: 0,
    paddingTop: '100%',
  },
  paper: { padding: theme.spacing(1) },
  dialogTitle: { padding: theme.spacing(2) },
  dialogTitleNone: {},
  dialogContent: { padding: theme.spacing(1) },
  dialogContentNone: {},
  gridItem: {
    paddingTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
});

export const SerialEditDialog = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SerialEditDialogClass);
