import {
  INITIALIZE_APPS,
  OPEN_SNACKBAR_MESSAGE,
  CLOSE_SNACKBAR_MESSAGE,
  SET_TAB,
  START_SETTING,
  DONE_SETTING,
  SET_CURRENT_LANGUAGE,
  OPEN_PROFILE_DIALOG,
  CLOSE_PROFILE_DIALOG,
  OPEN_QR_SCAN_DIALOG,
  CLOSE_QR_SCAN_DIALOG,
  OPEN_LOGOUT_DIALOG,
  CLOSE_LOGOUT_DIALOG,
  ADD_SCANNED_SERIAL_CODE,
  DEL_SCANNED_SERIAL_CODE,
  CLEAR_SCANNED_SERIAL_CODES,
  SET_EDIT_SERIAL,
  OPEN_SERIAL_EDIT_DIALOG,
  CLOSE_SERIAL_EDIT_DIALOG,
  SET_BREWERY_REG_STATE,
  SET_DISTRIBUTOR_SHIP_STATE,
  SET_RESTAURANT_REG_STATE,
  // - users
  SET_EDIT_USER,
  OPEN_USER_EDIT_DIALOG,
  CLOSE_USER_EDIT_DIALOG,
  SET_SERIAL_RANGE_FROM,
  SET_SERIAL_RANGE_TO,
  CLEAR_SERIAL_RANGE,
  UPDATE_SERIAL_RANGE_PAGINATON,
  ADD_SERIAL_CODE_RANGE,
  SET_SERIAL_CUSTOM_TOOLBAR_SEARCH_KEYWORD,
  TOOGLE_SERIAL_CUSTOM_TOOLBAR_SEARCHBOX,
  SET_REGISTRATION_DATE,
} from '../actions';
import { LANGUAGE_DEFAULT, SERIAL_PAGE_LIMIT_COUNT } from '@utils/common';
import { IStateApps } from '@/models/state-app';
import { TSupportedLanguages } from '@models/supported-languages';
import { ECategoryType } from '@models/category-type';
import { DefaultSerialPagination } from '@utils/default-serial-pagination';
import { EQROperations } from '@models/qr-operations';

const storage = window.localStorage;

let tabValue = storage.getItem(SET_TAB) || 0;
tabValue = tabValue ? Number(tabValue) : 0;

const initializeState: IStateApps = {
  nowSetting: false,
  isOpenSnackbarMessage: false,
  snackbarMessageFormat: 'info',
  snackbarMessage: '',
  isOpenSideBar: false,
  isOpenProfileDialog: false,
  isOpenQRScanDialog: false,
  isOpenLogoutDialog: false,

  isOpenUserEditDialog: false,
  editSerial: null,
  isOpenSerialEditDialog: false,
  tabValue: 0,
  serialRegistrationDate: '',
  currentLanguage:
    (storage.getItem(SET_CURRENT_LANGUAGE) as TSupportedLanguages) || LANGUAGE_DEFAULT,
  scannedSerialCodes: [],
  editUser: null,
  serialRangeFrom: null,
  serialRangeTo: null,
  serialRangePagination: DefaultSerialPagination,
  breweryRegState: {
    activeStep: 0,
    locCategory: ECategoryType.ADMIN,
    restLocId: 0,
    slipNum: '',
    customerNumber: '',
    eventName: '',
    purpose: '',
  },
  distributorShipState: {
    activeStep: 0,
    restLocId: 0,
    slipNum: '',
    customerNumber: '',
    eventName: '',
    purpose: '',
  },
  restaurantRegState: {
    activeStep: 0,
    operation: EQROperations.UNSPECIFIED,
  },
  serialCustomToolbarState: {
    openSearchBox: false,
    searchKeyword: '',
  },
};

export default (state: IStateApps = initializeState, action): IStateApps => {
  let isOpenSnackbarMessage = false;
  let snackbarMessageFormat = 'info';
  let snackbarMessage = '';
  //console.log('Action: ' + JSON.stringify(action, null, 4));
  switch (action.type) {
    case INITIALIZE_APPS: {
      storage.clear();
      return {
        ...initializeState,
      };
    }
    case OPEN_SNACKBAR_MESSAGE: {
      isOpenSnackbarMessage = true;
      snackbarMessage = action.message;
      snackbarMessageFormat = action.format;
      return {
        ...state,
        isOpenSnackbarMessage,
        snackbarMessageFormat,
        snackbarMessage,
      };
    }
    case CLOSE_SNACKBAR_MESSAGE:
      return {
        ...state,
        isOpenSnackbarMessage,
        snackbarMessageFormat,
        snackbarMessage,
      };
    case SET_TAB: {
      storage.setItem(SET_TAB, String(action.value));
      return {
        ...state,
        tabValue: parseInt(action.value),
      };
    }
    case SET_REGISTRATION_DATE:
      return {
        ...state,
        serialRegistrationDate: action.date,
      };
    case START_SETTING:
      return {
        ...state,
        nowSetting: true,
      };
    case DONE_SETTING:
      return {
        ...state,
        nowSetting: false,
      };
    case SET_CURRENT_LANGUAGE: {
      const currentLanguage = action.lang;
      storage.setItem(SET_CURRENT_LANGUAGE, currentLanguage);
      return {
        ...state,
        currentLanguage,
      };
    }
    case OPEN_PROFILE_DIALOG:
      return {
        ...state,
        isOpenProfileDialog: true,
      };
    case CLOSE_PROFILE_DIALOG:
      return {
        ...state,
        isOpenProfileDialog: false,
      };
    case OPEN_QR_SCAN_DIALOG:
      return {
        ...state,
        isOpenQRScanDialog: true,
      };
    case CLOSE_QR_SCAN_DIALOG:
      return {
        ...state,
        isOpenQRScanDialog: false,
      };
    case OPEN_LOGOUT_DIALOG:
      return {
        ...state,
        isOpenLogoutDialog: true,
      };
    case SET_BREWERY_REG_STATE:
      return {
        ...state,
        breweryRegState: action.state,
      };
    case SET_DISTRIBUTOR_SHIP_STATE:
      return {
        ...state,
        distributorShipState: action.state,
      };
    case SET_RESTAURANT_REG_STATE:
      return {
        ...state,
        restaurantRegState: action.state,
      };
    case SET_SERIAL_CUSTOM_TOOLBAR_SEARCH_KEYWORD:
      return {
        ...state,
        serialCustomToolbarState: {
          openSearchBox: state.serialCustomToolbarState.openSearchBox,
          searchKeyword: action.keyword,
        },
      };
    case TOOGLE_SERIAL_CUSTOM_TOOLBAR_SEARCHBOX:
      return {
        ...state,
        serialCustomToolbarState: {
          openSearchBox: action.value,
          searchKeyword: state.serialCustomToolbarState.searchKeyword,
        },
      };
    case CLOSE_LOGOUT_DIALOG:
      return {
        ...state,
        isOpenLogoutDialog: false,
      };
    case ADD_SERIAL_CODE_RANGE: {
      return {
        ...state,
        scannedSerialCodes: action.serialCodes,
      };
    }
    case ADD_SCANNED_SERIAL_CODE: {
      let newScannedSerialCodes: string[] = state.scannedSerialCodes.filter(
        (a) => a !== action.serialCode,
      );
      // Sreenath12Nov21- Display the scanned serials in latest scanned first order.
      newScannedSerialCodes = [action.serialCode].concat(newScannedSerialCodes);
      return {
        ...state,
        scannedSerialCodes: newScannedSerialCodes,
        // It will reset to First Page
        serialRangePagination: {
          totalCount: newScannedSerialCodes.length,
          currentPage: 1,
          offset: 0,
          pageSerialCodes: newScannedSerialCodes.slice(0, SERIAL_PAGE_LIMIT_COUNT),
        },
      };
    }
    case DEL_SCANNED_SERIAL_CODE: {
      const newScannedSerialCodes = state.scannedSerialCodes.filter((a) => a !== action.serialCode);
      return {
        ...state,
        scannedSerialCodes: newScannedSerialCodes,
        // It will reset to First Page
        serialRangePagination: {
          totalCount: newScannedSerialCodes.length,
          currentPage: 1,
          offset: 0,
          pageSerialCodes: newScannedSerialCodes.slice(0, SERIAL_PAGE_LIMIT_COUNT),
        },
      };
    }
    case CLEAR_SCANNED_SERIAL_CODES: {
      return {
        ...state,
        scannedSerialCodes: [],
        // It will reset pagination
        serialRangePagination: DefaultSerialPagination,
      };
    }
    case UPDATE_SERIAL_RANGE_PAGINATON: {
      return {
        ...state,
        serialRangePagination: action.serialRangePagination,
      };
    }
    case SET_SERIAL_RANGE_FROM: {
      return {
        ...state,
        serialRangeFrom: action.range,
      };
    }
    case SET_SERIAL_RANGE_TO: {
      return {
        ...state,
        serialRangeTo: action.range,
      };
    }
    case CLEAR_SERIAL_RANGE: {
      return {
        ...state,
        serialRangeFrom: null,
        serialRangeTo: null,
      };
    }
    case SET_EDIT_SERIAL:
      return {
        ...state,
        editSerial: action.obj,
      };
    case OPEN_SERIAL_EDIT_DIALOG:
      return {
        ...state,
        editSerial: action.obj,
        isOpenSerialEditDialog: true,
      };
    case CLOSE_SERIAL_EDIT_DIALOG:
      return {
        ...state,
        editSerial: null,
        isOpenSerialEditDialog: false,
      };
    // users
    case SET_EDIT_USER:
      return {
        ...state,
        editUser: action.obj,
      };
    case OPEN_USER_EDIT_DIALOG:
      return {
        ...state,
        editUser: action.obj,
        isOpenUserEditDialog: true,
      };
    case CLOSE_USER_EDIT_DIALOG:
      return {
        ...state,
        editUser: null,
        isOpenUserEditDialog: false,
      };
    default:
      break;
  }
  return state;
};
