import { IQuery } from '@models/query';
import appLanguages from '@utils/app-languages';
import { LIMIT_MASTER_MAX } from '@utils/default-query';
import { functions } from '@utils/firebase';
import { isOk } from '@utils/is-ok';

import {
  ADD_SERIAL_CODES,
  CLEAR_LOCATIONS,
  CLEAR_SERIALS,
  DELETE_SERIAL,
  GET_AUTH_USER,
  GET_BRANDS,
  GET_LOCATIONS,
  GET_MASTER_LOCATIONS,
  GET_SCANNED_SERIALS,
  GET_SERIAL_BRANDS,
  GET_SERIAL_SLIPS,
  GET_SERIALS,
  GET_STOCKS,
  INITIALIZE_SERVERS,
  LOGOUT,
  REGISTER_SERIAL,
  REGISTER_SERIALS,
  REGISTER_USER,
  REGISTER_LOGIN_HISTORY,
  REGISTER_SCAN_GPS_CORDS, //temporaly
  STORE_TMP_GPS_CORDS, //temporaly
  SERVER_GET_REQUEST,
  SERVER_REQUEST,
  SERVER_REQUEST_DONE,
  SET_BRANDS_QUERY,
  SET_LOCATIONS_QUERY,
  SET_MASTER_LOCATIONS_QUERY,
  SET_SERIALS_QUERY,
  SET_STOCKS_QUERY,
} from '.';
import { store } from '../reducers';
import {
  closeSerialEditDialog,
  initializeApps,
  openSnackbarMessage,
  setEditSerial,
} from './appsActions';

export const initializeServers = () => ({ type: INITIALIZE_SERVERS });
export const serversUpdateRequest = () => ({ type: SERVER_REQUEST });
export const serversGetRequest = () => ({ type: SERVER_GET_REQUEST });
export const serversResponseDone = () => ({ type: SERVER_REQUEST_DONE });
const serversResponseSuccess = (type, result) => ({ type, result });
const serversResponseFailure = (type, error) => ({ type, error });
const lang = store.getState().apps.currentLanguage;

const logout = () => ({ type: LOGOUT });

const handleError = (type, error, dispatch) => {
  dispatch(serversResponseFailure(type, error));
  window.console.error(`[${error.code}] ${error.message}`);
  let message = `${error.message}`;
  //if (error.code === 'internal') message = appLanguages.serverError[lang];
  console.log(error.code);
  switch (error.code) {
    case 'internal':
      message = appLanguages.serverError[lang];
      break;
    case 'already-exists':
      message = appLanguages.alreadyExist[lang];
      break;
    case 'failed-precondition':
      message = appLanguages.tokenIsInvalid[lang];
      dispatch(logout());
      break;
    default:
      break;
  }
  dispatch(openSnackbarMessage('error', message));
};

/*
 * Locations
 */

export const getLocations = (query: IQuery) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getLocations');
  try {
    dispatch(clearLocations());
    const response = await request(query);
    // Sreenath 18NOV21- filtering inactive locations from the response
    let locations = response?.data?.objects || [];
    locations = locations.filter((x) => x.status !== 1);
    dispatch(
      serversResponseSuccess(GET_LOCATIONS, {
        data: {
          objects: locations,
          totalCounts: locations.length,
        },
      }),
    );
  } catch (error) {
    handleError(GET_LOCATIONS, error, dispatch);
  }
};

export const getMasterLocations = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getMasterLocations');
  try {
    dispatch(clearLocations());
    const response = await request(query);
    // Sreenath 18NOV21- filtering inactive locations from the response
    let locations = response?.data?.objects || [];
    locations = locations.filter((x) => x.status !== 1);
    dispatch(
      serversResponseSuccess(GET_MASTER_LOCATIONS, {
        data: {
          objects: locations,
          totalCounts: locations.length,
        },
      }),
    );
  } catch (response) {
    handleError(GET_MASTER_LOCATIONS, response, dispatch);
  }
};

export const setLocationsQuery = (query: IQuery) => ({ type: SET_LOCATIONS_QUERY, query });

export const setMasterLocationsQuery = (query: IQuery) => ({
  type: SET_MASTER_LOCATIONS_QUERY,
  query,
});

/*
 * Brands
 */

export const getBrands = (query: IQuery) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getBrands');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_BRANDS, response));
  } catch (error) {
    handleError(GET_BRANDS, error, dispatch);
  }
};

export const setBrandsQuery = (query: IQuery) => ({ type: SET_BRANDS_QUERY, query });

/*
 * Users
 */
export const getAuthUser = () => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getAuthUser');
  try {
    const response = await request({});
    dispatch(serversResponseSuccess(GET_AUTH_USER, response));
  } catch (error) {
    handleError(GET_AUTH_USER, error, dispatch);
    dispatch(initializeServers());
    dispatch(initializeApps());
    dispatch(logout());
  }
};

export const registerUser = (params) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerUser');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_USER, response));
    if (params.id) {
      dispatch(openSnackbarMessage('success', appLanguages.updatedUserMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', appLanguages.addedUserMessage[lang]));
    }
  } catch (error) {
    handleError(REGISTER_USER, error, dispatch);
  }
};

export const registerUserLang = (params) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('registerUser');

  try {
    await request(params);
  } catch (error) {}
};

export const getStocks = (query: IQuery) => async (dispatch) => {
  if (!isOk(query?.brewLocId) && !isOk(query?.distLocId) && !isOk(query?.restLocId)) return;

  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getStocks');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_STOCKS, response));
  } catch (error) {
    handleError(GET_STOCKS, error, dispatch);
  }
};

/*
 * Serials
 */

export const getSerials = (query: IQuery, cancelShippingFlag?, adminFlag?) => async (dispatch) => {
  dispatch(serversGetRequest());
  dispatch(serversResponseSuccess(CLEAR_SERIALS, []));
  const request = functions.httpsCallable('getSerials');
  //console.log(cancelShippingFlag);
  try {
    const response = await request({ query, cancelShippingFlag });

    if (!adminFlag) {
      response.data.objects = response.data.objects.filter(function (item) {
        //console.log(item);
        return item.status !== 6;
      });
    }
    dispatch(serversResponseSuccess(GET_SERIALS, response));
  } catch (error) {
    handleError(GET_SERIALS, error, dispatch);
  }
};

export const getScannedSerials = (query: IQuery) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getSerials');
  try {
    // Must be set to Max
    query.limit = LIMIT_MASTER_MAX;
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_SCANNED_SERIALS, response));
  } catch (error) {
    handleError(GET_SCANNED_SERIALS, error, dispatch);
  }
};

export const registerSerial = (params) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerSerial');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_SERIAL, response));
    if (params.id) {
      dispatch(openSnackbarMessage('success', appLanguages.updatedMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', appLanguages.addedMessage[lang]));
    }
    dispatch(setEditSerial({}));
    dispatch(closeSerialEditDialog());
  } catch (error) {
    handleError(REGISTER_SERIAL, error, dispatch);
  } finally {
    dispatch(getSerials(store.getState().servers.serialsQuery));
    dispatch(getStocks(store.getState().servers.stocksQuery));
  }
};

export const registerSerials = (params, successMessage?: string) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerSerials');

  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_SERIALS, response));
    if (params.id) {
      dispatch(openSnackbarMessage('success', successMessage || appLanguages.updatedMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', successMessage || appLanguages.addedMessage[lang]));
    }
    dispatch(setEditSerial({}));
    dispatch(closeSerialEditDialog());
  } catch (error) {
    handleError(REGISTER_SERIALS, error, dispatch);
  } finally {
    dispatch(getSerials(store.getState().servers.serialsQuery));
    dispatch(getStocks(store.getState().servers.stocksQuery));
  }
};

export const storeTmpGpsCords = (params) => async (dispatch) => {
  const response = { data: params };
  dispatch(serversResponseSuccess(STORE_TMP_GPS_CORDS, response));
};

export const registerScanGpsCords = (params) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('registerScanGpsCords');
  try {
    const response = await request(params);
    console.log(response);
    dispatch(serversResponseSuccess(REGISTER_SCAN_GPS_CORDS, response));
  } catch (error) {
    console.log(error);
  }
};
//This is copy of above function but finally block has been commented out to solve the issue for cancel SHipping -> Stock management tranisition
export const registerSerialsCancelShipping = (params, successMessage?: string) => async (
  dispatch,
) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerSerials');

  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_SERIALS, response));
    if (params.id) {
      dispatch(openSnackbarMessage('success', successMessage || appLanguages.updatedMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', successMessage || appLanguages.addedMessage[lang]));
    }
    dispatch(setEditSerial({}));
    dispatch(closeSerialEditDialog());
  } catch (error) {
    handleError(REGISTER_SERIALS, error, dispatch);
  } finally {
    //dispatch(getSerials(store.getState().servers.serialsQuery));
    //dispatch(getStocks(store.getState().servers.stocksQuery));
  }
};

export const addSerialCodes = (params) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('addSerialCodes');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(ADD_SERIAL_CODES, response));
    dispatch(openSnackbarMessage('success', appLanguages.addedMessage[lang]));
    dispatch(setEditSerial({}));
    dispatch(closeSerialEditDialog());
  } catch (error) {
    handleError(ADD_SERIAL_CODES, error, dispatch);
  } finally {
    dispatch(getSerials(store.getState().servers.serialsQuery));
    dispatch(getStocks(store.getState().servers.stocksQuery));
  }
};
export const addSerialCode = (params) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('addSerialCode');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(ADD_SERIAL_CODES, response));
    dispatch(openSnackbarMessage('success', appLanguages.addedMessage[lang]));
    dispatch(setEditSerial({}));
    dispatch(closeSerialEditDialog());
  } catch (error) {
    handleError(ADD_SERIAL_CODES, error, dispatch);
  } finally {
    dispatch(getSerials(store.getState().servers.serialsQuery));
    dispatch(getStocks(store.getState().servers.stocksQuery));
  }
};

export const delSerial = (params) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('delSerial');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(DELETE_SERIAL, response));
    dispatch(openSnackbarMessage('success', appLanguages.deletedMessage[lang]));
  } catch (error) {
    handleError(DELETE_SERIAL, error, dispatch);
  } finally {
    dispatch(getSerials(store.getState().servers.serialsQuery));
    dispatch(getStocks(store.getState().servers.stocksQuery));
  }
};

export const setSerialsQuery = (query: IQuery) => ({ type: SET_SERIALS_QUERY, query });
export const setStocksQuery = (query: IQuery) => ({ type: SET_STOCKS_QUERY, query });

export const getSerialBrands = (params) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getBrands');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(GET_SERIAL_BRANDS, response));
  } catch (error) {
    handleError(GET_SERIAL_BRANDS, error, dispatch);
  }
};

export const getSerialSlips = (query: IQuery) => async (dispatch) => {
  dispatch(serversGetRequest());
  query.limit = LIMIT_MASTER_MAX;
  const request = functions.httpsCallable('getDistributorSerials');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_SERIAL_SLIPS, response));
  } catch (response) {
    handleError(GET_SERIAL_SLIPS, response, dispatch);
  }
};

export const registerLoginHistory = () => async (dispatch) => {
  const request = functions.httpsCallable('registerLoginHistory');

  try {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let deviceType;
    let deviceOS;
    let browser;
    if (userAgent.indexOf('iphone') > 0) {
      deviceType = 'iPhone';
    } else if (
      userAgent.indexOf('ipad') > 0 ||
      (userAgent.indexOf('macintosh') !== -1 && 'ontouchend' in document)
    ) {
      deviceType = 'iPad';
    } else if (userAgent.indexOf('mobile') > 0 || userAgent.indexOf('android') > 0) {
      deviceType = 'Android';
    } else if (
      !(
        userAgent.indexOf('iphone') > 0 ||
        userAgent.indexOf('ipad') > 0 ||
        userAgent.indexOf('android') > 0 ||
        userAgent.indexOf('mobile') > 0
      )
    ) {
      deviceType = 'PC';
    }
    if (userAgent.indexOf('windows nt') !== -1) {
      deviceOS = 'Microsoft Windows';
    } else if (userAgent.indexOf('android') !== -1) {
      deviceOS = 'android';
    } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
      deviceOS = 'iOS';
    } else if (userAgent.indexOf('mac os x') !== -1) {
      deviceOS = 'macOS';
      if (deviceType === 'iPad') deviceOS = 'iOS';
    } else {
      deviceOS = 'other';
    }

    if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      browser = 'IE';
    } else if (userAgent.indexOf('edg') != -1) {
      browser = 'edge';
    } else if (userAgent.indexOf('chrome') != -1 || userAgent.indexOf('crios') != -1) {
      browser = 'chrome';
    } else if (userAgent.indexOf('firefox') != -1 || userAgent.indexOf('fxios') != -1) {
      browser = 'firefox';
    } else if (userAgent.indexOf('safari') != -1) {
      browser = 'safari';
    } else if (userAgent.indexOf('opera') != -1) {
      browser = 'opera';
    } else if (userAgent.indexOf('samsung') != -1) {
      browser = 'samsung';
    } else {
      browser = 'other';
    }
    const now = new Date().toISOString();
    let user = store.getState().servers.user;
    if (user) {
      const payload = {
        userId: user.id,
        loginEmail: user.email,
        loginName: user.name,
        locationId: user.locationId || '',
        locationName: user.location.name || '',
        locationCountry: user.location.country || '',
        actionType: 'SCANAPP_LOGIN',
        actionDatetime: now,
        deviceType: deviceType || '',
        deviceOS: deviceOS || '',
        browser: browser || '',
        browserVersion: '',
        browserLang: window.navigator.language || '',
        network:
          window.navigator.connection?.type || window.navigator.connection?.effectiveType || '',
      };
      const response = await request(payload);
      dispatch(serversResponseSuccess(REGISTER_LOGIN_HISTORY, response));
    }
  } catch (error) {
    // handleError(REGISTER_LOGIN_HISTORY, error, dispatch);
    console.log(error);
  }
};

export const clearLocations = () => ({ type: CLEAR_LOCATIONS });
