import { ECategoryType } from '@models/category-type';
import { IUser } from '@models/user';
import { isOk } from './is-ok';

export function getUserCategory(userInfo: IUser): ECategoryType {
  if (!isOk(userInfo)) return;
  return isOk(userInfo?.location?.category)
    ? userInfo?.location?.category
    : userInfo?.locationCategory;
}

export function isUserBreweryOrAdmin(userInfo: IUser) {
  if (!isOk(userInfo)) return;
  return [ECategoryType.ADMIN, ECategoryType.BREWERY].includes(getUserCategory(userInfo));
}

export function isUserAdmin(userInfo: IUser) {
  if (!isOk(userInfo)) return;
  return getUserCategory(userInfo) === ECategoryType.ADMIN;
}
