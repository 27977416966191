import { store } from '@/redux/reducers';
import {
  Box,
  Grid,
  Typography,
  StyleRules,
  Theme,
  ThemeProvider,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { functions } from '@utils/firebase';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { IQuery } from '@models/query';
import { ISerial } from '@models/serial';
import { IStateServers } from '@models/state-servers';
import { IStoreState } from '@models/store-state';
import appLanguages from '@utils/app-languages';
import TextField from '@mui/material/TextField';
import {
  getFormattedDateString,
  getSmallTextField,
  getTextFieldForSmall,
  nowrapHeader,
  SERIAL_ACTION,
  withConfirm,
} from '@utils/common';
import { DefaultMasterQuery } from '@utils/default-query';
import { formatString } from '@utils/format-string';
import {
  appMuiTheme,
  disableDisplayOpts,
  disableSortOpts,
  enableEmptyOpts,
  enableSortOnlyOpts,
  getDeleteActionsRender,
  getSelectCustomBodyRender,
  getWarningDeleteActionsRender,
  muiDataTableCommonOptions,
  rowDataToObj,
} from '@utils/mui-datatables-ops';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';
import userEnv from 'userEnv';

import { IStateApps } from 'models/state-app';
import { compose } from 'redux';
import { Clear as ClearIcon } from '@material-ui/icons';

import {
  openSerialEditDialog,
  openSnackbarMessage,
  setEditSerial,
} from '../../../redux/actions/appsActions';
import {
  delSerial,
  getLocations,
  getSerialBrands,
  getSerials,
  setSerialsQuery,
  setLocationsQuery,
  registerSerialsCancelShipping,
} from '../../../redux/actions/serversActions';
import { SerialEditDialog } from './SerialEditDialog';

import { Autocomplete } from '@mui/material';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

let slip_flag = false;
//let searchFlag = false;
let fromFlag = false;
let toFlag = false;
let statusFlag = false;
let disabled_reset_icon = true;
let language = 'en';

//Row data for the edit function
var statusInEditDialog;
let newStatusId;
//let confirmEditDialogDisabledFlag = true;
//These flags will check whether the required options in edit menu satisfy the condition as specified for each status edit
//let breweryValueChangedFlag = false;
let distributorValueChangeFlag = false;
let restaurantValueChangeFlag = false;
let brewShipAtValueChangedFlag = false;
//let slipValueChangedFlag = false;
let distRecvAtValueChangedFlag = false;
let distShipAtValueChangedFlag = false;
//let distSlipValueChangedFlag = false;
let restRecvAtValueChangedFlag = false;
let restSoldAtValueChangedFlag = false;

let distributorConfirm = null;
let restaurantConfirm = null;
let brewShipAtConfirm = null;
let distRecvAtConfirm = null;
let distShipAtConfirm = null;
let restRecvAtConfirm = null;
let restSoldAtConfirm = null;

//let objects;

//Variables for the edit dialog
//let openDialog = false;
//let closeDialog = true;
//Variables to store the location ids and creator IDS for the filtering of distrbutor and restaurant based on brewery have been stored in state as creatorId, newbreweryId and locationId

//Check edit location part of dashoard for reference
const options = [
  {
    id: -1,
    label: {
      en: '-',
      ja: '-',
      zhcn: '-',
      zhtw: '-',
      ko: '-',
      th: '-',
      vi: '-',
    },
  },
  {
    id: 0,
    label: {
      en: 'Brewery stock',
      ja: '酒蔵在庫',
      zhcn: '仓本股票',
      zhtw: '倉本股票',
      ko: '쿠라모토 재고',
      th: 'สต็อกโรงเบียร์',
      vi: 'Tồn kho Nhà máy',
    },
  },
  {
    id: 1,
    label: {
      en: 'Shipped from the brewery',
      ja: '酒蔵出荷済',
      zhcn: '从啤酒厂发货',
      zhtw: '從啤酒廠發貨',
      ko: '장원 선적',
      th: 'จัดส่งจากโรงเบียร์',
      vi: 'Đã xuất hàng từ Nhà máy',
    },
  },
  {
    id: 2,
    label: {
      en: 'Distributor Stock',
      ja: 'ディストリビューター在庫',
      zhcn: '分销商库存',
      zhtw: '分銷商庫存',
      ko: '디스트리뷰터 재고',
      th: 'สต็อกผู้จัดจำหน่าย',
      vi: 'Tồn kho của nhà phân phối',
    },
  },
  {
    id: 3,
    label: {
      en: 'Shipped from Distributor',
      ja: 'ディストリビューター出荷済',
      zhcn: '分销商发货',
      zhtw: '分銷商發貨',
      ko: '디스트리뷰터 선적',
      th: 'จัดส่งจากผู้จัดจำหน่าย',
      vi: 'Đã xuất hàng từ nhà phân phối',
    },
  },
  {
    id: 4,
    label: {
      en: 'Restaurant Stock',
      ja: 'レストラン在庫',
      zhcn: '餐厅库存',
      zhtw: '餐廳庫存',
      ko: '레스토랑 재고',
      th: 'สต็อกร้านอาหาร',
      vi: 'Tồn kho nhà hàng',
    },
  },
  {
    id: 5,
    label: {
      en: 'Sold',
      ja: '販売済み',
      zhcn: '卖',
      zhtw: '賣',
      ko: '판매됨',
      th: 'ขายแล้ว',
      vi: 'Đã bán',
    },
  },
  {
    id: 6,
    label: {
      en: 'Others (internal use, quarantine, damage, loss, etc.)',
      ja: 'その他(社内利用・検疫・破損・紛失など)',
      zhcn: '其他（内部使用、检疫、损坏、丢失等）',
      zhtw: '其他（內部使用、檢疫、損壞、丟失等）',
      ko: '기타 (사내 이용·검역·파손·분실 등)',
      th: 'อื่นๆ (การใช้งานภายใน การกักกัน การเสียหาย การสูญหาย ฯลฯ)',
      vi: 'Khác (sử dụng nội bộ, kiểm dịch, hư hỏng, mất mát, v.v.)',
    },
  },
];

const optionsDialogStatus = [
  {
    id: 0,
    label: {
      en: 'Brewery stock',
      ja: '酒蔵在庫',
      zhcn: '仓本股票',
      zhtw: '倉本股票',
      ko: '쿠라모토 재고',
      th: 'สต็อกโรงเบียร์',
      vi: 'Tồn kho Nhà máy',
    },
  },
  {
    id: 1,
    label: {
      en: 'Shipped from the brewery',
      ja: '酒蔵出荷済',
      zhcn: '从啤酒厂发货',
      zhtw: '從啤酒廠發貨',
      ko: '장원 선적',
      th: 'จัดส่งจากโรงเบียร์',
      vi: 'Đã xuất hàng từ Nhà máy',
    },
  },
  {
    id: 2,
    label: {
      en: 'Distributor Stock',
      ja: 'ディストリビューター在庫',
      zhcn: '分销商库存',
      zhtw: '分銷商庫存',
      ko: '디스트리뷰터 재고',
      th: 'สต็อกผู้จัดจำหน่าย',
      vi: 'Tồn kho của nhà phân phối',
    },
  },
  {
    id: 3,
    label: {
      en: 'Shipped from Distributor',
      ja: 'ディストリビューター出荷済',
      zhcn: '分销商发货',
      zhtw: '分銷商發貨',
      ko: '디스트리뷰터 선적',
      th: 'จัดส่งจากผู้จัดจำหน่าย',
      vi: 'Đã xuất hàng từ nhà phân phối',
    },
  },
  {
    id: 4,
    label: {
      en: 'Restaurant Stock',
      ja: 'レストラン在庫',
      zhcn: '餐厅库存',
      zhtw: '餐廳庫存',
      ko: '레스토랑 재고',
      th: 'สต็อกร้านอาหาร',
      vi: 'Tồn kho nhà hàng',
    },
  },
  {
    id: 5,
    label: {
      en: 'Sold',
      ja: '販売済み',
      zhcn: '卖',
      zhtw: '賣',
      ko: '판매됨',
      th: 'ขายแล้ว',
      vi: 'Đã bán',
    },
  },
  {
    id: 6,
    label: {
      en: 'Others (internal use, quarantine, damage, loss, etc.)',
      ja: 'その他(社内利用・検疫・破損・紛失など)',
      zhcn: '其他（内部使用、检疫、损坏、丢失等）',
      zhtw: '其他（內部使用、檢疫、損壞、丟失等）',
      ko: '기타 (사내 이용·검역·파손·분실 등)',
      th: 'อื่นๆ (การใช้งานภายใน การกักกัน การเสียหาย การสูญหาย ฯลฯ)',
      vi: 'Khác (sử dụng nội bộ, kiểm dịch, hư hỏng, mất mát, v.v.)',
    },
  },
];
let activeStatus = null;

class bottleManagementBrewery extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    viewType: 'stock',
    viewReference: 'brew',
  };
  public pages = {
    offset: 0,
    limit: 1000,
  };
  public query = {};

  constructor(props) {
    super(props);
    const { apps, viewReference } = this.props;
    const lang = apps.currentLanguage;
    language = lang;
    const columns = [];

    /**
      BREW 在庫: シリアル、銘柄
      BREW 出荷: シリアル、銘柄、伝票番号、出荷日(brewShipAt)、出荷先（ディストリビュータ）、出荷先 (レストラン)
      DIST 在庫: シリアル、銘柄、酒蔵、酒蔵 出荷日(brewShipAt)、入荷受付日
      DIST 出荷: シリアル、銘柄、伝票番号、出荷日(distShipAt)、出荷先 (レストラン)
     */

    columns.push({
      name: 'id',
      options: { ...disableDisplayOpts },
    });

    // シリアル
    columns.push({
      name: 'code',
      label: nowrapHeader(appLanguages.serialCode[lang]),
      options: { ...disableSortOpts },
    });

    // 銘柄
    columns.push({
      name: 'code',
      label: nowrapHeader(appLanguages.brand[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => this.getBrandName(v, lang),
      },
    });

    columns.push({
      name: 'status',
      label: nowrapHeader(appLanguages.baseName[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v, tableMeta) => {
          if (v === 0) {
            return getSelectCustomBodyRender(
              this.getBrewShipRestLocOptionMap(tableMeta.rowData[5]),
              tableMeta.rowData[5],
            );
          } else if (v === 1) {
            if (tableMeta.rowData[6] !== null) {
              return getSelectCustomBodyRender(
                this.getBrewShipRestLocOptionMap(tableMeta.rowData[6]),
                tableMeta.rowData[6],
              );
            } else
              return getSelectCustomBodyRender(
                this.getBrewShipRestLocOptionMap(tableMeta.rowData[7]),
                tableMeta.rowData[7],
              );
          } else if (v === 2) {
            return getSelectCustomBodyRender(
              this.getBrewShipRestLocOptionMap(tableMeta.rowData[8]),
              tableMeta.rowData[8],
            );
          } else if (v === 3) {
            return getSelectCustomBodyRender(
              this.getBrewShipRestLocOptionMap(tableMeta.rowData[9]),
              tableMeta.rowData[9],
            );
          } else if (v === 4) {
            return getSelectCustomBodyRender(
              this.getBrewShipRestLocOptionMap(tableMeta.rowData[10]),
              tableMeta.rowData[10],
            );
          } else if (v === 5) {
            return getSelectCustomBodyRender(
              this.getBrewShipRestLocOptionMap(tableMeta.rowData[10]),
              tableMeta.rowData[10],
            );
          } else if (v === 6) {
            if (tableMeta.rowData[6] && !tableMeta.rowData[7] && !tableMeta.rowData[9])
              return getSelectCustomBodyRender(
                this.getBrewShipRestLocOptionMap(tableMeta.rowData[6]),
                tableMeta.rowData[6],
              );
            else if (!tableMeta.rowData[6] && tableMeta.rowData[7] && !tableMeta.rowData[9]) {
              return getSelectCustomBodyRender(
                this.getBrewShipRestLocOptionMap(tableMeta.rowData[7]),
                tableMeta.rowData[7],
              );
            } else if (tableMeta.rowData[6] && !tableMeta.rowData[7] && tableMeta.rowData[9]) {
              return getSelectCustomBodyRender(
                this.getBrewShipRestLocOptionMap(tableMeta.rowData[9]),
                tableMeta.rowData[9],
              );
            }
          }

          getSelectCustomBodyRender(this.getBrandOptionMap(), v);
        },
      },
    });

    columns.push({
      name: 'status',
      label: nowrapHeader(appLanguages.status[lang]),
      options: {
        ...enableSortOnlyOpts,
        customBodyRender: (v) => {
          const object = Object.values(options).find((obj) => obj.id === v);
          return object.label[lang];
        },
      },
    });

    ///Columns bring referenced just to retreive the ID for the Kyotenmei column. All are hidden
    // 5
    columns.push({
      name: 'brewLocId',
      label: nowrapHeader(appLanguages.status[lang]),
      options: {
        display: false,
      },
    });

    // 6
    columns.push({
      name: 'brewShipDistLocId',
      label: nowrapHeader(appLanguages.status[lang]),
      options: {
        display: false,
      },
    });

    //7
    columns.push({
      name: 'brewShipRestLocId',
      label: nowrapHeader(appLanguages.status[lang]),
      options: {
        display: false,
      },
    });

    //8
    columns.push({
      name: 'recvDistLocId',
      label: nowrapHeader(appLanguages.status[lang]),
      options: {
        display: false,
      },
    });

    //9
    columns.push({
      name: 'distShipRestLocId',
      label: nowrapHeader(appLanguages.status[lang]),
      options: {
        display: false,
      },
    });

    //10
    columns.push({
      name: 'recvRestLocId',
      label: nowrapHeader(appLanguages.status[lang]),
      options: {
        display: false,
      },
    });
    //11
    columns.push({
      name: 'soldRestLocId',
      label: nowrapHeader(appLanguages.status[lang]),
      options: {
        display: false,
      },
    });

    if (userEnv.enableSerialEdit && viewReference !== 'distributor') {
      columns.push({
        name: '',
        options: {
          ...enableEmptyOpts,
          customBodyRender: (_, mt) => this.getActionsRender(mt),
        },
      });
    }
    this.state = {
      initialized: false,
      columns,
      serialInputObjects: { from: '', to: '', fromErr: '', toErr: '' },
      statusValue: '',
      filterTimeout: undefined,
      filterSalesOrder: '',
      filterFromSerial: 0,
      filterToSerial: 0,
      filterStatus: '',
      currentPageNo: 0,
      openDialog: false,
      closeDialog: true,
      distributorDisabledFlag: true,
      restaurantDisabledFlag: true,
      filterDistId: '',
      filterBrewId: '',
      filterRestId: '',
      newBreweryId: 0,
      creatorId: {},
      locationId: {},
      usersData: {},
      tableMeta: {},
      newBrewShiptAt: '',
      newSlip: '',
      newDistRecvAt: '',
      newDistShipAt: '',
      newDistSlip: '',
      newRestRecvAt: '',
      newRestSoldAt: '',
      brewShipAtDisabledflag: true,
      slipDisabledFlag: true,
      distRecvAtDisabledFlag: true,
      distShipAtDisabledFlag: true,
      distSlipDisabledFlag: true,
      restRecvAtDisabledFlag: true,
      restSoldAtDisabledFlag: true,
      activeStatusEditDialog: null,
      serialNumber: '',
    };
  }

  async componentDidMount() {
    const { servers, setSerialsQuery, getSerialBrands, getLocations } = this.props;
    const { user } = servers;
    const { location } = user;
    //const { user } = servers;
    let whereClause = {};

    whereClause['locationId'] = location.id;
    await getSerialBrands({ ...DefaultMasterQuery, where: whereClause });
    const queryUsers = {
      offset: 0,
      limit: 10000000,
      searchText: '',
      order: [['createdAt', 'DESC']],
      where: {},
      totalCounts: 0,
    };

    // Sreenath27thOct - Unordered serials list
    // fetch serials in ascending order with respect to viewReference and view type
    let orderBy = [
      ['updatedAt', 'DESC'],
      ['code', 'ASC'],
    ];

    const query = {
      ...servers.serialsQuery,
      order: orderBy,
      searchText: null,
      where: { brewLocId: location.id },
      offset: 0,
      limit: 100,
    };
    this.query = query;
    setSerialsQuery(query);

    //await getLocations({ ...this.query });

    functions
      .httpsCallable('getUsers')(queryUsers)
      .then((result) => {
        const { data } = result;
        this.setState({ usersData: data });
        //console.log('Users: ' + JSON.stringify(objects, null, 4));
      });

    //console.log('Users: ' + JSON.stringify(this.state.usersData, null, 4));
    // Sreenath26thOct - Brand Info
    // for brewery only show the registered brands in the dropdown list.

    //await getSerials(query);
    await getLocations({ ...DefaultMasterQuery, where: { hidden: false } });
    await this.setState({ initialized: true });
    //this.setState({ filterBrewId: location.id });
  }

  getSlipText(text) {
    return text ? text : 'N/A';
  }

  getBrandOptionMap() {
    const { servers } = this.props;
    const optionMap = {};
    servers.serialBrands.map((o) => {
      optionMap[o.id] = o.name;
      return o.id;
    });
    return optionMap;
  }

  getBrandName(code: string, lang: string): string | null {
    const { servers } = this.props;
    if (servers.serials) {
      const serial = servers.serials.find((v) => v.code === code);
      return serial.brand?.name;
    }
  }

  getShipAtText(dateString, lang) {
    let shipAtText = appLanguages.unshipped[lang];
    if (dateString) {
      const date = new Date(Date.parse(dateString));
      shipAtText = getFormattedDateString(date);
    } else {
      shipAtText = 'N/A';
    }
    return shipAtText;
  }

  getBrewShipRestLocOptionMap(v) {
    const { servers } = this.props;
    //console.log(servers);
    const optionMap = {};
    servers?.locations?.forEach((o) => {
      optionMap[o.id] = o.name;
    });

    if (!(v in optionMap)) {
      optionMap[v] = 'N/A';
    }
    return optionMap;
  }

  getEditActionsRender(tableMeta) {
    const obj = tableMeta;
    //console.log('Obj : ' + JSON.stringify(obj, null, 4));
    //const isRequesting = servers ? servers.isRequesting : true;

    //rowDataGlobal = rowData;
    //console.log(rowDataGlobal);
    //const invalid = rowData[columnIndex - 1]; // brewShipAt
    return (
      <>
        {' '}
        <Tooltip title='Edit'>
          <IconButton size='small' color='primary' onClick={() => this.handleOnClickEdit(obj)}>
            <EditIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  getDeleteActionsRender(tableMeta) {
    const {
      servers,
      confirm,
      openSnackbarMessage,
      apps: { currentLanguage },
    } = this.props;
    const obj = this.rowDataToObj(tableMeta.rowData);
    const isRequesting = servers ? servers.isRequesting : true;
    const lang = store.getState().apps.currentLanguage;
    const delTitle = appLanguages.serialDelete[lang];
    const delDesc = formatString(appLanguages.confirmSerialDeleteAdmin[lang], {
      field: obj['code'],
    });
    const onClickDelete = (o) => this.handleOnClickDelete(o);
    const { columnIndex, rowData } = tableMeta;
    const invalid = rowData[columnIndex - 1]; // brewShipAt
    return (
      <>
        {invalid
          ? getWarningDeleteActionsRender(() =>
              openSnackbarMessage(
                'error',
                appLanguages.cannotUpdateDueToBreweryProcedures[currentLanguage],
              ),
            )
          : getDeleteActionsRender(obj, delTitle, delDesc, isRequesting, onClickDelete, confirm)}
      </>
    );
  }

  getActionsRender(tableMeta) {
    return (
      <>
        <Grid container wrap='nowrap'>
          <Grid item>{this.getEditActionsRender(tableMeta)}</Grid>
          <Grid item>{this.getDeleteActionsRender(tableMeta)}</Grid>
        </Grid>
      </>
    );
  }

  rowDataToObj(rowData) {
    const { columns } = this.state;
    return rowDataToObj(columns, rowData);
  }

  updateQuery(newQuery) {
    const { servers, setSerialsQuery, getSerials } = this.props;
    const query = { ...servers.serialsQuery, ...newQuery };
    setSerialsQuery(query);
    getSerials(query, undefined, true);
  }

  handleClose = () => {
    this.setState({ openDialog: false, closeDialog: true });
    //activeStatusEditDialog = optionsDialogStatus['label'[language]];
    this.setState({ activeStatusEditDialog: optionsDialogStatus['label'[language]] });
    this.setState({
      filterBrewId: '',
      filterDistId: '',
      filterRestId: '',
      newBrewShiptAt: null,
      newSlip: '',
      newDistRecvAt: null,
      newDistShipAt: null,
      newDistSlip: '',
      newRestRecvAt: null,
      newRestSoldAt: null,
    });
    this.setState({
      distributorDisabledFlag: true,
      restaurantDisabledFlag: true,
      slipDisabledFlag: true,
      brewShipAtDisabledflag: true,
      distRecvAtDisabledFlag: true,
      distShipAtDisabledFlag: true,
      distSlipDisabledFlag: true,
      restRecvAtDisabledFlag: true,
      restSoldAtDisabledFlag: true,
    });
    //breweryValueChangedFlag = false;
    distributorValueChangeFlag = false;
    restaurantValueChangeFlag = false;
    brewShipAtValueChangedFlag = false;
    //slipValueChangedFlag = false;
    distRecvAtValueChangedFlag = false;
    distShipAtValueChangedFlag = false;
    //distSlipValueChangedFlag = false;
    restRecvAtValueChangedFlag = false;
    restSoldAtValueChangedFlag = false;
  };

  handleOnClickEdit(obj) {
    //Take the metadata from obj and find the row and tableData etc

    console.log('entered here');
    this.setState({ tableMeta: obj });

    this.setState({ openDialog: true, closeDialog: false });

    //Block to make existing status appear in status box when edit is opened
    statusInEditDialog = parseInt(obj.rowData[3]);
    newStatusId = statusInEditDialog;
    const object = Object.values(optionsDialogStatus).find((obj) => obj.id === statusInEditDialog);
    this.setState({ activeStatusEditDialog: object.label[language] });

    //Take serial number frm metadata
    this.setState({ serialNumber: obj.tableData[obj.rowIndex]['code'] });

    //Block to make all the dates show existing dates if present
    this.setState({ newBrewShiptAt: obj.tableData[obj.rowIndex]['brewShipAt'] });
    this.setState({ newDistRecvAt: obj.tableData[obj.rowIndex]['distRecvAt'] });
    this.setState({ newDistShipAt: obj.tableData[obj.rowIndex]['distShipAt'] });
    this.setState({ newRestRecvAt: obj.tableData[obj.rowIndex]['restRecvAt'] });
    this.setState({ newRestSoldAt: obj.tableData[obj.rowIndex]['restSoldAt'] });

    //Block to fill slip boxes with existing slips if any
    this.setState({ newSlip: obj.tableData[obj.rowIndex]['slip'] });
    this.setState({ newDistSlip: obj.tableData[obj.rowIndex]['distSlip'] });

    //Block to fill distributor, brewery and restaurant drop down with existing values if any
    this.setState({ filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'] });
    //Block to determine distributor and restaurant value based on each status
    if (statusInEditDialog === 1) {
      this.setState({ filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'] });
      this.setState({ filterRestId: obj.tableData[obj.rowIndex]['brewShipRestLocId'] });
    } else if (statusInEditDialog === 2) {
      this.setState({ filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'] });
    } else if (
      statusInEditDialog === 3 ||
      statusInEditDialog === 4 ||
      statusInEditDialog === 5 ||
      statusInEditDialog === 6
    ) {
      this.setState({ filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'] });
      this.setState({
        filterRestId:
          obj.tableData[obj.rowIndex]['distShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['brewShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['recvRestLocId'] ||
          obj.tableData[obj.rowIndex]['soldRestLocId'],
      });
    }

    //Update value of Changedvalue flags for each field to determine if confirm button is disabled or not
    // if (this.state.filterBrewId !== null) breweryValueChangedFlag = true;
    // else breweryValueChangedFlag = false;
    distributorConfirm = obj.tableData[obj.rowIndex]['brewShipDistLocId'];
    (restaurantConfirm =
      obj.tableData[obj.rowIndex]['distShipRestLocId'] ||
      obj.tableData[obj.rowIndex]['brewShipRestLocId'] ||
      obj.tableData[obj.rowIndex]['recvRestLocId'] ||
      obj.tableData[obj.rowIndex]['soldRestLocId']),
      (brewShipAtConfirm = obj.tableData[obj.rowIndex]['brewShipAt']);
    distRecvAtConfirm = obj.tableData[obj.rowIndex]['distRecvAt'];
    distShipAtConfirm = obj.tableData[obj.rowIndex]['distShipAt'];
    restRecvAtConfirm = obj.tableData[obj.rowIndex]['restRecvAt'];
    restSoldAtConfirm = obj.tableData[obj.rowIndex]['restSoldAt'];
    if (newStatusId !== 1) {
      if (restaurantConfirm !== null) restaurantValueChangeFlag = true;
      else restaurantValueChangeFlag = false;
      if (distributorConfirm !== null) distributorValueChangeFlag = true;
      else distributorValueChangeFlag = false;
    }
    if (distRecvAtConfirm !== null) distRecvAtValueChangedFlag = true;
    else distRecvAtValueChangedFlag = false;
    if (distShipAtConfirm !== null) distShipAtValueChangedFlag = true;
    else distShipAtValueChangedFlag = false;
    if (brewShipAtConfirm !== null) brewShipAtValueChangedFlag = true;
    else brewShipAtValueChangedFlag = false;

    if (restRecvAtConfirm !== null) restRecvAtValueChangedFlag = true;
    else restRecvAtValueChangedFlag = false;
    if (restSoldAtConfirm !== null) restSoldAtValueChangedFlag = true;
    else restSoldAtValueChangedFlag = false;

    //Block to enable required fields based on status value
    if (statusInEditDialog === 0) {
      this.setState({
        distributorDisabledFlag: true,
        restaurantDisabledFlag: true,
        brewShipAtDisabledflag: true,
        slipDisabledFlag: true,
        distRecvAtDisabledFlag: true,
        distShipAtDisabledFlag: true,
        distSlipDisabledFlag: true,
        restRecvAtDisabledFlag: true,
        restSoldAtDisabledFlag: true,
      });
    } else if (statusInEditDialog === 1) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: true,
        distShipAtDisabledFlag: true,
        distSlipDisabledFlag: true,
        restRecvAtDisabledFlag: true,
        restSoldAtDisabledFlag: true,
      });
    } else if (statusInEditDialog === 2) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: true,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: true,
        distSlipDisabledFlag: true,
        restRecvAtDisabledFlag: true,
        restSoldAtDisabledFlag: true,
      });
    } else if (statusInEditDialog === 3) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: false,
        distSlipDisabledFlag: false,
        restRecvAtDisabledFlag: true,
        restSoldAtDisabledFlag: true,
      });
    } else if (statusInEditDialog === 4) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: false,
        distSlipDisabledFlag: false,
        restRecvAtDisabledFlag: false,
        restSoldAtDisabledFlag: true,
      });
    } else if (statusInEditDialog === 5) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: false,
        distSlipDisabledFlag: false,
        restRecvAtDisabledFlag: false,
        restSoldAtDisabledFlag: false,
      });
    } else if (statusInEditDialog === 6) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: false,
        distSlipDisabledFlag: false,
        restRecvAtDisabledFlag: false,
        restSoldAtDisabledFlag: false,
      });
    }

    //Update distributore and restaurant list to show only the ones connected to brewery by changing flag
    //breweryValueChangedFlag = true;
  }

  handleOnClickDelete(obj) {
    const { delSerial } = this.props;
    delSerial(obj);
  }

  handleOnChangePage(currentPage) {
    this.setState(
      (prevState) => ({ ...prevState, currentPageNo: currentPage }),
      () => {},
    );
    this.updateQuery({ offset: currentPage * 100 });
  }

  handleOnColumnSortChange(changedColumn, direction) {
    const { columns } = this.state;
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({
      order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']],
    });
  }

  setFilter(newWhere) {
    const { servers } = this.props;
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const query = Object.assign({}, this.query, { where });
    this.pages.limit = query['limit'];
    this.pages.offset = query['offset'];
    //this.updateQuery(query);
    this.setState({ filterTimeout: null });
  }

  /////////////////////////Functions newly created for bottle Management
  handleChangeSlip(slip) {
    const { servers, setSerialsQuery } = this.props;
    slip_flag = true;
    this.setState({
      filterSalesOrder: slip,
      //filterTimeout: setTimeout(() => this.setFilter({ slip: { $like: `%${slip}%` } }), 1000),
    });
    let newWhere = { slip: { $like: `%${slip}%` } };
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const newquery = Object.assign({}, this.query, { where });
    this.pages.limit = newquery['limit'];
    this.pages.offset = newquery['offset'];
    setSerialsQuery(newquery);
  }

  protected handleChangeFromSerials = (data: string) => {
    //Function to process and update the object when FROM boxes are filled with serial number

    fromFlag = true;
    this.setState(
      (prevState) => ({
        ...prevState,
        serialInputObjects: {
          ...prevState.serialInputObjects,
          from: data,
        },
      }),
      () => {
        //console.log('New from value : ' + this.state.serialInputObjects.from);
      },
    );
  };

  protected handleChangeToSerials = (data: string) => {
    //Function to process and update the object when FROM boxes are filled with serial number

    const { servers, setSerialsQuery } = this.props;
    toFlag = true;
    this.setState(
      (prevState) => ({
        ...prevState,
        serialInputObjects: {
          ...prevState.serialInputObjects,
          to: data,
        },
      }),
      () => {
        //console.log('New to value : ' + this.state.serialInputObjects.to);
      },
    );
    let newWhere = {
      ['code']: { $gte: parseInt(this.state.serialInputObjects.from), $lte: parseInt(data) },
    };
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const newquery = Object.assign({}, this.query, { where });
    //this.pages.limit = newquery['limit'];
    //this.pages.offset = newquery['offset'];
    setSerialsQuery(newquery);
    //console.log(servers.serialsQuery);
  };

  clearFilterTimeout() {
    const { filterTimeout } = this.state;
    this.pages.limit = 1000000;
    this.pages.offset = 0;
    if (filterTimeout) clearTimeout(filterTimeout);
  }

  handleChangeStatus(values) {
    statusFlag = true;
    const { servers, setSerialsQuery } = this.props;
    //console.log(servers.serialsQuery)
    const object = Object.values(options).find((obj) => obj.id === values.id);
    activeStatus = object.label[language];
    let newWhere = {
      ['status']: values.id,
    };
    if (values.id === -1) {
      newWhere = {
        ['status']: { $gte: 0, $lte: 6 },
      };
    }
    const where = { ...servers.serialsQuery.where, ...newWhere };
    const newquery = Object.assign({}, this.query, { where });
    this.pages.limit = newquery['limit'];
    this.pages.offset = newquery['offset'];
    setSerialsQuery(newquery);
  }

  handleChangeStatusEditDialog(values) {
    let obj = this.state.tableMeta;
    const object = Object.values(optionsDialogStatus).find((obj) => obj.id === values.id);
    // this.setState({
    //   filterDistId: '',
    //   filterRestId: '',
    //   newSlip: '',
    //   newBrewShiptAt: null,
    //   newDistRecvAt: null,
    //   newDistShipAt: null,
    //   newDistSlip: '',
    //   newRestRecvAt: null,
    //   newRestSoldAt: null,
    // });
    //this.setState({ activeStatusEditDialog: object.label[language] });
    restaurantValueChangeFlag = false;
    distributorValueChangeFlag = false;
    //breweryValueChangedFlag = false;
    console.log('Being called');
    statusInEditDialog = parseInt(values.id);
    newStatusId = parseInt(values.id);
    if (newStatusId === 0) {
      this.setState({
        distributorDisabledFlag: true,
        restaurantDisabledFlag: true,
        brewShipAtDisabledflag: true,
        slipDisabledFlag: true,
        distRecvAtDisabledFlag: true,
        distShipAtDisabledFlag: true,
        distSlipDisabledFlag: true,
        restRecvAtDisabledFlag: true,
        restSoldAtDisabledFlag: true,
      });
      //Change the values of each box according to format for that status value
      this.setState({
        filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'],
        newBrewShiptAt: null,
        newSlip: '',
        filterDistId: null,
        newDistRecvAt: null,
        newDistShipAt: null,
        newDistSlip: '',
        filterRestId: null,
        newRestRecvAt: null,
        newRestSoldAt: null,
      });
    } else if (newStatusId === 1) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: true,
        distShipAtDisabledFlag: true,
        distSlipDisabledFlag: true,
        restRecvAtDisabledFlag: true,
        restSoldAtDisabledFlag: true,
      });
      //Change the values of each box according to format for that status value
      this.setState({
        filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'],
        newBrewShiptAt: obj.tableData[obj.rowIndex]['brewShipAt'],
        newSlip: obj.tableData[obj.rowIndex]['slip'],
        filterDistId: null,
        newDistRecvAt: null,
        newDistShipAt: null,
        newDistSlip: '',
        filterRestId: null,

        newRestRecvAt: null,
        newRestSoldAt: null,
      });
    } else if (newStatusId === 2) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: true,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: true,
        distSlipDisabledFlag: true,
        restRecvAtDisabledFlag: true,
        restSoldAtDisabledFlag: true,
      });
      //Change the values of each box according to format for that status value
      this.setState({
        filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'],
        newBrewShiptAt: obj.tableData[obj.rowIndex]['brewShipAt'],
        newSlip: obj.tableData[obj.rowIndex]['slip'],
        filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'],
        newDistRecvAt: obj.tableData[obj.rowIndex]['distRecvAt'],
        newDistShipAt: null,
        newDistSlip: '',
        filterRestId: null,
        newRestRecvAt: null,
        newRestSoldAt: null,
      });
    } else if (newStatusId === 3) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: false,
        distSlipDisabledFlag: false,
        restRecvAtDisabledFlag: true,
        restSoldAtDisabledFlag: true,
      });
      //Change the values of each box according to format for that status value
      this.setState({
        filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'],
        newBrewShiptAt: obj.tableData[obj.rowIndex]['brewShipAt'],
        newSlip: obj.tableData[obj.rowIndex]['slip'],
        filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'],
        newDistRecvAt: obj.tableData[obj.rowIndex]['distRecvAt'],
        newDistShipAt: obj.tableData[obj.rowIndex]['distShipAt'],
        newDistSlip: obj.tableData[obj.rowIndex]['distSlip'],
        filterRestId:
          obj.tableData[obj.rowIndex]['distShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['brewShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['recvRestLocId'] ||
          obj.tableData[obj.rowIndex]['soldRestLocId'],
        newRestRecvAt: null,
        newRestSoldAt: null,
      });
    } else if (newStatusId === 4) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: false,
        distSlipDisabledFlag: false,
        restRecvAtDisabledFlag: false,
        restSoldAtDisabledFlag: true,
      });
      //Change the values of each box according to format for that status value
      this.setState({
        filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'],
        newBrewShiptAt: obj.tableData[obj.rowIndex]['brewShipAt'],
        newSlip: obj.tableData[obj.rowIndex]['slip'],
        filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'],
        newDistRecvAt: obj.tableData[obj.rowIndex]['distRecvAt'],
        newDistShipAt: obj.tableData[obj.rowIndex]['distShipAt'],
        newDistSlip: obj.tableData[obj.rowIndex]['distSlip'],
        filterRestId:
          obj.tableData[obj.rowIndex]['distShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['brewShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['recvRestLocId'] ||
          obj.tableData[obj.rowIndex]['soldRestLocId'],
        newRestRecvAt: obj.tableData[obj.rowIndex]['restRecvAt'],
        newRestSoldAt: null,
      });
    } else if (newStatusId === 5) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: false,
        distSlipDisabledFlag: false,
        restRecvAtDisabledFlag: false,
        restSoldAtDisabledFlag: false,
      });
      //Change the values of each box according to format for that status value
      this.setState({
        filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'],
        newBrewShiptAt: obj.tableData[obj.rowIndex]['brewShipAt'],
        newSlip: obj.tableData[obj.rowIndex]['slip'],
        filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'],
        newDistRecvAt: obj.tableData[obj.rowIndex]['distRecvAt'],
        newDistShipAt: obj.tableData[obj.rowIndex]['distShipAt'],
        newDistSlip: obj.tableData[obj.rowIndex]['distSlip'],
        filterRestId:
          obj.tableData[obj.rowIndex]['distShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['brewShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['recvRestLocId'] ||
          obj.tableData[obj.rowIndex]['soldRestLocId'],
        newRestRecvAt: obj.tableData[obj.rowIndex]['restRecvAt'],
        newRestSoldAt: obj.tableData[obj.rowIndex]['restSoldAt'],
      });
    } else if (newStatusId === 6) {
      this.setState({
        distributorDisabledFlag: false,
        restaurantDisabledFlag: false,
        slipDisabledFlag: false,
        brewShipAtDisabledflag: false,
        distRecvAtDisabledFlag: false,
        distShipAtDisabledFlag: false,
        distSlipDisabledFlag: false,
        restRecvAtDisabledFlag: false,
        restSoldAtDisabledFlag: false,
      });
      //Change the values of each box according to format for that status value
      this.setState({
        filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'],
        newBrewShiptAt: obj.tableData[obj.rowIndex]['brewShipAt'],
        newSlip: obj.tableData[obj.rowIndex]['slip'],
        filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'],
        newDistRecvAt: obj.tableData[obj.rowIndex]['distRecvAt'],
        newDistShipAt: obj.tableData[obj.rowIndex]['distShipAt'],
        newDistSlip: obj.tableData[obj.rowIndex]['distSlip'],
        filterRestId:
          obj.tableData[obj.rowIndex]['distShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['brewShipRestLocId'] ||
          obj.tableData[obj.rowIndex]['recvRestLocId'] ||
          obj.tableData[obj.rowIndex]['soldRestLocId'],
        newRestRecvAt: obj.tableData[obj.rowIndex]['restRecvAt'],
        newRestSoldAt: obj.tableData[obj.rowIndex]['restSoldAt'],
      });
    }
    //this.setState({ filterBrewId: '', filterDistId: '', filterRestId: '' });

    //const object = Object.values(optionsDialogStatus).find((obj) => obj.id === statusInEditDialog);
    this.setState({ activeStatusEditDialog: object.label[language] });

    //Update value of Changedvalue flags for each field to determine if confirm button is disabled or not
    // if (this.state.filterBrewId !== null) breweryValueChangedFlag = true;
    // else breweryValueChangedFlag = false;
    if (newStatusId !== 1) {
      distributorConfirm = obj.tableData[obj.rowIndex]['brewShipDistLocId'];
      restaurantConfirm =
        obj.tableData[obj.rowIndex]['distShipRestLocId'] ||
        obj.tableData[obj.rowIndex]['brewShipRestLocId'] ||
        obj.tableData[obj.rowIndex]['recvRestLocId'] ||
        obj.tableData[obj.rowIndex]['soldRestLocId'];
    }
    brewShipAtConfirm = obj.tableData[obj.rowIndex]['brewShipAt'];
    distRecvAtConfirm = obj.tableData[obj.rowIndex]['distRecvAt'];
    distShipAtConfirm = obj.tableData[obj.rowIndex]['distShipAt'];
    restRecvAtConfirm = obj.tableData[obj.rowIndex]['restRecvAt'];
    restSoldAtConfirm = obj.tableData[obj.rowIndex]['restSoldAt'];

    if (restaurantConfirm !== null) restaurantValueChangeFlag = true;
    else restaurantValueChangeFlag = false;
    if (distributorConfirm !== null) distributorValueChangeFlag = true;
    else distributorValueChangeFlag = false;

    if (distRecvAtConfirm !== null) distRecvAtValueChangedFlag = true;
    else distRecvAtValueChangedFlag = false;
    if (distShipAtConfirm !== null) distShipAtValueChangedFlag = true;
    else distShipAtValueChangedFlag = false;
    if (brewShipAtConfirm !== null) brewShipAtValueChangedFlag = true;
    else brewShipAtValueChangedFlag = false;

    if (restRecvAtConfirm !== null) restRecvAtValueChangedFlag = true;
    else restRecvAtValueChangedFlag = false;
    if (restSoldAtConfirm !== null) restSoldAtValueChangedFlag = true;
    else restSoldAtValueChangedFlag = false;

    if (newStatusId == 1) {
      (restaurantValueChangeFlag = false), (distributorValueChangeFlag = false);
    }

    //Update distributore and restaurant list to show only the ones connected to brewery by changing flag
    //breweryValueChangedFlag = true;
  }

  async handleSearch() {
    const { servers, getSerials, getLocations } = this.props;
    const query = { ...servers.serialsQuery };

    disabled_reset_icon = false;
    //setSerialsQuery(query);
    await getSerials(query, undefined, true);
    await getLocations({ ...DefaultMasterQuery });
    slip_flag = false;
    fromFlag = false;
    toFlag = false;
    statusFlag = false;
  }

  async refresh() {
    const { setSerialsQuery, servers } = this.props;
    const { user } = servers;
    const { location } = user;
    this.setState((prevState) => ({
      ...prevState,
      filterSalesOrder: '',
      serialInputObjects: {
        ...prevState.serialInputObjects,
        to: 0,
        from: 0,
        //filterTimeout: setTimeout(() => this.setFilter({ slip: { $like: `%${slip}%` } }), 1000),
      },
    }));
    activeStatus = null;
    disabled_reset_icon = true;
    const where = { brewLocId: location.id };
    const newquery = Object.assign({}, this.query, { where: where });

    setSerialsQuery(newquery);
    //await getSerials(newquery);
    //console.log('Inside refresh function ', disabled_reset_icon);
  }

  renderOptionLabel(options) {
    const currentLanguage = language; // Change this dynamically based on user preference
    const opt = typeof options === 'object' ? options.label[currentLanguage] : options;
    return opt;
  }

  renderOptionLabelEditDialog(options) {
    const currentLanguage = language; // Change this dynamically based on user preference
    const opt = typeof options === 'object' ? options.label[currentLanguage] : options;
    return opt;
  }

  handleChangeDistributor = (v) => {
    //console.log('Distributor: ' + JSON.stringify(v, null, 4));
    this.setState({ filterDistId: v.id ? v.id : '', filterRestId: '' });
    distributorValueChangeFlag = true;
    restaurantValueChangeFlag = false;
    //console.log(filterDistId);
    //console.log(distValue);
    if (newStatusId === 1 && restaurantValueChangeFlag === false) {
      //This means restaurant is already entered so disable distributor
      this.setState({ restaurantDisabledFlag: true });
    }
  };

  handleChangeRestaurant = (v) => {
    //console.log('Rest: ' + JSON.stringify(v, null, 4));
    this.setState({ filterRestId: v.id ? v.id : '' });
    restaurantValueChangeFlag = true;
    if (newStatusId === 1 && distributorValueChangeFlag === false) {
      //This means restaurant is already entered so disable distributor
      this.setState({ distributorDisabledFlag: true });
    }
  };

  handleChangeBrewery = (v) => {
    //this.getDistributorAndBrewery();
    restaurantValueChangeFlag = false;
    distributorValueChangeFlag = false;

    //Display the existing values of all variables as present
    //let obj = this.state.tableMeta;
    const object = Object.values(optionsDialogStatus).find((obj) => obj.id === statusInEditDialog);
    this.setState({ activeStatusEditDialog: object.label[language] });

    // //Block to make all the dates show existing dates if present
    // this.setState({ newBrewShiptAt: obj.tableData[obj.rowIndex]['brewShipAt'] });
    // this.setState({ newDistRecvAt: obj.tableData[obj.rowIndex]['distRecvAt'] });
    // this.setState({ newDistShipAt: obj.tableData[obj.rowIndex]['distShipAt'] });
    // this.setState({ newRestRecvAt: obj.tableData[obj.rowIndex]['restRecvAt'] });
    // this.setState({ newRestSoldAt: obj.tableData[obj.rowIndex]['restSoldAt'] });

    // //Block to fill slip boxes with existing slips if any
    // this.setState({ newSlip: obj.tableData[obj.rowIndex]['slip'] });
    // this.setState({ newDistSlip: obj.tableData[obj.rowIndex]['distSlip'] });

    // //Block to fill distributor, brewery and restaurant drop down with existing values if any
    // //this.setState({ filterBrewId: obj.tableData[obj.rowIndex]['brewLocId'] });
    // //Block to determine distributor and restaurant value based on each status
    // if (statusInEditDialog === 1) {
    //   this.setState({ filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'] });
    //   this.setState({ filterRestId: obj.tableData[obj.rowIndex]['brewShipRestLocId'] });
    // } else if (statusInEditDialog === 2) {
    //   this.setState({ filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'] });
    // } else if (
    //   statusInEditDialog === 3 ||
    //   statusInEditDialog === 4 ||
    //   statusInEditDialog === 5 ||
    //   statusInEditDialog === 6
    // ) {
    //   this.setState({ filterDistId: obj.tableData[obj.rowIndex]['brewShipDistLocId'] });
    //   this.setState({
    //     filterRestId:
    //       obj.tableData[obj.rowIndex]['distShipRestLocId'] ||
    //       obj.tableData[obj.rowIndex]['brewShipRestLocId'] ||
    //       obj.tableData[obj.rowIndex]['recvRestLocId'] ||
    //       obj.tableData[obj.rowIndex]['soldRestLocId'],
    //   });
    // }

    if (newStatusId === 1) {
      this.setState({ distributorDisabledFlag: false, restaurantDisabledFlag: false });
    }
    //console.log('Brewer: ' + JSON.stringify(v, null, 4));
    this.setState({ filterBrewId: v.id });
    if (newStatusId >= 0 && newStatusId <= 6) {
      //breweryValueChangedFlag = true;
    }
  };

  handleChangeBrewShipAt(date) {
    //console.log('Date ' + new Date(date).toISOString());
    this.setState({
      newBrewShiptAt: new Date(date).toISOString(),
    });
    brewShipAtValueChangedFlag = true;
    //console.log(this.state.newBrewShiptAt);
  }

  handleChangeDistRecvAt(date) {
    this.setState({ newDistRecvAt: new Date(date).toISOString() });
    distRecvAtValueChangedFlag = true;
  }

  handleChangeDistShipAt(date) {
    this.setState({ newDistShipAt: new Date(date).toISOString() });
    distShipAtValueChangedFlag = true;
  }

  handleChangeRestRecvAt(date) {
    this.setState({ newRestRecvAt: new Date(date).toISOString() });
    restRecvAtValueChangedFlag = true;
  }

  handleChangeRestSoldAt(date) {
    this.setState({ newRestSoldAt: new Date(date).toISOString() });
    restSoldAtValueChangedFlag = true;
  }

  handleChangeBrewSlip(slip) {
    this.setState({ newSlip: slip });
    //slipValueChangedFlag = true;
    //console.log(typeof this.state.newSlip);
  }

  handleChangeDistSlip(slip) {
    this.setState({ newDistSlip: slip });
    //distSlipValueChangedFlag = true;
  }

  isConfirmDisabled() {
    if (
      this.state.newBrewShiptAt > this.state.newDistRecvAt ||
      this.state.newBrewShiptAt > this.state.newDistShipAt ||
      this.state.newBrewShiptAt > this.state.newRestRecvAt ||
      this.state.newBrewShiptAt > this.state.newRestSoldAt
    ) {
      return true;
    } else if (
      this.state.newDistRecvAt < this.state.newBrewShiptAt ||
      this.state.newDistRecvAt > this.state.newDistShipAt ||
      this.state.newDistRecvAt > this.state.newRestRecvAt ||
      this.state.newDistRecvAt > this.state.newRestSoldAt
    ) {
      return true;
    } else if (
      this.state.newDistShipAt < this.state.newBrewShiptAt ||
      this.state.newDistShipAt < this.state.newDistRecvAt ||
      this.state.newDistShipAt > this.state.newRestRecvAt ||
      this.state.newDistShipAt > this.state.newRestSoldAt
    ) {
      return true;
    } else if (
      this.state.newRestRecvAt < this.state.newBrewShiptAt ||
      this.state.newRestRecvAt < this.state.newDistShipAt ||
      this.state.newRestRecvAt < this.state.newDistRecvAt ||
      this.state.newRestRecvAt > this.state.newRestSoldAt
    ) {
      return true;
    } else if (
      this.state.newRestSoldAt < this.state.newBrewShiptAt ||
      this.state.newRestSoldAt < this.state.newDistShipAt ||
      this.state.newRestSoldAt < this.state.newDistRecvAt ||
      this.state.newRestSoldAt < this.state.newRestRecvAt
    ) {
      return true;
    } else if (newStatusId === 0) {
      return false;
    } else if (
      (newStatusId === 1 &&
        restaurantValueChangeFlag === true &&
        brewShipAtValueChangedFlag === true) ||
      (newStatusId === 1 &&
        distributorValueChangeFlag === true &&
        brewShipAtValueChangedFlag === true)
    ) {
      return false;
    } else if (
      newStatusId === 2 &&
      distributorValueChangeFlag === true &&
      brewShipAtValueChangedFlag === true &&
      distRecvAtValueChangedFlag == true
    ) {
      return false;
    } else if (
      newStatusId === 3 &&
      distributorValueChangeFlag === true &&
      restaurantValueChangeFlag === true &&
      brewShipAtValueChangedFlag === true &&
      distRecvAtValueChangedFlag == true &&
      distShipAtValueChangedFlag === true
    ) {
      return false;
    } else if (
      newStatusId === 4 &&
      restaurantValueChangeFlag === true &&
      brewShipAtValueChangedFlag == true &&
      restRecvAtValueChangedFlag === true
    ) {
      return false;
    } else if (
      newStatusId === 5 &&
      restaurantValueChangeFlag === true &&
      restRecvAtValueChangedFlag === true &&
      brewShipAtValueChangedFlag == true &&
      restSoldAtValueChangedFlag === true
    ) {
      return false;
    } else if (
      (newStatusId == 6 &&
        brewShipAtValueChangedFlag == true &&
        distributorValueChangeFlag == true) ||
      (newStatusId == 6 &&
        brewShipAtValueChangedFlag == true &&
        restaurantValueChangeFlag == true) ||
      (newStatusId == 6 &&
        brewShipAtValueChangedFlag == true &&
        restaurantValueChangeFlag == true &&
        distributorValueChangeFlag == true &&
        distShipAtValueChangedFlag == true)
    ) {
      return false;
    } else return true;
  }

  async handleSubmit() {
    const { registerSerialsCancelShipping, servers, getSerials, getLocations } = this.props;
    //const finalStatus = newStatusId;
    const brewery = this.state.filterBrewId;
    const distributor = this.state.filterDistId;
    const restaurant = this.state.filterRestId;
    //console.log(this.state.tableMeta);
    const { tableData, rowIndex } = this.state.tableMeta;
    const serials = tableData[rowIndex];
    serials['newStatus'] = statusInEditDialog;
    serials['newBrewery'] = brewery;
    if (distributor !== '') {
      serials['newDistributor'] = distributor;
    } else if ('newDistributor' in serials) {
      delete serials.newDistributor;
    }
    if (restaurant !== '') {
      serials['newRestaurant'] = restaurant;
    } else if ('newRestaurant' in serials) {
      delete serials.newRestaurant;
    }
    if (this.state.newBrewShiptAt != 's') {
      serials['newBrewShipAt'] = this.state.newBrewShiptAt;
    } else if ('newBrewShipAt' in serials) {
      delete serials.newBrewShipAt;
    }
    if (this.state.newRestRecvAt != '') {
      serials['newRestRecvAt'] = this.state.newRestRecvAt;
    } else if ('newRestRecvAt' in serials) {
      delete serials.newRestRecvAt;
    }
    if (this.state.newRestSoldAt != '') {
      serials['newRestSoldAt'] = this.state.newRestSoldAt;
    } else if ('newRestSoldt' in serials) {
      delete serials.newRestSoldAt;
    }
    if (this.state.newDistRecvAt != '') {
      serials['newDistRecvAt'] = this.state.newDistRecvAt;
    } else if ('newDistRecvAt' in serials) {
      delete serials.newDistRecvAt;
    }
    if (this.state.newDistShipAt != '') {
      serials['newDistShipAt'] = this.state.newDistShipAt;
    } else if ('newDistShipAt' in serials) {
      delete serials.newDistShipAt;
    }
    if (this.state.newSlip != '') {
      serials['newSlip'] = this.state.newSlip;
    } else if ('newSlip' in serials) {
      delete serials.newSlip;
    }
    if (this.state.newDistSlip != '') {
      serials['newDistSlip'] = this.state.newDistSlip;
    } else if ('newDistSlip' in serials) {
      delete serials.newDistSlip;
    }
    if (newStatusId === 6) {
      if (distributorValueChangeFlag && restaurantValueChangeFlag) {
        if (restaurant != '') {
          serials['newDistShipRestLocId'] = restaurant;
          delete serials.newRestaurant;
        } else if (serials['newDistShipRestLocId'] in serials) {
          delete serials.newDistShipRestLocId;
        }
      }
    }
    let rowinfo = [];
    rowinfo.push(serials);
    //console.log('Serials: ' + JSON.stringify(rowinfo, null, 4));
    await registerSerialsCancelShipping({ action: SERIAL_ACTION.editStatus, serials: rowinfo });
    rowinfo.pop();
    //console.log('Serials: ' + JSON.stringify(rowinfo, null, 4));
    this.handleClose();
    const query = { ...servers.serialsQuery };
    //setSerials"Query(query);
    console.log('Calling get serials now ');
    await getSerials(query, undefined, true);
    await getLocations({ ...DefaultMasterQuery });
  }

  render() {
    const { apps, servers, classes } = this.props;
    const { columns } = this.state;
    //const { user } = servers;
    //const { location } = user;

    const lang = apps.currentLanguage;

    //Store all the user Ids and location IDs from servers

    //console.log(this.state.usersData);

    //Brewry list for edit Status function
    let brewValue = { id: 0, name: '' };
    const brews = servers.locations
      ?.map((locObj) => {
        let displayLabel = locObj.name;

        if (locObj.id === this.state.filterBrewId) brewValue = { ...locObj };
        displayLabel = `${locObj?.name}`;
        return { ...locObj, displayLabel };
      })
      .filter((x) => x.category === 1);

    //Distributor list for edit status function
    let distValue = { id: 0, name: '' };
    let dists = servers.locations
      ?.map((locObj) => {
        let displayLabel = locObj.name;
        if (locObj.id === this.state.filterDistId) distValue = { ...locObj };
        displayLabel = `${locObj?.name}`;
        return { ...locObj, displayLabel };
      })
      .filter((x) => x.category === 2);

    // if (breweryValueChangedFlag) {
    //   dists = [];
    //   const filteredUsersData = this.state.usersData.objects.filter((item) => {
    //     if (item.locationId === this.state.filterBrewId) {
    //       return { ...item };
    //     }
    //   });
    //   //console.log('filtered1: ' + JSON.stringify(filteredUsersData, null, 4));
    //   const filteredLocationDataDist = servers.locations.filter((locationRow) => {
    //     return filteredUsersData.find((userRow) => {
    //       //console.log('User row1 :' + JSON.stringify(userRow, null, 4));
    //       return locationRow.creatorId === userRow.id;
    //     });
    //   });
    //   //console.log('filteredDistData: ' + JSON.stringify(filteredLocationDataDist, null, 4));
    //   dists = filteredLocationDataDist
    //     .map((locObj) => {
    //       let displayLabel = locObj.name;
    //       if (locObj.id === this.state.filterDistId) distValue = { ...locObj };
    //       displayLabel = `${locObj?.name}`;
    //       return { ...locObj, displayLabel };
    //     })
    //     .filter((x) => x.category === 2);
    //   //console.log(JSON.stringify(dists, null, 4));
    // }

    //Restaurant list for edit status function
    const defaultRest = 0;
    let restValue = { id: defaultRest, name: '' };
    let rests = servers.locations
      ?.map((locObj) => {
        let displayLabel = locObj.name;
        if (locObj.id === this.state.filterRestId) restValue = { ...locObj };
        displayLabel = `${locObj?.name}`;
        return { ...locObj, displayLabel };
      })
      .filter((x) => x.category === 3);

    //Display restaurants linked to this brewery and to all dsitributors under this brwewery
    // if (breweryValueChangedFlag) {
    //   rests = [];
    //   //Add all rstaurants connected to the brewery directly
    //   const filteredUsersDataBrewery = this.state.usersData.objects.filter((item) => {
    //     if (item.locationId === this.state.filterBrewId) {
    //       return { ...item };
    //     }
    //   });
    //   //console.log('filtered2: ' + JSON.stringify(filteredUsersDataBrewery, null, 4));
    //   const filteredLocationDataRest1 = servers.locations.filter((locationRow) => {
    //     return filteredUsersDataBrewery.find((userRow) => {
    //       //console.log('User row2 :' + JSON.stringify(userRow, null, 4));
    //       return locationRow.creatorId === userRow.id;
    //     });
    //   });
    //   //console.log('filteredResttData: ' + JSON.stringify(filteredLocationDataRest1, null, 4));
    //   rests = filteredLocationDataRest1
    //     .map((locObj) => {
    //       let displayLabel = locObj.name;
    //       if (locObj.id === this.state.filterRestId) restValue = { ...locObj };
    //       displayLabel = `${locObj?.name}`;
    //       return { ...locObj, displayLabel };
    //     })
    //     .filter((x) => x.category === 3);
    //Brewery-restaurant ends here

    // //Add restaurants connected to each distributor
    // const filteredUsersDataDistributor = filteredLocationDataRest1.filter((item) => {
    //   if (item.category === 2) {
    //     return { ...item };
    //   }
    // });
    // //console.log('filtered2: ' + JSON.stringify(filteredUsersData, null, 4));
    // const filteredLocationDataRest2 = servers.locations.filter((locationRow) => {
    //   return filteredUsersDataDistributor.find((userRow) => {
    //     //console.log('User row2 :' + JSON.stringify(userRow, null, 4));
    //     return locationRow.creatorId === userRow.id;
    //   });
    // });
    // //console.log('filteredResttData: ' + JSON.stringify(filteredLocationDataRest, null, 4));
    // const rests2 = filteredLocationDataRest2
    //   .map((locObj) => {
    //     let displayLabel = locObj.name;
    //     if (locObj.id === this.state.filterRestId) restValue = { ...locObj };
    //     displayLabel = `${locObj?.name}`;
    //     return { ...locObj, displayLabel };
    //   })
    //   .filter((x) => x.category === 3);
    // rests = rests.concat(rests2);
    //Distributor -restaurant  ends here

    //-------------------------XXXXXXXX------------------------------

    //Display all restaurants linked to this particular distributor
    if (distributorValueChangeFlag) {
      rests = [];
      const filteredUsersData = this.state.usersData.objects.filter((item) => {
        if (item.locationId === this.state.filterDistId) {
          return { ...item };
        }
      });
      //console.log('filtered2: ' + JSON.stringify(filteredUsersData, null, 4));
      const filteredLocationDataRest = servers.locations.filter((locationRow) => {
        return filteredUsersData.find((userRow) => {
          //console.log('User row2 :' + JSON.stringify(userRow, null, 4));
          return locationRow.creatorId === userRow.id;
        });
      });
      //console.log('filteredResttData: ' + JSON.stringify(filteredLocationDataRest, null, 4));
      rests = filteredLocationDataRest
        .map((locObj) => {
          let displayLabel = locObj.name;
          if (locObj.id === this.state.filterRestId) restValue = { ...locObj };
          displayLabel = `${locObj?.name}`;
          return { ...locObj, displayLabel };
        })
        .filter((x) => x.category === 3);
    }
    //console.log(JSON.stringify(rests, null, 4));

    const filtersView = (
      <>
        <Grid container alignItems='flex-end'>
          <Grid item xs={4} className={classes.gridItem} style={{ paddingLeft: '15px' }}>
            {getSmallTextField(
              appLanguages.slipNum[lang],
              this.state.filterSalesOrder,
              false,
              (e) => this.handleChangeSlip(e.target.value),
            )}
          </Grid>
          <Box width='100%' />
          <ThemeProvider theme={classes.resize}>
            <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
              {getTextFieldForSmall(
                <Typography>{appLanguages.serialRangeStart[lang]}</Typography>,
                appLanguages.serialInputSampleStart[lang],
                this.state.serialInputObjects['from'] || '',
                (e) => this.handleChangeFromSerials(e.target.value),
                Boolean(this.state.serialInputObjects['fromErr']) || false,
                this.state.serialInputObjects['fromErr'] || '',
              )}
            </Grid>
            {/*Box for End range serial */}
            <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
              <Typography variant='caption' color='textSecondary'>
                {'~'}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem} style={{ paddingLeft: '15px' }}>
              {getTextFieldForSmall(
                <Typography>{appLanguages.serialRangeEnd[lang]}</Typography>,
                appLanguages.serialInputSampleEnd[lang],
                this.state.serialInputObjects['to'] || '',
                (e) => this.handleChangeToSerials(e.target.value),
                Boolean(this.state.serialInputObjects['toErr']) || false,
                this.state.serialInputObjects['toErr'] || '',
              )}
            </Grid>
          </ThemeProvider>
          <Box width='100%' />
        </Grid>
        <Grid container>
          <Grid
            item
            className='auto-complete-wrapper'
            style={{
              paddingLeft: '15px',
              paddingRight: '40px',
              paddingTop: '20px',
            }}
          >
            <Autocomplete
              disablePortal
              clearOnBlur={true}
              id='combo-box-demo'
              options={options}
              value={activeStatus}
              getOptionLabel={this.renderOptionLabel}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label={appLanguages.status[lang]} />}
              onChange={(event, newValue) => {
                this.handleChangeStatus(newValue);
                //console.log(newValue);
              }}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingTop: '30px',
              paddingRight: '20px',
            }}
          >
            <Button
              disabled={slip_flag || (fromFlag && toFlag) || statusFlag ? false : true}
              variant='outlined'
              color='primary'
              size='small'
              onClick={() => this.handleSearch()}
              className={classes.button}
            >
              {appLanguages.search[lang]}
            </Button>
          </Grid>
          <Grid
            item
            style={{
              paddingRight: '10px',
              paddingTop: '40px',
            }}
          >
            <div style={{ display: disabled_reset_icon ? 'none' : 'block' }}>
              <Button
                size='small'
                variant='outlined'
                color='default'
                startIcon={<ClearIcon fontSize='small' />}
                onClick={() => this.refresh()}
              >
                <Typography variant='caption' noWrap>
                  {appLanguages.reset[lang]}
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );

    /* 参考: https://github.com/gregnb/mui-datatables */
    //const isRequesting = servers.isRequesting || servers.isGetRequesting || !initialized;
    return (
      <>
        <MuiThemeProvider theme={appMuiTheme}>
          {filtersView}
          <br></br>
          <MUIDataTable
            title=''
            data={servers.serials}
            columns={columns}
            options={{
              ...muiDataTableCommonOptions(),
              download: false,
              expandableRowsOnClick: false,
              onRowClick: (rowData, rowMeta) => console.log('onRowsSelect.', rowData, rowMeta),
              search: false,
              page: this.state.currentPageNo,
              rowsPerPage: 100,
              rowsPerPageOptions: [100],
              pagination: true,
              count: servers.serialsTotalCounts,
              onChangePage: (currentPage) => this.handleOnChangePage(currentPage),
              //onChangeRowsPerPage: (numberOfRows) => this.handleOnChangeRowsPerPage(numberOfRows),
              onColumnSortChange: (changedColumn, direction) =>
                this.handleOnColumnSortChange(changedColumn, direction),
            }}
          />
          <Dialog
            open={this.state.openDialog}
            onClose={() => this.handleClose()}
            fullWidth={true}
            maxWidth='md'
          >
            <DialogContent>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs={6}>
                  <Typography variant='subtitle1'>
                    <strong>
                      {appLanguages.serialCode[lang]} - {this.state.serialNumber}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs={6}>
                  <Typography variant='subtitle1'>{appLanguages.status[lang]}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    clearOnBlur={true}
                    id='combo-box-demo'
                    options={optionsDialogStatus}
                    value={this.state.activeStatusEditDialog}
                    getOptionLabel={this.renderOptionLabelEditDialog}
                    renderInput={(params) => <TextField {...params} fullWidth size='small' />}
                    onChange={(event, newValue) => {
                      this.handleChangeStatusEditDialog(newValue);
                      //console.log(newValue);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs='auto' style={{ backgroundColor: '#006400' }}>
                  <Typography variant='subtitle1' style={{ color: 'white' }}>
                    {appLanguages.brewery[lang]}
                  </Typography>
                </Grid>
                <Grid item xs></Grid>
                {/* <Grid item xs={6}>
                  {getAutocompleteAdminEditDialog(
                    '',
                    '',
                    true,
                    { ...brewValue },
                    [...brews],
                    (e, v) => this.handleChangeBrewery(v ? v.id : 0),
                    false,
                  )}
                </Grid> */}
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    clearOnBlur={true}
                    value={brewValue}
                    id='combo-box-demo'
                    options={[...brews]}
                    getOptionLabel={(o) => o['name'] || ''}
                    disabled={true}
                    onChange={(event, newValue) => {
                      this.handleChangeBrewery(newValue);
                      //console.log(newValue);
                    }}
                    renderInput={(p) => <TextField {...p} label={''} fullWidth size='small' />}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      disableMaskedInput
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{ disabled: true }}
                          value={
                            this.state.newBrewShiptAt
                              ? new Date(this.state.newBrewShiptAt).toLocaleString()
                              : ''
                          }
                          InputLabelProps={{ shrink: true }}
                          error={false}
                          fullWidth
                          size='small'
                        />
                      )}
                      label={appLanguages.brewShipAt[language]}
                      value={this.state.newBrewShiptAt}
                      disabled={this.state.brewShipAtDisabledflag}
                      onChange={(date) => {
                        this.handleChangeBrewShipAt(date);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  {getSmallTextField(
                    appLanguages.slipNum[lang],
                    this.state.newSlip,
                    this.state.slipDisabledFlag,
                    (e) => this.handleChangeBrewSlip(e.target.value),
                  )}
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs='auto' style={{ backgroundColor: '#8B7D04' }}>
                  <Typography variant='subtitle1' style={{ color: 'white' }}>
                    {appLanguages.distributor[lang]}
                  </Typography>
                </Grid>
                <Grid item xs></Grid>

                {/* <Grid item xs={6}>
                  {getAutocompleteAdminEditDialog(
                    '',
                    '',
                    true,
                    { ...distValue },
                    [...dists],
                    (e, v) => this.handleChangeDistributor(v ? v.id : defaultRest),
                    distributorDisabledFlag,
                  )}
                </Grid> */}
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    clearOnBlur={true}
                    value={distValue}
                    options={[...dists]}
                    id='combo-box-demo'
                    getOptionLabel={(o) => o['name'] || ''}
                    onInputChange={(event, value, reason) => {
                      if (reason === 'clear') {
                        distributorValueChangeFlag = false;
                        this.setState({
                          filterDistId: '',
                          filterRestId: '',
                          newDistRecvAt: null,
                          newDistShipAt: null,
                          newRestRecvAt: null,
                          newRestSoldAt: null,
                        });
                        distributorValueChangeFlag = false;
                        restaurantValueChangeFlag = false;
                        distRecvAtValueChangedFlag = false;
                        distShipAtValueChangedFlag = false;
                        restRecvAtValueChangedFlag = false;
                        restSoldAtValueChangedFlag = false;
                      }
                    }}
                    onChange={(event, newValue) => {
                      this.handleChangeDistributor(newValue);
                      //console.log(newValue);
                    }}
                    disabled={this.state.distributorDisabledFlag}
                    renderInput={(p) => <TextField {...p} label={''} fullWidth size='small' />}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      disableMaskedInput
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{ disabled: true }}
                          error={false}
                          fullWidth
                          size='small'
                          value={
                            this.state.newDistRecvAt
                              ? new Date(this.state.newDistRecvAt).toLocaleString()
                              : ''
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      label={appLanguages.distRecvAtWithTime[language]}
                      value={this.state.newDistRecvAt}
                      disabled={this.state.distRecvAtDisabledFlag}
                      onChange={(date) => {
                        this.handleChangeDistRecvAt(date);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      disableMaskedInput
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{ disabled: true }}
                          error={false}
                          fullWidth
                          size='small'
                          value={
                            this.state.newDistShipAt
                              ? new Date(this.state.newDistShipAt).toLocaleString()
                              : ''
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      label={appLanguages.distShipAtWithTime[language]}
                      value={this.state.newDistShipAt}
                      disabled={this.state.distShipAtDisabledFlag}
                      onChange={(date) => {
                        this.handleChangeDistShipAt(date);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  {getSmallTextField(
                    appLanguages.slipNum[lang],
                    this.state.newDistSlip,
                    this.state.distSlipDisabledFlag,
                    (e) => this.handleChangeDistSlip(e.target.value),
                  )}
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs='auto' style={{ backgroundColor: '#be4d25' }}>
                  <Typography variant='subtitle1' style={{ color: 'white' }}>
                    {appLanguages.restaurant[lang]}
                  </Typography>
                </Grid>
                <Grid item xs></Grid>
                {/* <Grid item xs={6}>
                  {getAutocompleteAdminEditDialog(
                    '',
                    '',
                    true,
                    { ...restValue },
                    [...rests],
                    (e, v) => this.handleChangeRestaurant(v ? v.id : defaultRest),
                    restaurantDisabledFlag,
                  )}
                </Grid> */}
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    clearOnBlur={true}
                    value={restValue}
                    options={[...rests]}
                    getOptionLabel={(o) => o['name'] || ''}
                    onInputChange={(event, value, reason) => {
                      if (reason === 'clear') {
                        restaurantValueChangeFlag = false;
                        this.setState({
                          filterRestId: '',
                          newRestRecvAt: null,
                          newRestSoldAt: null,
                        });
                        restaurantValueChangeFlag = false;
                        restRecvAtValueChangedFlag = false;
                        restSoldAtValueChangedFlag = false;
                      }
                    }}
                    onChange={(event, newValue) => {
                      this.handleChangeRestaurant(newValue);
                      //console.log(newValue);
                    }}
                    disabled={this.state.restaurantDisabledFlag}
                    renderInput={(p) => <TextField {...p} label={''} fullWidth size='small' />}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      disableMaskedInput
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{ disabled: true }}
                          fullWidth
                          error={false}
                          size='small'
                          value={
                            this.state.newRestRecvAt
                              ? new Date(this.state.newRestRecvAt).toLocaleString()
                              : ''
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      label={appLanguages.restRecvAtWithTime[language]}
                      value={this.state.newRestRecvAt}
                      disabled={this.state.restRecvAtDisabledFlag}
                      onChange={(date) => {
                        this.handleChangeRestRecvAt(date);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{ padding: '15px' }}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      disableMaskedInput
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          inputProps={{ disabled: true }}
                          error={false}
                          fullWidth
                          size='small'
                          value={
                            this.state.newRestSoldAt
                              ? new Date(this.state.newRestSoldAt).toLocaleString()
                              : ''
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      label={appLanguages.restSoldAtWithTime[language]}
                      value={this.state.newRestSoldAt}
                      disabled={this.state.restSoldAtDisabledFlag}
                      onChange={(date) => {
                        this.handleChangeRestSoldAt(date);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleSubmit()}
                color='primary'
                disabled={this.isConfirmDisabled()}
              >
                {appLanguages.confirm[lang]}
              </Button>
              <Button onClick={() => this.handleClose()}>{appLanguages.cancel[lang]}</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
        {apps.isOpenSerialEditDialog ? <SerialEditDialog /> : ''}
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  viewType?: 'stock' | 'shipped';
  viewReference?: 'brew' | 'distributor';
  classes: any;
}

export interface IDispatchProps {
  setSerialsQuery: (query: IQuery) => void;
  openSerialEditDialog: (serialObj: ISerial) => void;
  getSerials: (query: IQuery, cancelShippingFlag?, adminFlag?) => void;
  setEditSerial: (serialObj: ISerial) => void;
  delSerial: (serialObj: ISerial) => void;
  getSerialBrands: (query: IQuery) => void;
  getLocations: (query: IQuery) => void;
  openSnackbarMessage: (type: string, message: string) => void;
  confirm?: () => void;
  setLocationsQuery: (query: IQuery) => void;
  registerSerialsCancelShipping: (serialObj: any, successMessage?: any) => void;
}

interface State {
  initialized: boolean;
  columns: MUIDataTableColumn[];
  serialInputObjects: any;
  statusValue: any;
  filterTimeout: any;
  filterSalesOrder: any;
  filterFromSerial: number;
  filterToSerial: number;
  filterStatus: any;
  currentPageNo: any;
  openDialog: any;
  closeDialog: any;
  restaurantDisabledFlag: boolean;
  distributorDisabledFlag: boolean;
  filterBrewId: any;
  filterDistId: any;
  filterRestId: any;
  creatorId: any;
  newBreweryId: any;
  locationId: any;
  usersData: any;
  tableMeta: any;
  newBrewShiptAt: any;
  newSlip: any;
  newDistRecvAt: any;
  newDistShipAt: any;
  newDistSlip: any;
  newRestRecvAt: any;
  newRestSoldAt: any;
  brewShipAtDisabledflag: boolean;
  slipDisabledFlag: boolean;
  distRecvAtDisabledFlag: boolean;
  distShipAtDisabledFlag: boolean;
  distSlipDisabledFlag: boolean;
  restRecvAtDisabledFlag: boolean;
  restSoldAtDisabledFlag: boolean;
  activeStatusEditDialog: any;
  serialNumber: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setSerialsQuery,
  openSerialEditDialog,
  getSerials,
  setEditSerial,
  delSerial,
  getSerialBrands,
  getLocations,
  openSnackbarMessage,
  setLocationsQuery,
  registerSerialsCancelShipping,
};

const myStyles = (theme: Theme): StyleRules => ({
  root: { width: '100%' },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  actionsContainer: { marginBottom: theme.spacing(2) },
  resetContainer: { padding: theme.spacing(3) },
  paper: { padding: theme.spacing(0.1) },
  paper1: { padding: theme.spacing(1) },
  paper2: { padding: theme.spacing(2) },
  paper3: { padding: theme.spacing(3) },
  cardMedia: {
    height: 0,
    paddingTop: '100%',
  },
  gridItem: {
    paddingTop: theme.spacing(0.1),
    padding: theme.spacing(0.1),
  },
  alert: {
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0.1),
  },
  pagination: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resize: {
    fontSize: 50,
  },
});

export const BottleManagementBrewery = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withConfirm(bottleManagementBrewery));
