import { DefaultMasterQuery } from './default-query';
import { isOk } from './is-ok';

export function getQueryForScannedSerials(serialCodes: string[]) {
  const where: any = {};
  if (isOk(serialCodes)) {
    where.$or = [];
    serialCodes.map((serialCode) => {
      where.$or.push({ code: serialCode });
    });
  }
  return {
    ...DefaultMasterQuery,
    where,
  };
}
