import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  LinearProgress,
  DialogContent,
  Box,
  Grid,
  DialogActions,
  Typography,
  DialogTitle,
  Button,
  Paper,
  Avatar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  LibraryAdd as LibraryAddIcon,
  CameraAlt as CameraAltIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons';
import {
  closeProfileDialog,
  setEditUser,
  openSnackbarMessage,
} from '../../redux/actions/appsActions';
import { registerUser } from '../../redux/actions/serversActions';
import { storage } from '@utils/firebase';

import appLanguages from '@utils/app-languages';
import {
  getAutocomplete,
  getOutlinedButton,
  getTextFieldProfile,
  getTextFieldForPassword,
} from '@utils/common';

class ProfileDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      name: '',
      email: '',
      password: '',
      company: '',
    };
  }

  componentDidMount(props) {
    const { servers } = this.props;
    const user = servers.user;
    this.setState({
      imageUrl: user.imageUrl,
      name: user.name,
      email: user.email,
      password: user.password,
      company: user.company,
    });
  }

  handleClickProfileEdit() {
    console.log('Handle profile edit');
    const { servers, apps, registerUser, openSnackbarMessage } = this.props;
    const { currentLanguage } = apps;
    const lang = currentLanguage;
    const user = servers.user;
    user.name = this.state.name;
    user.email = this.state.email;
    user.password = this.state.password;
    user.company = this.state.company;
    user.imageUrl = this.state.imageUrl;
    if (!user.name) return openSnackbarMessage('error', appLanguages.pleaseSetTheName[lang]);
    if (!user.email) return openSnackbarMessage('error', appLanguages.pleaseSetTheEmail[lang]);
    if (!user.password)
      return openSnackbarMessage('error', appLanguages.pleaseSetThePassword[lang]);
    return registerUser(user);
  }

  handleChangeImgFiles(e) {
    const { files } = e.target;
    const { servers } = this.props;
    console.log('Handle change img');
    for (let i = 0; i < files.length; i += 1) {
      const imgFile = files[i];
      const fileName = `${imgFile.size}-${imgFile.lastModified}-${imgFile.type}`.replace('/', '_');
      const storageRef = storage.ref(`upload/${servers.user.uid}/users/${fileName}`);
      const uploadTask = storageRef.put(imgFile);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          window.console.log(`Upload is ${progress}% done. state is ${snapshot.state}`);
        },
        (error) => {
          window.console.error(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.setState({ imageUrl: downloadURL });
          });
        },
      );
    }
  }

  handleChangeName(e) {
    this.setState({ name: e.target.value });
  }
  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  handleChangePassword(e) {
    this.setState({ password: e.target.value });
  }
  handleChangeCompany(e) {
    this.setState({ company: e.target.value });
  }

  render() {
    const { apps, auth, classes, servers } = this.props;
    const { closeProfileDialog } = this.props;
    const lang = apps.currentLanguage;
    const obj = this.state;

    const isRequesting = servers.isGetRequesting || servers.isRequesting;
    console.log(isRequesting);

    const uploadAvatarImageView = (
      <Paper elevation={3}>
        <Grid container justify='center' alignItems='flex-start' alignContent='flex-start'>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            <Avatar
              onClick={() => null}
              src={obj && obj.imageUrl ? obj.imageUrl : ''}
              className={classes.avatar}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            <label htmlFor='content-image-file01'>
              <input
                accept='image/*'
                className={classes.fileInput}
                id='content-image-file01'
                multiple
                type='file'
                onChange={(e) => this.handleChangeImgFiles(e)}
                disabled={isRequesting}
              />
              <Button
                variant='contained'
                component='span'
                className={classes.fileButton}
                disabled={isRequesting}
                color='primary'
              >
                <CameraAltIcon />
                <Box ml={1} />
                {appLanguages.avatarImageUpload[lang]}
              </Button>
            </label>
            {obj && obj.imageUrl ? (
              <Button
                variant='contained'
                component='span'
                className={classes.fileButton}
                disabled={isRequesting}
                color='default'
                onClick={() => this.setState({ imageUrl: '' })}
              >
                <DeleteForeverIcon />
                <Box ml={1} />
                画像リセット
              </Button>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Paper>
    );

    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        open={apps.isOpenProfileDialog}
        onClose={() => closeProfileDialog()}
      >
        {isRequesting ? <LinearProgress color='primary' /> : ''}
        <DialogTitle>
          <Grid container wrap='nowrap'>
            <Grid item>
              <EditIcon fontSize='inherit' />
            </Grid>
            <Grid item>
              <Box ml={1} />
            </Grid>
            <Grid item>
              <Typography variant='subtitle1'>プロフィール編集</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box mt={0} mb={10}>
            {/* TODO: ここで、プロフィールの編集画面を実装する */}
            {/* ▼▼▼ ダッシュボードのユーザ編集画面を参考にする。ダッシュボードと同じAPIを利用する。 ▼▼▼ */}
            {/* https://github.com/bajji-corporation/sake-blockchain-v2-dashboard/blob/master/src/components/main/masters/users/UserEditDialog.jsx */}
            {/* 編集できる項目は、 "アバター画像(imageUrl)", "名前(name)", "パスワード(password)", "会社(company)"  */}

            {uploadAvatarImageView}
            <Box mt={1} />
            {getTextFieldProfile(
              `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
              obj ? obj.name || '' : '',
              isRequesting,
              (e) => this.handleChangeName(e),
            )}
            <Box mt={1} />
            {getTextFieldProfile(
              `${appLanguages.email[lang]} (${appLanguages.require[lang]})`,
              obj ? obj.email || '' : '',
              true,
              (e) => this.handleChangeEmail(e),
            )}
            <Box mt={1} />
            {getTextFieldForPassword(
              `${appLanguages.password[lang]} (${appLanguages.require[lang]})`,
              obj ? obj.password || '' : '',
              isRequesting,
              (e) => this.handleChangePassword(e),
            )}
            <Box mt={1} />
            {getTextFieldProfile(
              `${appLanguages.company[lang]}`,
              obj ? obj.company || '' : '',
              isRequesting,
              (e) => this.handleChangeCompany(e),
            )}

            {/* <Alert severity="warning">
              申し訳ございません。現在、メンテナンス中のため、ご利用できません。
            </Alert> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container justify='flex-end'>
            <Button
              variant='outlined'
              color='default'
              onClick={() => closeProfileDialog()}
              disabled={isRequesting}
            >
              キャンセル
            </Button>
            <Box ml={1} />
            <Button
              variant='outlined'
              color='default'
              onClick={(e) => this.handleClickProfileEdit(e)} /* TODO: 登録処理を実装する */
              disabled={isRequesting}
            >
              更新
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

ProfileDialog.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  apps: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  apps: PropTypes.objectOf(PropTypes.any).isRequired,
  servers: PropTypes.objectOf(PropTypes.any).isRequired,
  registerUser: PropTypes.func.isRequired,
  openSnackbarMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps = {
  closeProfileDialog,
  setEditUser,
  openSnackbarMessage,
  registerUser,
};

const myStyles = (theme) => ({
  fileButton: { margin: theme.spacing(1) },
  fileInput: { display: 'none' },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
});

export default compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(ProfileDialog);
