import { IBMapResult } from '@models/baidu-map-result';

export function getCurrentBaiduLocation() {
  const promiseItem = new Promise((resolve: (value: IBMapResult) => void, reject) => {
    // Load raw BMap library
    const BMap = require('BMap');
    const geolocation = new BMap.Geolocation();
    geolocation.getCurrentPosition(
      (r: any) => {
        if (geolocation.getStatus() === 0) {
          // get gps coords
          // console.log('Your Location: ' + r.point.lng + ',' + r.point.lat);
          const point = new BMap.Point(r.point.lng, r.point.lat);
          const geoc = new BMap.Geocoder();
          // get address from gps
          geoc.getLocation(point, (rs: any) => {
            const addComp = rs.addressComponents;
            const res = {
              longitude: r.point.lng,
              latitude: r.point.lat,
              province: addComp.province,
              city: addComp.city,
              district: addComp.district,
              street: addComp.street,
              streetNumber: addComp.streetNumber,
            };
            resolve(res);
          });
        } else {
          // console.log('failed to get address');
          reject('failed to get address');
        }
      },
      { enableHighAccuracy: true },
    );
  });
  return promiseItem;
}
