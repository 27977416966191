import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import userEnv from 'userEnv';
import Login from './Login';
import AppProgress from './main/Progress';
import SnackbarMessage from './snackbar/SnackbarMessage';
import Main from './Main';
import { initAuth, loginWithCustomToken } from '../redux/actions/authActions';

/**
 * @returns {*}
 * @constructor
 */
class Layout extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { initAuth } = this.props;
    initAuth();
    // NOTE: Google Map Suggest を利用するために、事前に読み込んでおく.
    const script = window.document.createElement('script');
    const apiKey = userEnv.googleMapApiKey;
    script.type = 'text/javascript';
    const librariesQuery = 'libraries=places,visualization,geometry&sensor=false';
    script.src = `https://maps.googleapis.com/maps/api/js?language=en&key=${apiKey}&${librariesQuery}`;
    window.document.head.appendChild(script);
  }

  render() {
    const { classes, auth } = this.props;
    const { isRequesting, initialized, user, isTokenLogin } = auth;
    const isLoading = !initialized && isRequesting;
    const isLogout = !isLoading && !user;
    const isLogin = !isLoading && user;
    return (
      <>
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {isLoading ? <AppProgress /> : ''}
            {isLogin || isTokenLogin ? <Main /> : ''}
            {isLogout && !isTokenLogin ? <Login /> : ''}
          </main>
        </div>
        {auth.initialized ? <SnackbarMessage /> : ''}
      </>
    );
    // }
  }
}

Layout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  initAuth: PropTypes.func.isRequired,
  loginWithCustomToken: PropTypes.func.isRequired,
};

const myStyles = (theme) => ({
  root: { display: 'flex' },
  content: { width: '100%' },
  toolbar: theme.mixins.toolbar,
});

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = { initAuth, loginWithCustomToken };

export default compose(withStyles(myStyles), connect(mapStateToProps, mapDispatchToProps))(Layout);
