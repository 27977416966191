import { applyMiddleware, combineReducers, createStore } from 'redux';
// import persistState from 'redux-localstorage';
import thunk from 'redux-thunk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import apps from './apps';
import auth from './auth';
import servers from './servers';

const reducer = combineReducers({
  apps,
  auth,
  servers,
});

// TODO [SAKE-BC] Revert this back on Production
const enhancer = composeWithDevTools(applyMiddleware(thunk));
//const enhancer = applyMiddleware(thunk);
export const store = createStore(reducer, enhancer);
export default reducer;
