import { createMuiTheme } from '@material-ui/core/styles';
import { blue, lightBlue } from '@material-ui/core/colors';

// eslint-disable-next-line no-underscore-dangle
const _theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: lightBlue,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      light: '#ffffff',
      main: '#eceff1',
      dark: '#babdbe',
      contrastText: '#455a64',
    },
  },
});

export default theme;
